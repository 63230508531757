<div class="bg-white rounded-5 w-full flex flex-col">
  <header class="flex items-center justify-end w-full px-4 pt-3">
    <arkiq-icon-button
      (click)="handleCloseModal()"
      icon="bi-x-lg"
      tooltipText="Close dialog"
    />
  </header>

  <h3 class="text-[#F00] px-6 font-bold text-2xl">Warning!</h3>

  <h4 class="m-0 font-medium px-6 text-[15px]">
    You are about to return the following devices to ArkIQ inventory
  </h4>

  <ul
    class="flex flex-1 flex-col gap-3 p-4 border w-full max-w-[90%] max-h-[200px] sm:max-h-[300px] border-black/15 rounded-xl overflow-y-auto mx-auto my-4 scrollbar-style transition-all"
  >
    @for(device of data.selectedDevices; track device.id) {
    <li class="flex gap-3 items-center">
      <i
        class="bi bi-wifi border border-black/10 rounded-[7px] p-[10px] text-base text-[#CCC]"
      ></i>
      <span class="mr-auto font-semibold text-nowrap">{{ device.id }}</span>
      <span class="font-semibold text-right">{{ device.type }}</span>
    </li>
    }
  </ul>

<!--  <div class="p-6 border-t col-span-2 border-t-black/15">-->
<!--    <p class="font-semibold text-sm">An OTP code has been sent to [Number]</p>-->
<!--    -->
<!--    <label-->
<!--      class="w-full focus-within border border-black/15 p-4 text-sm flex items-center rounded-lg mt-3 focus-within:ring-0 focus-within:outline-none focus-within:border-primary"-->
<!--    >-->
<!--      <input-->
<!--        class="w-full outline-none text-base bg-transparent"-->
<!--        placeholder="OTP Code"-->
<!--        type="text"-->
<!--      />-->
<!--      <button class="text-primary underline text-sm ml-auto" id="otpcode">-->
<!--        Resend-->
<!--      </button>-->
<!--    </label>-->
<!--  </div>-->

  <footer class="flex justify-between items-center px-6 py-5">
    <arkiq-button
      (click)="cancelReturn()"
      [disabled]="isSaving"
      [loading]="isSaving"
      [rounded]="false"
      size="md"
      text="Cancel"
      variant="tertiary"
    />

    <arkiq-button
      (click)="confirmReturn()"
      [disabled]="isSaving"
      [loading]="isSaving"
      [rounded]="false"
      size="md"
      text="Confirm"
      variant="primary"
    />
  </footer>
</div>
