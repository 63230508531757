
import { CommonModule } from '@angular/common';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import {
  DeviceOverview,
  DevicesWaterLeakService,
  DeviceWaterLeakView,
  SortOrder,
} from '@arkiq-portals/sdk';
import {
  TableColumn,
  TableColumnHeaderFilterType,
  TableColumnType,
  TableComponent,
  TableSortChangeEvent,
} from '@arkiq-portals/ui';
import { MINUTES_DIFFERENCE_UTC_TO_EST } from 'apps/admin/src/utils';
import { formatDate, subMinutes } from 'date-fns';
import { Subscription, debounceTime, distinctUntilChanged } from 'rxjs';

@Component({
  selector: 'app-device-water-leak-table',
  templateUrl: './device-water-leak-table.component.html',
  standalone: true,
  imports: [CommonModule, TableComponent],
})
export class DeviceWaterLeakHistoryTableComponent implements OnInit, OnDestroy {
  @Input({ required: true }) device!: DeviceOverview;

  public isLoading = false;

  public history: DeviceWaterLeakView[] = [];

  public limit = 10;
  public page = 1;
  public totalPages = 1;
  public totalItems = 0;

  public tableOrder: SortOrder = 'desc_nulls_last';

  public subscriptions = new Subscription()


  public tableOrderBy = 'date_time';

  private dateForm = new FormControl(
    {
      startDate: '',
      endDate: ''
    }
  );


  public tableColumns: TableColumn<DeviceWaterLeakView>[] = [
    {
      type: TableColumnType.TEXT,
      label: 'Date/Time',
      valueFn: history => this.formattedDateForTimezone(history.date_time),
      headerClasses: 'text-center justify-center',
      valueClasses: 'text-center text-[#888]',
      sortable: true,
      id: 'date_time',
      filter: {
        options: [],
        type: TableColumnHeaderFilterType.DATE_RANGE,
        control: this.dateForm
      }
    },
    {
      type: TableColumnType.TEXT,
      label: 'Leak',
      valueFn: history => (history.leak ? 'Yes' : 'No'),
      headerClasses: 'text-center justify-center',
      valueClasses: 'text-center text-[#888]',
      valueClassesFn: history =>
        history.leak ? 'text-state-cancel' : 'text-state-completed',
      sortable: true,
      id: 'leak'
    },
    {
      type: TableColumnType.TEXT,
      label: 'Temperature',
      valueFn: history => `${history.temperature ?? 0} ºF`,
      headerClasses: 'text-center justify-center',
      valueClasses: 'text-center text-[#888]',
      sortable: true,
      id: 'temperature'
    },
    {
      type: TableColumnType.TEXT,
      label: 'Humidity',
      valueFn: history => `${history.humidity ?? 0}%`,
      headerClasses: 'text-center justify-center',
      valueClasses: 'text-center text-[#888]',
      sortable: true,
      id: 'humidity'
    },
    {
      type: TableColumnType.TEXT,
      label: 'Battery Level',
      valueFn: history => `${history.battery_level ?? 0}%`,
      headerClasses: 'text-center justify-center',
      valueClasses: 'text-center text-[#888]',
      sortable: true,
      id: 'battery_level'
    },
    {
      type: TableColumnType.TEXT,
      label: 'Battery Voltage',
      valueFn: history => `${history.battery_voltage ?? 0} V`,
      headerClasses: 'text-center justify-center',
      valueClasses: 'text-center text-[#888]',
      sortable: true,
      id: 'battery_voltage'
    },
    {
      type: TableColumnType.TEXT,
      label: 'Tampered',
      valueFn: history => (history.tampered ? 'Yes' : 'No'),
      headerClasses: 'text-center justify-center',
      valueClasses: 'text-center',
      valueClassesFn: history =>
        history.tampered ? 'text-state-cancel' : 'text-state-completed',
      sortable: true,
      id: 'tampered'
    },
    {
      type: TableColumnType.TEXT,
      label: 'f_cnt',
      valueFn: history => String(history.f_cnt ?? 0),
      headerClasses: 'text-center justify-center',
      valueClasses: 'text-center',
      sortable: true,
      id: 'f_cnt'
    },
    {
      type: TableColumnType.TEXT,
      label: 'lastRssi',
      valueFn: history => String(history.last_rssi ?? 0),
      headerClasses: 'text-center justify-center',
      valueClasses: 'text-center',
      sortable: true,
      id: 'last_rssi'
    },
    {
      type: TableColumnType.TEXT,
      label: 'lastSnr',
      valueFn: history => String(history.last_snr ?? 0),
      headerClasses: 'text-center justify-center',
      valueClasses: 'text-center',
      sortable: true,
      id: 'last_snr'
    },
  ];

  constructor(private devicesWaterLeakService: DevicesWaterLeakService) {}

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe()
  }

  public ngOnInit(): void {
    this.subscriptions.add(
      this.dateForm.valueChanges
        .pipe(
          debounceTime(350),
          distinctUntilChanged()
        ).subscribe((value) => {
          console.log(value)
          this.getHistory()
        })
    )
    this.getHistory();
  }

  public async getHistory() {
    try {
      this.isLoading = true;
      const response = await this.devicesWaterLeakService.listByDeviceId(
        this.device.id,
        {
          limit: this.limit,
          page: this.page,
          order: this.tableOrder,
          orderBy: this.tableOrderBy,
          startDate: this.dateForm.value?.startDate,
          endDate: this.dateForm.value?.endDate
        },
      );

      this.history = response.devices;
      this.totalPages = Math.ceil(response.totalItems / this.limit);
      this.totalItems = response.totalItems;

      this.isLoading = false;
    } catch (error) {
      this.isLoading = false;

      console.error(error);
    }
  }

  public onTableRowsLimitChanged(newLimit: number): void {
    this.limit = newLimit;
    this.getHistory();
  }

  public onTablePageChanged(newPage: number): void {
    this.page = newPage;
    this.getHistory();
  }

  public onSortOrderChange(event: TableSortChangeEvent) {
    this.tableOrder = event.order;
    this.tableOrderBy = event.columnId;
    this.getHistory();
  }

  private formattedDateForTimezone(date) {
    const estDate = subMinutes(new Date(date), MINUTES_DIFFERENCE_UTC_TO_EST);
    const formattedDate = formatDate(estDate, 'MMM dd, yyyy HH:mm aa');

    return formattedDate;
  }
}
