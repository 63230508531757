import { Directive, Input, OnDestroy, TemplateRef, ViewContainerRef } from '@angular/core';
import { UserOrganizationRole, UserRole, UsersService } from '@arkiq-portals/sdk';
import { Subscription } from 'rxjs';

@Directive({ selector: '[hasRole]', standalone: true })
export class HasRoleDirective implements OnDestroy {
  private currentUserRoles: UserOrganizationRole[] = [];

  private subscriptions = new Subscription();

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private usersService: UsersService,
  ) {
    this.currentUserRoles = this.usersService.loggedUserRoles;
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  @Input() set hasRole(allowedRoles: UserRole[]) {
    this.viewContainer.clear();

    this.usersService.loggedUserRoles$.subscribe(
      roles => {
        this.currentUserRoles = roles;

        this.viewContainer.clear();

        if (this.usersService.validateUserPermission(allowedRoles)) {
          this.viewContainer.createEmbeddedView(this.templateRef);
        }
      }
    );
  }

  public get roles() {
    return this.currentUserRoles.map(role => role.role);
  }
}
