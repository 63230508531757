<div
  class="w-full flex flex-col bg-white rounded-[6px] shadow-md p-3 min-w-[200px] min-h-[168px]"
>
  @if (title) {
  <header class="w-full text-left">
    <h1 class="font-semibold text-[#000]">{{ title }}</h1>
  </header>
  }

  <main class="flex-1 w-full flex items-center justify-center gap-1">
    @if (leftIcon) {
    <i [ngClass]="[defaultIconClass, leftIcon, iconClasses ?? '']"></i>
    }
    <p class="font-semibold text-[40px]">{{ description }}</p>
    @if (rightIcon) {
    <i [ngClass]="[defaultIconClass, rightIcon, iconClasses ?? '']"></i>
    }
  </main>

  @if (subtitle) {
  <p class="text-sm text-center font-medium text-[#888888]">
    {{ subtitle }}
  </p>
  }
</div>
