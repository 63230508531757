<a
  [routerLink]="routeTo"
  routerLinkActive="router-link-active"
  class="flex flex-col rounded-xl bg-white h-full transition-shadow shadow-sm hover:shadow-md"
>
  <header
    class="px-6 py-3 flex items-center border-b border-b-[#E0E0E0] h-[53px]"
    [ngClass]="{ 'justify-between': monthValue == 0 }"
  >
    <h3 class="text-[#121212] font-semibold text-base">{{ title }}</h3>
    @if(icon) {
    <i class="bi text-primary ml-1 text-2xl" [ngClass]="icon"></i>
    } @if (monthValue > 0) {
    <span class="ml-auto text-4xl font-semibold text-[#121212]">{{
      valueFormatted(value)
    }}</span>
    }
  </header>
  <div
    class="p-6 flex items-center flex-1"
    [ngClass]="{ 'justify-center': monthValue == 0 }"
  >
    <div class="flex gap-1 flex-col items-center">
      @if(monthValue > 0 ) {
      <h3 class="text-[#121212] font-semibold text-base">New This Month</h3>
      <span class="text-4xl font-semibold text-[#121212]">{{
        valueFormatted(monthValue)
      }}</span>
      }@else {
      <span class="text-4xl font-semibold text-[#121212]">{{
        valueFormatted(value)
      }}</span>
      }
    </div>
  </div>
</a>
