<div class="bg-white rounded-[20px] max-w-screen w-[432px]">
  @if (isLoading) {
    <div class="w-full h-[300px] flex items-center justify-center gap-2">
      <arkiq-spinner />
      <p class="text-gray-500">Loading property locations...</p>
    </div>
  } @else {
    <header class="w-full flex items-center justify-between p-[25px] border-b border-b-black/10">
      <h1 class="m-0 text-center text-2xl font-bold">
        Add Location
      </h1>

      <arkiq-icon-button icon="bi-x-lg" (click)="dialogRef.close()" [disabled]="isCreatingLocation" />
    </header>

    <main class="w-full flex flex-col gap-5 p-[25px]">
      <arkiq-input
        label="Location name"
        [required]="true"
        [autofocus]="true"
        [rounded]="false"
        size="md"
        [control]="form.controls.name"
      />

      @if (!disableParentLocation) {
        <arkiq-select
          label="Parent Location"
          [options]="locationsOptions"
          [control]="form.controls.parent"
          [rounded]="false"
          size="md"
        />
      }
    </main>

    <footer class="w-full flex items-center justify-between border-t border-t-black/10 px-[25px] py-5">
      <arkiq-button
        variant="tertiary"
        text="Cancel"
        size="md"
        [rounded]="false"
        [disabled]="isCreatingLocation"
        (click)="dialogRef.close()"
      />

      <arkiq-button
        text="Add"
        size="md"
        [rounded]="false"
        [disabled]="isCreatingLocation"
        [loading]="isCreatingLocation"
        (click)="handleCreateLocation()"
      />
    </footer>
  }
</div>
