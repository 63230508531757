import { BuildUserOrganizationRole, Property, UserOrganizationRole, UserRole, UserRoleLabel, UserType } from ".";

export type User = {
  id: number;
  firebaseId: string;
  email: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  photo: string;
  type: UserType;
  role: UserRole;
  created_at: Date;
  updated_at: Date;
  properties: Property[];
  propertiesNames: string[];
  propertiesIds: string[];
  lastLogin: Date | null;
  users_organizations_roles?: UserOrganizationRole[];
}

export type PropertyAndRoles = {
  propertyName: string;
  organizationName: string;
  role: UserRoleLabel;
};
export function BuildUser(data: any): User {
  return {
    id: data.id,
    firebaseId: data.firebaseId,
    email: data.email,
    firstName: data.firstName,
    lastName: data.lastName,
    phoneNumber: data.phoneNumber,
    photo: data.photo || `https://ui-avatars.com/api/?background=random&name=${data.firstName}+${data.lastName}`,
    type: data.type,
    role: data.role,
    created_at: new Date(data.created_at),
    updated_at: new Date(data.updated_at),
    properties: data.properties ?? [],
    propertiesNames: data.properties?.map((property: any) => property.name),
    propertiesIds: data.properties?.map((property: any) => property.id),
    lastLogin: data.lastLogin ? new Date(data.lastLogin) : null,
    users_organizations_roles: data.users_organizations_roles?.length > 0
      ? data.users_organizations_roles.map((userOrganizationRole: any) => BuildUserOrganizationRole(userOrganizationRole))
      : [],
  }
}
