@if (isLoading) {
<div
  class="w-full h-full bg-white rounded-[15px] flex items-center justify-center gap-3"
>
  <arkiq-spinner />
  <span class="text-sm text-black/50"> Loading device data... </span>
</div>
} @else {
<div class="w-full h-full flex flex-1 flex-col bg-white rounded-[15px]">
  <header class="w-full flex items-center justify-between px-6 py-5">
    <section class="flex items-center gap-2">
      <arkiq-icon-button icon="bi-chevron-left" (click)="handleGoBack()" />
      <h1 class="m-0 font-bold">Device Details</h1>
    </section>

    <section
      class="flex items-center gap-2"
      *hasRole="[USER_ROLES.ORGANIZATION_MASTER]"
    >
      <arkiq-button
        variant="outline-primary"
        text="Return"
        size="md"
        [rounded]="false"
        (click)="handleReturnDevice()"
      />

      @if (deviceFixture) {
        <arkiq-button
          variant="primary"
          [text]="isUninstalling ? 'Uninstalling' : 'Uninstall'"
          size="md"
          [rounded]="false"
          [disabled]="isUninstalling"
          (click)="handleUninstall()"
        />
      }
    </section>
  </header>

  <app-device-details-header [device]="device" />

  <main class="flex-1 px-3 py-5">
    @switch (device.type) { @case (DEVICE_TYPE.LEAK_SENSOR) {
    <app-device-water-leak-table [device]="device" />
    } @case (DEVICE_TYPE.TOILET_SENSOR) {
    <app-device-toilet-sensor-grid [device]="device" />
    } @case (DEVICE_TYPE.SMART_VALVE) {
    <app-device-smart-valve-table [device]="device" />
    } @case (DEVICE_TYPE.GATEWAY) {
    <app-device-gateway-grid [device]="device" />
    } }
  </main>
</div>
}
