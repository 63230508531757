import { CommonModule } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { provideNativeDateAdapter } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatMenuModule } from '@angular/material/menu';
import { SortOrder } from '@arkiq-portals/sdk';
import { debounceTime, distinctUntilChanged, Subscription } from 'rxjs';
import { ButtonComponent } from '../../button/button.component';
import { IconButtonComponent } from '../../icon-button/icon-button.component';
import { InputComponent } from '../../input/input.component';
import { SelectOption } from '../../select/select.component';

export type TableColumnHeaderMenuCloseEvent = {
  sort?: SortOrder;
  options: string[];
  startDate: string;
  endDate: string;
};

export type TableColumnHeaderOptions = {
  value: string;
  label: string;
  isSelected: boolean;
};

export enum TableColumnHeaderFilterType {
  MULTIPLE_SELECT = 'MULTIPLE_SELECT',
  DATE_RANGE = 'DATE_RANGE',
}

export type TableColumnHeaderFilter = {
  type: TableColumnHeaderFilterType;
  options: TableColumnHeaderOptions[];
  control: FormControl<any | null> | FormGroup;
};

@Component({
  selector: 'arkiq-table-column-header-button',
  templateUrl: './table-column-header-button.component.html',
  standalone: true,
  providers: [provideNativeDateAdapter()],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    MatMenuModule,
    MatDatepickerModule,
    IconButtonComponent,
    ButtonComponent,
    InputComponent,
  ],
})
export class TableColumnHeaderButtonComponent implements OnInit, OnDestroy {
  @Input() isSortable = true;
  @Input() filter?: TableColumnHeaderFilter;

  @Output() closeMenu = new EventEmitter<TableColumnHeaderMenuCloseEvent>();

  public form = new FormGroup({
    search: new FormControl(''),
    sort: new FormControl<SortOrder | null>(null),
    selectedOptions: new FormControl<string[]>([]),
    startDate: new FormControl<Date | null>(null),
    endDate: new FormControl<Date | null>(null),
  });

  public filteredOptions: SelectOption[] = [];

  private subscriptions = new Subscription();

  constructor() {}

  public ngOnInit(): void {
    this.subscriptions.add(
      this.form.controls.search.valueChanges
        .pipe(distinctUntilChanged(), debounceTime(500))
        .subscribe(search => {
          const allOptions = this.filter?.options ?? [];

          this.filteredOptions = search
            ? allOptions.filter(option =>
                option.label.toLowerCase().includes(search.toLowerCase()),
              )
            : allOptions;
        }),
    );

    if (this.filter?.options.length) {
      const selectedOptions = this.filter.options
        .filter(option => option.isSelected)
        .map(option => option.value);

      this.form.patchValue({ selectedOptions });
    }
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  public handleSelectAllOptions() {
    if (!this.filter) {
      return;
    }

    this.form.patchValue({
      selectedOptions: this.filter.options.map(option => option.value),
    });
  }

  public handleClearAllOptions() {
    this.form.patchValue({ selectedOptions: [] });
  }

  public handleSelectOption(optionValue: string) {
    if (!this.filter) {
      return;
    }

    let selectedOptions: string[] = [];

    if (this.form.value.selectedOptions?.includes(optionValue)) {
      selectedOptions = this.form.value.selectedOptions.filter(
        selectedOption => selectedOption !== optionValue,
      );
    } else {
      selectedOptions = [
        ...(this.form.value.selectedOptions ?? []),
        optionValue,
      ];
    }

    this.form.patchValue({ selectedOptions });
  }

  public handleSave() {
    this.closeMenu.emit({
      options: this.form.value.selectedOptions ?? [],
      sort: this.form.value.sort ?? undefined,
      startDate: this.form.value.startDate?.toISOString() ?? '',
      endDate: this.form.value.endDate?.toISOString() ?? '',
    });
  }

  public get displayOptions(): SelectOption[] {
    return this.form.value.search
      ? this.filteredOptions
      : this.filter?.options ?? [];
  }
  public get hasSelectedOptions(): boolean {
    if (!this.form.value.selectedOptions) return false;
    return this.form.value.selectedOptions?.length > 0;
  }
}
