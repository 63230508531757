<button
  type="button"
  class="size-8 flex items-center justify-center hover:bg-black/5 transition-colors rounded-full"
  [matTooltip]="tooltipText"
  [disabled]="disabled"
>
  <i
    class="bi text-[#717579] text-[15px]"
    [ngClass]="[icon, customIconClass]"
  ></i>
</button>
