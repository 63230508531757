import { TableColumn, TableColumnType } from '@arkiq-portals/ui';

export type TableGenerateCsvOptions<TData> = {
  columns: Array<
    Pick<
      TableColumn<TData>,
      'valueKey' | 'valueFn' | 'label' | 'type' | 'pictureAndTextValueFn'
    >
  >;
  data: any[];
};

export function getColumnsToExport<TData>(columns: TableColumn<TData>[]): TableGenerateCsvOptions<TData>['columns'] {
  return columns
    .filter(
      column =>
        column.type !== TableColumnType.ACTIONS &&
        column.label &&
        (column.valueKey || column.valueFn || column.pictureAndTextValueFn),
    )
    .map(column => ({
      label: column.label,
      valueKey: column.valueKey,
      valueFn: column.valueFn,
      type: column.type,
      pictureAndTextValueFn: column.pictureAndTextValueFn,
    }));
}

export function generateCsv<TData>({ columns, data }: Pick<TableGenerateCsvOptions<TData>, 'columns' | 'data'>) {
  let csvContent = columns.map(column => `"${column.label}"`).join(';');

  csvContent += '\n';

  data.forEach((d, index) => {
    const line: string[] = [];

    for (const column of columns) {
      let value: any;

      switch (column.type) {
        case TableColumnType.PICTURE_AND_TEXT:
          value = column.pictureAndTextValueFn ? column.pictureAndTextValueFn(d).text : '';
          break;

        default:
          value = column.valueFn ? column.valueFn(d) : d[column.valueKey ?? ''];
          break;
      }


      line.push(`"${value?.toString().replace(/"/g, '""')}"`);
    }

    csvContent += line.join(';') + '\n';
  });

  const encodedUri = 'data:text/csv;charset=utf-8,%EF%BB%BF' + encodeURI(csvContent);
  return encodedUri;
}
