import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { MatMenuModule } from '@angular/material/menu';
import { AuthService, UsersService } from '@arkiq-portals/sdk';

@Component({
  selector: 'arkiq-user-badge',
  templateUrl: 'user-badge.component.html',
  standalone: true,
  imports: [CommonModule, MatMenuModule],
})
export class UserBadgeComponent {
  constructor(
    private usersService: UsersService,
    private authService: AuthService,
  ) {}

  public get userPhoto() {
    return this.usersService.loggedUser?.photo ?? '';
  }

  public get userName() {
    const firstName = this.usersService.loggedUser?.firstName ?? '';
    const lastName = this.usersService.loggedUser?.lastName ?? '';
    return `${firstName} ${lastName}`;
  }

  public get userEmail() {
    return this.usersService.loggedUser?.email ?? '';
  }

  public handleSignOut() {
    this.authService.signOut();
  }
}
