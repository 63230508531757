import { CommonModule, Location } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { Router } from '@angular/router';
import {
  Organization,
  OrganizationsService,
  PropertiesService,
  Property,
  PropertyType,
  PropertyTypeLabel,
  SortOrder,
  UserRole,
} from '@arkiq-portals/sdk';
import {
  ClearFiltersTypes,
  DialogAlertComponent,
  DialogAlertParams,
  DialogAlertVariant,
  DialogConfirmActionComponent,
  TableColumn,
  TableColumnHeaderFilterType,
  TableColumnHeaderOptions,
  TableColumnType,
  TableComponent,
  TableHeaderButton,
  TableRowClickedEvent,
  TableSortChangeEvent,
} from '@arkiq-portals/ui';
import { Subscription, take } from 'rxjs';

@Component({
  selector: 'app-properties-list',
  templateUrl: './properties-list.component.html',
  standalone: true,
  imports: [CommonModule, MatDialogModule, TableComponent],
})
export class PropertiesListComponent implements OnInit, OnDestroy {
  public isLoading = false;
  public properties: Property[] = [];
  public subscriptions = new Subscription();
  public search = '';
  public propertyDevices = {};

  public organizationsOptions: TableColumnHeaderOptions[] = [];

  public typeOptions: TableColumnHeaderOptions[] = Object.keys(
    PropertyType,
  ).map(type => ({
    label: PropertyTypeLabel[type],
    value: type,
    isSelected: false,
  }));

  public page = 1;
  public limit = 10;
  public totalItems = 0;
  public totalPages = 1;

  public stateOptions: TableColumnHeaderOptions[] = [];
  public cityOptions: TableColumnHeaderOptions[] = [];

  public columns: TableColumn<Property>[] = [];

  public tableHeaderButtons: TableHeaderButton[] = [
    {
      type: 'default-button',
      text: 'Add Property',
      icon: 'bi-plus',
      variant: 'primary',
      handler: () => this.handleAddProperty(),
      allowedRoles: [UserRole.ORGANIZATION_MASTER],
    },
  ];
  public CLEAR_FILTERS_BUTTON_TYPE = ClearFiltersTypes.TEXT


  public filterForm = new FormGroup({
    search: new FormControl(''),
    orderBy: new FormControl('created_at'),
    order: new FormControl<SortOrder>('desc_nulls_last'),
    type: new FormControl<string[]>([]),
    organizationId: new FormControl<string[]>([]),
    city: new FormControl<string[]>([]),
    state: new FormControl<string[]>([]),
    propertiesIds: new FormControl<string[]>([])
  });

  constructor(
    private propertiesService: PropertiesService,
    private router: Router,
    private matDialog: MatDialog,
    private organizationsService: OrganizationsService,
    private location: Location
  ) {}

  public ngOnInit() {
    const state = this.location.getState()
    if (state && state['ids']) {
      const propertiesIds = state['ids']

      this.filterForm.patchValue({
        propertiesIds
      })
    }
    this.listProperties(false);
    this.subscriptions.add(
      this.filterForm.valueChanges.subscribe(() => {
        this.listProperties();
      }),
    );
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  public onClearFilters() {
    this.filterForm.setValue({
      search: '',
      order: 'desc_nulls_last',
      orderBy: 'created_at',
      type: [],
      organizationId: [],
      city: [],
      state: [],
      propertiesIds: []
    });
  }

  public onTableSortChanged(event: TableSortChangeEvent) {
    switch (event.columnId) {
      case 'name':
        this.filterForm.patchValue({
          orderBy: 'name',
          order: event.order,
        });
        break;
      case 'type':
        this.filterForm.patchValue({
          orderBy: 'type',
          order: event.order,
        });
        break;
      case 'organization':
        this.filterForm.patchValue({
          orderBy: 'organizationId',
          order: event.order,
        });
        break;
      case 'city':
        this.filterForm.patchValue({
          orderBy: 'address_city',
          order: event.order,
        });
        break;
      case 'state':
        this.filterForm.patchValue({
          orderBy: 'address_state',
          order: event.order,
        });
        break;
    }
  }

  public async listProperties(loadJustProperties = true) {
    try {
      this.isLoading = true;

      this.properties = [];
      const response = await this.propertiesService.list({
        page: this.page,
        limit: this.limit,
        search: this.search,
        orderBy: this.filterForm.value.orderBy || 'name',
        order: this.filterForm.value.order || 'asc_nulls_first',
        type: this.filterForm.value.type,
        organizationId: this.filterForm.value.organizationId
          ? this.filterForm.value.organizationId.map(Number)
          : [],
        city: this.filterForm.value.city,
        state: this.filterForm.value.state,
        propertiesIds: this.filterForm.value.propertiesIds || []
      });

      this.properties = response.properties;
      this.totalItems = response.totalItems;
      this.totalPages = Math.ceil(response.totalItems / this.limit);

      if (!loadJustProperties) {
        const organizations = await this.organizationsService.list();

        this.buildOrganizationOptions(organizations.organizations);
        this.buildCityAndStateOptions();
      }
      this.buildDevices();
      // this.buildTableHeaderFilters();
      this.buildTableColumns();

      this.isLoading = false;
    } catch (error) {
      console.error(error);
      this.isLoading = false;

      this.matDialog.open(DialogAlertComponent, {
        hasBackdrop: true,
        disableClose: false,
        data: {
          variant: DialogAlertVariant.ERROR,
          title: 'Unable to list properties',
          text: error,
        } as DialogAlertParams,
      });
    }
  }
  public async buildDevices() {
    const propertiesId = this.properties.map(property => property.id);
    const propertiesWithDeviceCount =
      await this.propertiesService.listPropertiesWithDeviceCount(propertiesId);
    propertiesWithDeviceCount.nodes.forEach(property => {
      if (property.id in this.propertyDevices) return;
      this.propertyDevices[property.id] =
        property.devices_aggregate.aggregate.count;
    });
  }

  public handleAddProperty(): void {
    this.router.navigateByUrl('/properties/create');
  }

  public handleEditProperty(property: Property) {
    this.router.navigateByUrl(`/properties/${property.id}/edit`, {
      state: { property },
    });
  }

  public handleDeleteProperty(property: Property) {
    const ref = this.matDialog.open(DialogConfirmActionComponent, {
      hasBackdrop: true,
      disableClose: false,
      data: {
        title: 'Are you sure?',
        text: `Are you sure you want to delete ${property.name}?`,
      },
    });

    ref
      .afterClosed()
      .pipe(take(1))
      .subscribe(async data => {
        if (data.confirm) {
          this._deleteProperty(property.id);
        }
      });
  }

  public onSearch(search: string) {
    this.search = search;
    this.listProperties(true);
  }

  public onPropertyClicked(event: TableRowClickedEvent<Property>) {
    const property = event.data;
    this.router.navigateByUrl(`/properties/${property.id}`, {
      state: {
        property,
      },
    });
  }

  public onTableRowsLimitChanged(newLimit: number): void {
    this.limit = newLimit;
    this.listProperties(true);
  }

  public onTablePageChanged(newPage: number): void {
    this.page = newPage;
    this.listProperties(true);
  }

  private async _deleteProperty(userId: number) {
    try {
      this.isLoading = true;

      await this.propertiesService.delete(userId);
      await this.listProperties();

      this.isLoading = false;
    } catch (error) {
      console.error(error);
      this.isLoading = false;
      alert(error);
    }
  }

  private buildOrganizationOptions(organizations: Organization[]) {
    const options: TableColumnHeaderOptions[] = organizations
      .filter(organization => organization.properties?.length)
      .map(organization => ({
        label: organization.name,
        value: String(organization.id),
        isSelected: false,
      }));
    this.organizationsOptions = options;
  }

  private buildCityAndStateOptions() {
    const stateObject: {
      [key: string]: { label: string; value: any; isSelected: boolean };
    } = {};
    const cityObject: {
      [key: string]: { label: string; value: any; isSelected: boolean };
    } = {};

    this.properties.forEach(property => {
      const state = property.address_state;
      const city = property.address_city;
      if (!stateObject[state] && state.length > 0) {
        stateObject[state] = { label: state, value: state, isSelected: false };
      }
      if (!cityObject[city] && city.length > 0) {
        cityObject[city] = { label: city, value: city, isSelected: false };
      }
    });

    this.stateOptions = Object.values(stateObject);
    this.cityOptions = Object.values(cityObject);
  }

  public buildTableColumns(): void {
    this.columns = [
      {
        type: TableColumnType.PICTURE_AND_TEXT,
        label: 'Name',
        id: 'name',
        sortable: true,
        pictureAndTextValueFn: property => ({
          text: property.name,
          picture: property.photo,
        }),
        headerClasses: 'text-left',
        valueClasses: 'text-left cursor-pointer text-black',
        columnLink: property => ({ url: `/properties/${property.id}`, state: {} }),
      },
      {
        type: TableColumnType.TEXT,
        label: 'Type',
        id: 'type',
        sortable: true,
        valueFn: property => PropertyTypeLabel[property.type],
        headerClasses: 'justify-center',
        valueClasses: 'text-center cursor-pointer',
        filter: {
          type: TableColumnHeaderFilterType.MULTIPLE_SELECT,
          options: this.typeOptions,
          control: this.filterForm.controls.type,
        },
      },
      {
        type: TableColumnType.TEXT,
        label: 'Organization',
        id: 'organization',
        sortable: true,
        valueFn: property =>
          property.organization
            ? property.organization.name
            : String(property.organizationId),
        headerClasses: 'justify-center',
        valueClasses: 'text-center cursor-pointer text-black',
        filter: {
          type: TableColumnHeaderFilterType.MULTIPLE_SELECT,
          options: this.organizationsOptions,
          control: this.filterForm.controls.organizationId,
        },
        columnLink: property => ({ url: `/organizations/${property.organizationId}`, state: {} }),
      },
      {
        type: TableColumnType.TEXT,
        label: 'City',
        id: 'city',
        sortable: true,
        valueFn: property => property.address_city,
        headerClasses: 'justify-center',
        valueClasses: 'text-center cursor-pointer',
        filter: {
          type: TableColumnHeaderFilterType.MULTIPLE_SELECT,
          options: this.cityOptions,
          control: this.filterForm.controls.city,
        },
      },
      {
        type: TableColumnType.TEXT,
        label: 'State',
        id: 'state',
        sortable: true,
        valueFn: property => property.address_state,
        headerClasses: 'justify-center',
        valueClasses: 'text-center cursor-pointer',
        filter: {
          type: TableColumnHeaderFilterType.MULTIPLE_SELECT,
          options: this.stateOptions,
          control: this.filterForm.controls.state,
        },
      },
      {
        type: TableColumnType.TEXT,
        label: 'Total Devices',
        id: 'total-devices',
        valueFn: property => this.propertyDevices[property.id] || '0',
        headerClasses: 'justify-center',
        valueClasses: 'text-center cursor-pointer text-black',
        columnLink: property => {
          return {
            url: '/devices',
            state: {
              propertyId: property.id,
              organizationId: property.organizationId,
            },
            disabled: !this.propertyDevices[property.id],
          };
        },
      },
      {
        type: TableColumnType.ACTIONS,
        label: 'Actions',
        allowedRoles: [UserRole.ORGANIZATION_MASTER],
        headerClasses: 'justify-center',
        valueClasses: 'text-center cursor-pointer',
        actionButtons: [
          {
            type: 'icon-button',
            icon: 'bi-pencil-square',
            handler: property => this.handleEditProperty(property),
            tooltip: 'Edit property',
          },
          {
            type: 'icon-button',
            icon: 'bi-trash-fill',
            handler: property => this.handleDeleteProperty(property),
            tooltip: 'Delete property',
          },
        ],
      },
    ];
  }
}
