import { CommonModule } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import {
    MatAutocompleteModule,
    MatAutocompleteSelectedEvent,
} from '@angular/material/autocomplete';
import {
    MAT_DIALOG_DATA,
    MatDialog,
    MatDialogModule,
    MatDialogRef,
} from '@angular/material/dialog';
import { MatTooltipModule } from '@angular/material/tooltip';
import {
    DeviceOverview,
    DevicesOverviewService,
    Fixture,
    FixturesService,
} from '@arkiq-portals/sdk';
import { formatDate } from 'date-fns';
import { Subscription, debounceTime, distinctUntilChanged } from 'rxjs';
import {
    ButtonComponent,
    IconButtonComponent,
    InputComponent,
    SelectComponent, SpinnerComponent
} from '../../components';
import {
    DialogAlertComponent,
    DialogAlertParams,
    DialogAlertVariant,
} from '../dialog-alert/dialog-alert.component';

export type DialogAssignDeviceParams = {
  fixture: Fixture;
};

@Component({
  selector: 'arkiq-dialog-assign-device',
  templateUrl: './dialog-assign-device.component.html',
  standalone: true,
  imports: [
    CommonModule,
    MatDialogModule,
    MatAutocompleteModule,
    MatTooltipModule,
    IconButtonComponent,
    SelectComponent,
    ButtonComponent,
    SpinnerComponent,
    InputComponent,
  ],
})
export class DialogAssignDeviceComponent implements OnInit {
  public isLoading = false;
  public isSaving = false;

  public deviceControl = new FormControl<any>('');
  public selectedDevice!: DeviceOverview;
  public devicesFiltered: DeviceOverview[] = [];
  public devices: DeviceOverview[] = [];
  public deviceLastActivity: string = '';
  public subscriptions = new Subscription();
  constructor(
    @Inject(MAT_DIALOG_DATA) private data: DialogAssignDeviceParams,
    public dialogRef: MatDialogRef<DialogAssignDeviceComponent>,
    private devicesOverviewService: DevicesOverviewService,
    private matDialog: MatDialog,
    private fixturesService: FixturesService,
  ) {}

  public ngOnInit(): void {
    this.loadDevices();
    this.subscriptions.add(
      this.deviceControl.valueChanges
        .pipe(distinctUntilChanged(), debounceTime(500))
        .subscribe(value => {
          this.devicesFiltered = this.devices.filter(device =>
            device.id.includes(value),
          );
        }),
    );
  }

  public async loadDevices(): Promise<void> {
    try {
      this.isLoading = true;

      const devices = await this.devicesOverviewService.list({
        propertyId: [this.data.fixture.property_id],
        limit: 9999,
      });

      this.devices = devices;
      this.devicesFiltered = devices;

      this.isLoading = false;
    } catch (error) {
      this.isLoading = false;

      console.error(error);

      this.matDialog.open(DialogAlertComponent, {
        hasBackdrop: true,
        disableClose: false,
        data: {
          variant: DialogAlertVariant.ERROR,
          title: 'Unable to fetch devices',
          text: error,
        } as DialogAlertParams,
      });
    }
  }

  public async handleAssignDevice(): Promise<void> {
    try {
      this.isSaving = true;
      this.deviceControl.disable();

      const deviceId = this.deviceControl.value;
      await this.fixturesService.updateDeviceId(this.data.fixture.id, deviceId);

      this.isSaving = false;

      const device = this.devices.find(
        curDevice => curDevice.id === deviceId,
      ) as DeviceOverview;

      this.dialogRef.close({ deviceId, device });
    } catch (error) {
      console.error(error);

      this.matDialog.open(DialogAlertComponent, {
        hasBackdrop: true,
        disableClose: false,
        data: {
          variant: DialogAlertVariant.ERROR,
          title: 'Unable to fetch devices',
          text: error,
        } as DialogAlertParams,
      });
    } finally {
      this.deviceControl.enable();
      this.isSaving = false;
    }
  }

  public deviceText(device: DeviceOverview) {
    const deviceLastActivity = device.last_alert
      ? formatDate(device.last_alert, 'yyyy-MM-dd')
      : 'No record';
    return `#${device.id}  •  Last activity: ${deviceLastActivity}`;
  }

  public onDeviceSelected(event: MatAutocompleteSelectedEvent) {
    const device = this.devices.find(
      device => device.id === event.option.value,
    );
    if (!device) return;
    const lastActivity = this.deviceText(device).split('•')[1].trim();
    this.deviceLastActivity = lastActivity;
    this.deviceControl.setValue(device.id);
  }
}
