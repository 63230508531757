<section
  class="border-b border-b-[#E0E0E0] px-[26px] py-3 flex items-center gap-6"
>
  <a
    *ngFor="let tab of tabs"
    [routerLink]="'/properties/' + property.id + '/' + tab.route"
    class="text-sm font-medium py-2 px-4 rounded-[5px]"
    [ngClass]="[
      currentRoute === tab.route ? 'text-white bg-primary' : 'text-[#AAAAAA]'
    ]"
  >
    {{ tab.text }}
  </a>
</section>
