export type Fixture = {
  id: number;
  name: string;
  type: FixtureType;
  status: string;
  device_id: string;
  location_id: number;
  property_id: number;
  created_at: string;
  updated_at: string;
};

export enum FixtureType {
  SINK = 'SINK',
  TOILET = 'TOILET',
}
