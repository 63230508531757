import { Injectable } from '@angular/core';
import { Apollo, gql } from 'apollo-angular';
import { firstValueFrom } from 'rxjs';
import { BuildOrganization, Organization, UserRole } from '../models';
import { SortOrder } from '../types';
import { PropertiesService } from './properties.service';
import { UserOrganizationRoleService } from './user-organization-role.service';
import { UsersService } from './users.service';

export type ListOrganizationsParams = {
  page?: number | null;
  limit?: number | null;
  search?: string | null;
  names?: string[] | null;
  masters?: string[] | null;
  orderBy?: string;
  order?: SortOrder
  organizationsIds?: number[];
};

export type ListOrganizationsResponse = {
  organizations: Organization[];
  totalItems: number;
};
export type ListOrganizationsQueryResult = {
  front_organizations: Organization[];
  front_organizations_aggregate: {
    aggregate: {
      count: number;
    };
  };
};



const GET_ORGANIZATIONS_QUERY = (search = '') => gql`
  query ListOrganizations {
    front_organizations${search} {
      id
      name
      created_at
      updated_at
      description
      properties {
        address_street
        address_city
        address_state
        address_zip_code
        created_at
        id
        name
        type
        updated_at
        organizationId
        users_organizations_roles {
          role
          user {
            id
            firstName
            lastName
            phoneNumber
            email
          }
        }
      }
      users_organizations_roles {
        id
        organizationId
        userId
        role
        propertyId
        organization {
          id
          name
        }
        property {
          id
          name
          type
          address_street
          address_city
          address_state
          address_zip_code
        }
        user {
          id
          firstName
          lastName
          email
          phoneNumber
          photo
          role
          type
          firebaseId
          created_at
          updated_at
        }
      }
      devices_details_aggregate {
        aggregate {
          count
        }
      }
    }
  }
`;

@Injectable({ providedIn: 'root' })
export class OrganizationsService {
  constructor(
    private apollo: Apollo,
    private propertiesService: PropertiesService,
    private userOrganizationRolesService: UserOrganizationRoleService,
    private usersService: UsersService,
  ) {}

  public async list(
    params?: ListOrganizationsParams,
  ): Promise<ListOrganizationsResponse> {
    try {
      const page = params?.page ?? 1;
      const limit = params?.limit ?? 10;
      const offset = (page - 1) * limit;
      const search = params?.search;
      const orderBy = params?.orderBy || 'name';
      const order = params?.order || 'asc_nulls_first';
      const names = params?.names ?? [];
      const masters = params?.masters ?? [];
      const user = this.usersService.loggedUser;
      const isUserCustomer = this.usersService.isLoggedUserCustomer;
      const hasZeroOrganization =
        this.usersService.loggedUserOrganizations.length === 0;

      const emptyResponse = {
        organizations: [],
        totalItems: 1,
      };

      if (!user) {
        return emptyResponse;
      }

      if (isUserCustomer && hasZeroOrganization) {
        return emptyResponse;
      }

      const ids: number[] = [];

      const userOrganizationsIds = this.usersService.loggedUserOrganizations;
      const organizationsIdsParams = params && params.organizationsIds ? params.organizationsIds : [];

      if (isUserCustomer) {
        const filteredOrganizationsIds = organizationsIdsParams.filter(
          id => userOrganizationsIds.includes(Number(id))
        );

        ids.push(
          ...(
            filteredOrganizationsIds.length
              ? filteredOrganizationsIds
              : userOrganizationsIds
          ),
        );
      } else {
        ids.push(
          ...(
            organizationsIdsParams.length
              ? organizationsIdsParams
              : []
          ),
        );
      }

      // const userOrganizationsIds = this.usersService.loggedUserOrganizations;
      // const organizationsIdsParams  = params && params.organizationsIds ? params.organizationsIds : []

      // const filteredOrganizationsIds = organizationsIdsParams.filter(id => userOrganizationsIds.includes(Number(id)))

      // const organizationFilter = !isUserCustomer
      // ? (organizationsIdsParams.length > 0
      //     ? `id: { _in: [${organizationsIdsParams.join(',')}] }`
      //     : '')
      // : (organizationsIdsParams.length > 0
      //     ? `id: { _in: [${filteredOrganizationsIds.join(',')}] }`
      //     : `id: { _in: [${this.usersService.loggedUserOrganizations.join(',')}] }`
      // );

      // const organizationsIds = Array.from(
      //   new Set([...userOrganizationsIds, ...names]),
      // ).join(',');

      // const organizationsByUser = isUserCustomer
      //   ? `id: {_in: [${organizationsIds}]}`
      //   : names.length > 0
      //   ? `id: {_in: [${names.join(',')}]}`
      //   : '';

      const orderByField = `
        ${
          orderBy == 'properties'
            ? `properties_aggregate: {count: ${order} }`
            : ''
        }
        ${
          orderBy == 'users'
            ? `users_organizations_roles_aggregate: {count: ${order}}`
            : ''
        }
        ${
          orderBy == 'devices'
            ? `devices_details_aggregate: {count: ${order}}`
            : ''
        }
        ${orderBy == 'name' ? `name: ${order}` : ''}
      `;

      const query = `
        order_by: {${orderByField}}
        where: {
          ${
            ids.length
              ? `id: {_in: [${ids.join(',')}]}`
              : ''
          }

          ${
            masters.length > 0
              ? `users_organizations_roles: {
                _and: {userId: {_in: [${masters.join(',')}]}, role: {_eq: "${
                  UserRole.ORGANIZATION_MASTER
                }"}}
          }`
              : ''
          }


          ${
            search && search.length > 0
              ? `_or: {name: {_ilike:"%${search}%"} }`
              : ''
          }
        }
      `;

      const response = await firstValueFrom(
        this.apollo.query<ListOrganizationsQueryResult>({
          query: gql`query ListOrganizations {
            front_organizations (
              limit: ${limit}
              offset: ${offset}
              ${query}
            ) {
              id
              name
              created_at
              updated_at
              properties {
                address_street
                address_city
                address_state
                address_zip_code
                created_at
                id
                name
                type
                updated_at
                organizationId
              }
              users_organizations_roles {
                id
                organizationId
                userId
                role
                propertyId
                organization {
                  id
                  name
                }
                property {
                  id
                  name
                  type
                  address_street
                  address_city
                  address_state
                  address_zip_code
                }
                user {
                  id
                  firstName
                  lastName
                  email
                  phoneNumber
                  photo
                  role
                  type
                  firebaseId
                  created_at
                  updated_at
                }
              }
              devices_details_aggregate {
                aggregate {
                  count
                }
              }

              devices_online: devices_details_aggregate(where: {status: {_eq: "online"}}) {
                aggregate {
                  count
                }
              }
            }
            front_organizations_aggregate(${query}) {
              aggregate {
                count
              }
            }
          }`,
          fetchPolicy: 'no-cache',
        }),
      );
      const organizations =
        response.data.front_organizations.map(BuildOrganization);
      return {
        organizations,
        totalItems: response.data.front_organizations_aggregate.aggregate.count,
      };
    } catch (error: any) {
      console.log(error);

      throw new Error(error?.error?.message || error?.message || error);
    }
  }

  public async simpleList(): Promise<Organization[]> {
    try {
      let search = '';

      if (this.usersService.isLoggedUserCustomer) {
        if (this.usersService.loggedUserOrganizations.length === 0) {
          return [];
        }

        search = `(where: {id: {_in: [${this.usersService.loggedUserOrganizations.join(
          ',',
        )}]}})`;
      }

      const response = await firstValueFrom(
        this.apollo.query<{ front_organizations: Organization[] }>({
          query: GET_ORGANIZATIONS_QUERY(search),
          fetchPolicy: 'no-cache',
        }),
      );

      return response.data.front_organizations.map(BuildOrganization);
    } catch (error: any) {
      throw new Error(error?.error?.message || error?.message || error);
    }
  }

  public async searchByName(search: string): Promise<Organization[]> {
    try {
      if (
        this.usersService.isLoggedUserCustomer &&
        this.usersService.loggedUserOrganizations.length === 0
      ) {
        return [];
      }

      const searchQuery = `(where: {
        _or: [
          {
            name: { _ilike: "%${search}%" },
            ${
              this.usersService.isLoggedUserCustomer
                ? `id: {_in: [${this.usersService.loggedUserOrganizations.join(
                    ',',
                  )}]}`
                : ''
            }
          },
        ]
      })`;

      const response = await firstValueFrom(
        this.apollo.query<{ front_organizations: Organization[] }>({
          query: GET_ORGANIZATIONS_QUERY(searchQuery),
        }),
      );

      return response.data.front_organizations.map(BuildOrganization);
    } catch (error: any) {
      throw new Error(error?.error?.message || error?.message || error);
    }
  }

  public async getById(organizationId: number): Promise<Organization> {
    try {
      const searchQuery = `(where: {id: {_eq: ${organizationId}}})`;

      const response = await firstValueFrom(
        this.apollo.query<{ front_organizations: Organization[] }>({
          query: GET_ORGANIZATIONS_QUERY(searchQuery),
        }),
      );

      return BuildOrganization(response.data.front_organizations[0]);
    } catch (error: any) {
      throw new Error(error?.error?.message || error?.message || error);
    }
  }

  public async delete(id: number): Promise<void> {
    try {
      await this.propertiesService.deleteByOrganizationId(id);
      await this.userOrganizationRolesService.deleteByOrganizationId(id);

      await firstValueFrom(
        this.apollo.mutate({
          mutation: gql`
            mutation DeleteOrganization {
              delete_front_organizations(where: {id: {_eq: ${id}}}) {
                returning {
                  id
                }
              }
            }
          `,
        }),
      );
    } catch (error: any) {
      throw new Error(error?.error?.message || error?.message || error);
    }
  }

  public async create(data: Partial<Organization>) {
    try {
      const response = await firstValueFrom(
        this.apollo.mutate<{ insert_front_organizations_one: Organization }>({
          mutation: gql`
            mutation InsertOrganization($name: String = "${data.name}", $description: String = "${data.description}") {
              insert_front_organizations_one(object: {name: $name, description: $description}) {
                id
                name
                description
                created_at
                updated_at
                properties {
                  address_street
                  address_city
                  address_state
                  address_zip_code
                  created_at
                  id
                  name
                  type
                  updated_at
                  organizationId
                }
                users_organizations_roles {
                  id
                  organizationId
                  userId
                  role
                  propertyId
                  organization {
                    id
                    name
                  }
                  property {
                    id
                    name
                    type
                    address_street
                    address_city
                    address_state
                    address_zip_code
                  }
                }
              }
            }
          `,
        }),
      );

      return BuildOrganization(response.data?.insert_front_organizations_one);
    } catch (error: any) {
      throw new Error(error?.error?.message || error?.message || error);
    }
  }

  public async update(organizationId: number, data: Partial<Organization>) {
    try {
      const response = await firstValueFrom(
        this.apollo.mutate<{
          update_front_organizations: { returning: Organization[] };
        }>({
          mutation: gql`
            mutation UpdateOrganization($_eq: Int = ${organizationId}, $name: String = "${data.name}", $description: String = "${data.description}") {
              update_front_organizations(where: {id: {_eq: $_eq}}, _set: {name: $name, description: $description}) {
                returning {
                  id
                  name
                  description
                  created_at
                  updated_at
                  properties {
                    address_street
                    address_city
                    address_state
                    address_zip_code
                    created_at
                    id
                    name
                    type
                    updated_at
                    organizationId
                  }
                  users_organizations_roles {
                    id
                    organizationId
                    userId
                    role
                    propertyId
                    organization {
                      id
                      name
                    }
                    property {
                      id
                      name
                      type
                      address_street
                      address_city
                      address_state
                      address_zip_code
                    }
                  }
                }
              }
            }
          `,
        }),
      );

      return BuildOrganization(
        response.data?.update_front_organizations.returning[0],
      );
    } catch (error: any) {
      throw new Error(error?.error?.message || error?.message || error);
    }
  }
}
