
import { HttpClientModule } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { AngularFireAuth } from "@angular/fire/compat/auth";
import { ApolloLink, InMemoryCache } from "@apollo/client/core";
import { setContext } from '@apollo/client/link/context';
import { APOLLO_OPTIONS, ApolloModule } from 'apollo-angular';
import { HttpLink } from 'apollo-angular/http';
import { jwtDecode } from "jwt-decode";
import { firstValueFrom } from "rxjs";
import { environment } from "../../environments/environment";

const authCtx = (auth: AngularFireAuth) =>
  setContext(async () => {
    try {
      const user = await firstValueFrom(auth.user);
      const token = await user?.getIdToken(true);

      if (!token) {
        console.log('GraphQL Module - No Token');
        auth.signOut();
        window.location.href = '/auth';
        return;
      }

      jwtDecode(token);

      return {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
    } catch (error) {
      console.log('GraphQL Module - Failed to decode token');
      auth.signOut();
      window.location.href = '/auth';
      return {};
    }
  });

const uri = environment.apiUrl; // <-- add the URL of the GraphQL server here

export function createApollo(httpLink: HttpLink, auth: AngularFireAuth) {
  return {
    link: ApolloLink.from([authCtx(auth), httpLink.create({ uri })]),
    cache: new InMemoryCache(),
    defaultOptions: {
      query: {
        fetchPolicy: 'no-cache',
      },
    },
  };
}

@NgModule({
  exports: [ApolloModule, HttpClientModule],
  providers: [
    {
      provide: APOLLO_OPTIONS,
      useFactory: createApollo,
      deps: [HttpLink, AngularFireAuth],
    },
  ],
})
export class GraphQLModule { }