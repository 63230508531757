<label class="w-full  focus-within:text-black text-tertiary flex flex-col gap-2">
  <span
    *ngIf="label"
    class="font-medium text-black"
    [ngClass]="[
      required ? 'required' : '',
      size === 'md' ? 'text-[15px]' : 'text-lg',
    ]"
  >
    {{ label }}
  </span>
  
  <div
    [ngClass]="[
      classes.base,
      classes[variant],
      invalid ? classes.invalid : '',
      sizes[size],
      iconPosition === 'right' ? 'flex-row' : 'flex-row-reverse',
      rounded ? 'rounded-full' : 'rounded-[5px]',
      control.invalid && (control.dirty || control.touched) ? 'border-state-cancel' : '',
      disabled ? 'bg-black/5' : 'bg-transparent'
    ]"
  >
    <input
      *ngIf="matAutocomplete"
      #inputRef1
      [formControl]="control"
      [placeholder]="placeholder"
      [attr.disabled]="disabled"
      [type]="type"
      class="w-full outline-none text-sm bg-transparent"
      [matAutocomplete]="matAutocomplete"
    />
    
    <input
      *ngIf="!matAutocomplete"
      #inputRef2
      [formControl]="control"
      [placeholder]="placeholder"
      [attr.disabled]="disabled"
      [type]="type"
      class="w-full outline-none text-sm bg-transparent"
    />

    <i
      *ngIf="icon"
      class="bi text-sm"
      [ngClass]="[icon, variant === 'secondary' ? 'text-[#717579]' : '']"
    ></i>
  </div>
</label>
