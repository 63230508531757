import { CommonModule } from '@angular/common';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import {
    DeviceOverview,
    DeviceSmartValveView,
    DevicesSmartValveService,
    UserRole,
} from '@arkiq-portals/sdk';
import {
    DialogAlertComponent,
    DialogAlertParams,
    DialogAlertVariant,
    HasRoleDirective,
    SlideToggleComponent,
    SpinnerComponent,
    TableColumn,
    TableColumnType,
    TableComponent,
} from '@arkiq-portals/ui';
import { MINUTES_DIFFERENCE_UTC_TO_EST } from 'apps/admin/src/utils';
import { formatDate, subMinutes } from 'date-fns';
import { debounceTime, distinctUntilChanged, Subscription } from 'rxjs';

@Component({
  selector: 'app-device-smart-valve-table',
  templateUrl: './device-smart-valve-table.component.html',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatDialogModule,
    SpinnerComponent,
    TableComponent,
    SlideToggleComponent,
    HasRoleDirective,
  ],
})
export class DeviceSmartValveTableComponent implements OnInit, OnDestroy {
  @Input({ required: true }) device!: DeviceOverview;

  public isLoading = false;
  public isLoadingTable = false;
  public isUpdating = false;

  public USER_ROLES = UserRole;

  public isOpenedControl = new FormControl<boolean>(true);

  public tableColumns: TableColumn<DeviceSmartValveView>[] = [
    {
      type: TableColumnType.TEXT,
      label: 'Date/Time',
      valueFn: row => this.formattedDateForTimezone(row.date_time),
      valueClasses: 'text-center',
    },
    {
      type: TableColumnType.TEXT,
      label: 'Valve Status',
      valueFn: row => row.valve_status.toUpperCase(),
      valueClasses: 'text-center',
      valueClassesFn: row =>
        row.valve_status === 'open' ? 'text-primary' : 'text-black',
    },
    {
      type: TableColumnType.TEXT,
      label: 'Water Pressure',
      valueFn: row =>
        row.water_pressure ? row.water_pressure.toFixed(2) : '-',
      valueClasses: 'text-center',
    },
    {
      type: TableColumnType.TEXT,
      label: 'Water Temperature',
      valueFn: row =>
        row.water_temperature ? row.water_temperature + ' °F' : '-',
      valueClasses: 'text-center',
    },
    {
      type: TableColumnType.TEXT,
      label: 'Consumption Rate',
      valueFn: row =>
        row.consumption_rate ? row.consumption_rate + ' gal/h' : '-',
      valueClasses: 'text-center',
    },
    {
      type: TableColumnType.TEXT,
      label: 'Power Source',
      valueFn: row => row.power_source,
      valueClasses: 'text-center',
      valueClassesFn: row =>
        row.power_source === 'Outlet'
          ? 'text-state-completed'
          : 'text-state-cancel',
    },
    {
      type: TableColumnType.TEXT,
      label: 'f_cnt',
      valueFn: row => (row.f_cnt ? row.f_cnt.toString() : '-'),
      valueClasses: 'text-center',
    },
    {
      type: TableColumnType.TEXT,
      label: 'lastRssi',
      valueFn: row => (row.last_rssi ? row.last_rssi.toString() : '-'),
      valueClasses: 'text-center',
    },
    {
      type: TableColumnType.TEXT,
      label: 'lastSnr',
      valueFn: row => (row.last_snr ? row.last_snr.toString() : '-'),
      valueClasses: 'text-center',
    },
  ];

  public highlightData!: DeviceSmartValveView;

  public data: DeviceSmartValveView[] = [];
  public totalPages = 1;

  public page = 1;
  public limit = 10;

  private subscriptions = new Subscription();

  constructor(
    private matDialog: MatDialog,
    private deviceSmartValveViewService: DevicesSmartValveService,
  ) {}

  public ngOnInit(): void {
    this.loadData();

    this.subscriptions.add(
      this.isOpenedControl.valueChanges
        .pipe(distinctUntilChanged(), debounceTime(500))
        .subscribe(isOpened => this.handleToggleValve(isOpened as boolean)),
    );
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  public handleToggleValve(isOpened: boolean) {
    if (this.isUpdating) {
      return;
    }

    try {
      this.isUpdating = true;

      if (isOpened) {
        this.deviceSmartValveViewService.openValve(this.device.id);
      } else {
        this.deviceSmartValveViewService.closeValve(this.device.id);
      }

      this.isUpdating = false;
    } catch (error) {
      this.isUpdating = false;

      console.error(error);

      this.matDialog.open(DialogAlertComponent, {
        hasBackdrop: true,
        disableClose: false,
        data: {
          variant: DialogAlertVariant.ERROR,
          title: `Unable to ${isOpened ? 'close' : 'open'} valve`,
          text: error,
        } as DialogAlertParams,
      });
    }
  }

  public async loadData(): Promise<void> {
    try {
      this.isLoading = true;

      const [response, highlightResponse] = await Promise.all([
        this.deviceSmartValveViewService.listByDeviceId(this.device.id, {
          page: this.page,
          limit: this.limit,
        }),

        this.deviceSmartValveViewService.listByDeviceId(this.device.id, {
          limit: 1,
        }),
      ]);
      this.data = response.devices;

      this.highlightData = highlightResponse.devices[0];

      this.totalPages = Math.ceil(response.totalItems / this.limit);

      this.isLoading = false;
    } catch (error: any) {
      this.isLoading = false;

      this.matDialog.open(DialogAlertComponent, {
        hasBackdrop: true,
        disableClose: false,
        data: {
          title: 'Unable to fetch device data',
          text: error.message || 'Unexpected error',
          variant: DialogAlertVariant.ERROR,
        } as DialogAlertParams,
      });
    }
  }

  public async listTableData() {
    try {
      this.isLoadingTable = true;

      const response = await this.deviceSmartValveViewService.listByDeviceId(
        this.device.id,
        {
          page: this.page,
          limit: this.limit,
        },
      );

      this.data = response.devices;
      this.totalPages = Math.ceil(response.totalItems / this.limit);

      this.isLoadingTable = false;
    } catch (error: any) {
      console.error(error);

      this.isLoadingTable = false;

      this.matDialog.open(DialogAlertComponent, {
        hasBackdrop: true,
        disableClose: false,
        data: {
          title: 'Unable to fetch device data',
          text:
            error?.message || 'Unexpected error while recovering device data',
          variant: DialogAlertVariant.ERROR,
        } as DialogAlertParams,
      });
    }
  }

  public onTableRowsLimitChanged(newLimit: number): void {
    this.limit = newLimit;
    this.listTableData();
  }

  public onTablePageChanged(newPage: number): void {
    this.page = newPage;
    this.listTableData();
  }

  public get lastUpdate() {
    return formatDate(this.highlightData.date_time, 'MMM dd, yyyy HH:mm aa');
  }

  private formattedDateForTimezone(date) {
    const estDate = subMinutes(new Date(date), MINUTES_DIFFERENCE_UTC_TO_EST);
    const formattedDate = formatDate(estDate, 'MMM dd, yyyy HH:mm aa');

    return formattedDate;
  }
}
