import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, ElementRef, Input, ViewChild } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';

@Component({
  selector: 'arkiq-input',
  templateUrl: 'input.component.html',
  standalone: true,
  imports: [CommonModule, MatAutocompleteModule, FormsModule, ReactiveFormsModule],
})
export class InputComponent implements AfterViewInit {
  @ViewChild('inputRef1') private inputRef1!: ElementRef<HTMLInputElement>;
  @ViewChild('inputRef2') private inputRef2!: ElementRef<HTMLInputElement>;

  @Input() label = '';
  @Input() placeholder = '';
  @Input() type: HTMLInputElement['type'] = 'text';
  @Input() control = new FormControl();
  @Input() disabled = false;
  // @Input() invalid = false;
  @Input() icon?: `bi-${string}`;
  @Input() iconPosition?: 'left' | 'right' = 'right';
  @Input() variant: 'primary' | 'secondary' = 'primary';
  @Input() rounded = true;
  @Input() size: 'lg' | 'md' = 'lg';
  @Input() required = false;
  @Input() matAutocomplete?: any;
  @Input() autofocus = false;

  public classes = {
    base: 'focus-within:border-primary border border-[#EEEEEE] flex items-center gap-4',
    primary: '',
    secondary: 'bg-[#EEEEEE]',
    invalid: 'border-state-cancel focus-within:border-state-cancel',
  };

  public sizes = {
    lg: 'h-[50px] text-[20px] px-[29px] py-[14px]',
    md: 'h-[34px] text-sm px-3 py-2',
  }

  constructor() { }

  public ngAfterViewInit(): void {
    if (this.autofocus) {
      setTimeout(() => {
        this.inputRef1?.nativeElement.focus();
        this.inputRef2?.nativeElement.focus();
      }, 500);
    }
  }

  public get invalid(): boolean {
    return this.control.invalid && (this.control.touched || this.control.dirty);
  }
}
