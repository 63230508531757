<div *ngIf="isLoading" class="w-full h-full flex items-center justify-center">
  <arkiq-spinner />
</div>

<div *ngIf="!isLoading" class="w-full h-full flex justify-center">
  <div class="w-full bg-white h-full rounded-xl">
    <header class="flex items-center gap-2 py-5 px-6 border-b border-b-[#E0E0E0]">
      <arkiq-icon-button icon="bi-chevron-left" (click)="handleGoBack()" />

      <h2 class="m-0 font-bold text-base">
        {{ property ? 'Edit Property' : 'Add New Property' }}
      </h2>
    </header>

    <main class="w-full grid grid-cols-1 lg:grid-cols-2">
      <section class="w-full flex flex-col border-r border-r-[#E0E0E0] px-7 py-6">
        <header class="w-full flex items-center gap-8 pb-6 border-dashed border-b border-b-[#E0E0E0]">
          <app-select-property-photo-button
            [property]="property"
            (selectFile)="form.controls.photo.setValue($event)"
          />

          <div class="flex-1 flex flex-col gap-5">
            <fieldset class="flex gap-6">
              <arkiq-input
                class="flex-1"
                label="Name"
                [required]="true"
                placeholder="Name"
                size="md"
                [rounded]="false"
                [control]="form.controls.name"
              />

              <arkiq-select
                class="flex-1"
                label="Type"
                [required]="true"
                size="md"
                [rounded]="false"
                [control]="form.controls.type"
                [options]="propertyTypeOptions"
              />
            </fieldset>

            <app-select-property-organization
              [initialValue]="form.value.organization"
              (selectOrganization)="form.controls.organization.setValue($event)"
              (createOrganization)="handleCreateOrganization()"
              (resetOrganization)="form.controls.organization.setValue(null)"
            />
          </div>
        </header>

        <main class="w-full flex flex-col gap-6 pb-6 border-b border-dashed border-b-black/20">
          <h3 class="m-0 font-bold text-base">
            Address
          </h3>

          <arkiq-input
            type="text"
            [required]=true
            label="Street"
            placeholder="Street"
            [control]="form.controls.address_street"
            [rounded]="false"
            size="md"
          />

          <fieldset class="w-full grid grid-cols-1 lg:grid-cols-4 gap-4">
            <arkiq-input
              class="col-span-1 lg:col-span-2"
              type="text"
              [required]=true
              label="City"
              placeholder="City"
              [control]="form.controls.address_city"
              [rounded]="false"
              size="md"
            />

            <arkiq-input
              class="col-span-1"
              type="text"
              [required]=true
              label="State"
              placeholder="State"
              [control]="form.controls.address_state"
              [rounded]="false"
              size="md"
            />

            <arkiq-input
              class="col-span-1"
              type="text"
              [required]=true
              label="Zip Code"
              placeholder="Zip Code"
              [control]="form.controls.address_zip_code"
              [rounded]="false"
              size="md"
            />
          </fieldset>
        </main>

        <footer class="w-full flex flex-col gap-6 pt-6">
          <h3
            class="m-0 font-bold text-base required"
            [ngClass]="[isMasterValid ? '' : 'text-state-cancel']"
          >
            Property Master User
          </h3>

          <arkiq-select-organization-master
            title="Select Property Master User"
            placeholder="Select Property Master User"
            [initialValue]="form.value.master"
            addBtnLabel="Add property master"
            (selectUser)="form.controls.master.setValue($event)"
            (createUser)="form.controls.master.setValue($event)"
            (resetUser)="form.controls.master.setValue(null)"
            (masterFormTouchedAndValid)="isMastersTouchedAndValid($event)"
          />
        </footer>
      </section>

      <section class="w-full flex flex-col px-7 py-6">
        <h3
          class="m-0 font-bold text-base required"
          [ngClass]="[isManagerUserValid ? '' : 'text-state-cancel']"
        >
          Managing Users
        </h3>

        <div
          *ngIf="form.value.managingUsers && form.value.managingUsers.length === 0"
          class="w-full bg-[#F2F2F2] flex items-center justify-center py-8 rounded-xl mt-4 mb-5"
        >
          <span class="text-[#727272] text-base font-light">
            You didn't assign any Managing Users yet
          </span>
        </div>

        <div
          *ngIf="form.value.managingUsers && form.value.managingUsers.length > 0"
          class="w-full py-6"
        >
          <header class="rounded-t-[15px] bg-[#FCFCFD] border border-black/15 py-3 px-5 grid grid-cols-4 gap-2">
            <span class="text-sm text-center font-medium">Name</span>
            <span class="text-sm text-center font-medium">Information</span>
            <span class="text-sm text-center font-medium">Role</span>
            <span class="text-sm text-center font-medium">Actions</span>
          </header>

          <main class="rounded-b-[15px] border border-black/15 py-3 px-5">
            <div
              *ngFor="let managingUser of form.value.managingUsers; let index = index; let isLast = last"
              class="grid grid-cols-4 gap-2 py-3"
              [ngClass]="[isLast ? '' : 'border-b border-b-black/15']"
            >
              <section class="flex items-center gap-[10px] truncate">
                <img
                  class="size-[28px] rounded-full"
                  [src]="managingUser.user.photo"
                  [alt]="managingUser.user.firstName"
                />
                <span
                  class="font-medium text-sm truncate"
                  [matTooltip]="managingUser.user.firstName + ' ' + managingUser.user.lastName"
                >
                  {{ managingUser.user.firstName + ' ' + managingUser.user.lastName }}
                </span>
              </section>

              <section class="w-full truncate">
                <span
                  class="block text-center font-medium text-xs text-[#888888] truncate"
                  [matTooltip]="managingUser.user.email ?? ''"
                >
                  {{ managingUser.user.email }}
                </span>
                <span
                  class="block text-center font-medium text-xs text-[#888888] truncate"
                  [matTooltip]="managingUser.user.phoneNumber ?? ''"
                >
                  {{ managingUser.user.phoneNumber }}
                </span>
              </section>

              <section class="w-full text-center truncate">
                <span
                  class="font-medium text-xs text-[#888888] text-center truncate"
                  [matTooltip]="USER_ROLES[managingUser.role]"
                >
                  {{ USER_ROLES[managingUser.role] }}
                </span>
              </section>

              <section class="flex justify-center gap-3">
                <arkiq-icon-button
                  icon="bi-pencil-fill"
                  tooltipText="Edit"
                  (click)="(undefined)"
                />

                <arkiq-icon-button
                  icon="bi-trash-fill"
                  tooltipText="Remove"
                  (click)="handleRemoveManagingUser(managingUser, index)"
                />
              </section>
            </div>
          </main>
        </div>

        <div class="w-full py-6 border-t border-t-[#E0E0E0]">
        @if (!shouldAssignUsers) {
          <div class="max-w-[220px] ml-auto">
            <arkiq-button
              text="Assign Managing User(s)"
              variant="outline-primary"
              size="md"
              [rounded]="false"
              (click)="shouldAssignUsers = true"
            />
          </div>
        } @else {
          <app-select-property-managing-user
            (assignUser)="onAssignManagingUser($event)"
            (isManageUserValid)="isManagerTouchedAndValid($event)"
            (showAssignUsers)="shouldAssignUsers = false"

          />
        }
        </div>
      </section>
    </main>

    <footer class="w-full border-t border-t-[#E0E0E0] flex items-center justify-end gap-2 py-5 px-6">
      <arkiq-button
        text="Cancel"
        variant="tertiary"
        size="md"
        [rounded]="false"
        (click)="handleGoBack()"
      />

      <arkiq-button
        [text]="property ? 'Save' : 'Create'"
        size="md"
        [rounded]="false"
        [loading]="isSaving"
        (click)="handleSave()"
      />
    </footer>
  </div>
</div>
