import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { Router, RouterModule } from '@angular/router';
import { AuthService, FunctionsService } from '@arkiq-portals/sdk';
import {
    DialogAlertComponent,
    DialogAlertParams,
    DialogAlertVariant,
} from '@arkiq-portals/ui';
import { take } from 'rxjs';
import { ButtonComponent, InputComponent } from '../../../../components';
import { AuthStepsComponent } from '../../components';

@Component({
  selector: 'arkiq-forgot-password',
  templateUrl: 'forgot-password.component.html',
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    AuthStepsComponent,
    InputComponent,
    ButtonComponent,
    MatDialogModule,
  ],
})
export class AuthForgotPasswordComponent {
  public isLoading = false;

  public email = new FormControl('', [Validators.required, Validators.email]);

  constructor(
    private authService: AuthService,
    private functionsService: FunctionsService,
    private matDialog: MatDialog,
    private router: Router,
  ) {}

  public async handleForgotPassword() {
    if (!this.email.value) {
      return;
    }

    try {
      this.isLoading = true;

      // await this.authService.sendForgotPasswordEmail(this.email.value);
      await this.functionsService.sendResetPasswordEmail(this.email.value);
      this.router.navigateByUrl('/auth/forgot-password-email');
    } catch (error: any) {
      alert(error);
      const ref = this.matDialog.open(DialogAlertComponent, {
        hasBackdrop: true,
        disableClose: false,
        data: {
          variant: DialogAlertVariant.ERROR,
          title: 'Unable send reset password email',
          text: error?.error?.message || error?.message || error,
        } as DialogAlertParams,
      });
      ref
        .afterClosed()
        .pipe(take(1))
        .subscribe(data => {
          console.log(data);
        });
    } finally {
      this.isLoading = false;
    }
  }
}
