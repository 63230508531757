import {
  LastAlert,
  LastAlertsComponent,
} from './last-alerts/last-alerts.component';
import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import {
  AuthService,
  DevicesOverviewService,
  HomeDashboardService,
} from '@arkiq-portals/sdk';
import {
  ButtonComponent,
  HeaderComponent,
  SpinnerComponent,
} from '@arkiq-portals/ui';
import { HomeCardComponent } from './home-card/home-card.component';
import {
  RateAlert,
  RateAlertsComponent,
} from './rate-alerts/rate-alerts.component';
import { format } from 'date-fns';

export type CardInfo = {
  value: number;
  monthValue: number;
};

@Component({
  selector: 'app-home',
  templateUrl: 'home.component.html',
  standalone: true,
  imports: [
    CommonModule,
    ButtonComponent,
    HomeCardComponent,
    LastAlertsComponent,
    RateAlertsComponent,
    HeaderComponent,
    SpinnerComponent,
  ],
})
export class HomeComponent implements OnInit {
  public isLoading = false;
  public deviceInfo: CardInfo = {
    value: 0,
    monthValue: 0,
  };
  public organizationInfo: CardInfo = {
    value: 0,
    monthValue: 0,
  };
  public propertyInfo: CardInfo = {
    value: 0,
    monthValue: 0,
  };
  public alertInfo: CardInfo = {
    value: 0,
    monthValue: 0,
  };

  public alerts: LastAlert[] = [];

  public rateAlerts: RateAlert[] = [];
  constructor(
    private authService: AuthService,
    private homeService: HomeDashboardService,
    private devicesOverviewService: DevicesOverviewService,
  ) {}

  ngOnInit(): void {
    this.homeData();
  }

  public async homeData() {
    try {
      this.isLoading = true;
      const [counters, lastAlerts, rateAlert] = await Promise.all([
        this.homeService.totalCounter(),
        this.homeService.alerts(),
        this.homeService.rateAlerts(),
      ]);
      const alertsArray: LastAlert[] = [];
      lastAlerts.forEach(alert => {
        const date = new Date(alert.time);
        if (isNaN(date.getDate())) {
          return;
        }
        const time = format(date, 'HH:mm');
        const address = `${
          alert.device.properties.address_street ?? 'street'
        }, ${alert.device.properties.address_city ?? 'city'}, ${
          alert.device.properties.address_state ?? 'state'
        }`;
        const alertType = alert.type.replace('detected', '');
        alertsArray.push({
          time,
          date,
          address,
          alertType,
          fixture: alert.device.properties?.fixture?.name ?? 'Fixture',
        });
      });

      const rateArray: RateAlert[] = [];
      rateAlert.forEach(property =>
        rateArray.push({
          name: property.name,
          photo: property.photo,
          total: property.alerts.total,
          tamper: property.alerts.tamper,
          leak: property.alerts.leak,
          temperature: property.alerts.temperature,
          humidity: property.alerts.humidity,
        }),
      );

      this.alerts = [...alertsArray];
      this.rateAlerts = [...rateArray];

      const deviceInfo: CardInfo = {
        value: counters.devices.aggregate.count,
        monthValue: 0,
      };
      const organizationInfo: CardInfo = {
        value: counters.organizations.aggregate.count,
        monthValue: counters.lastMonthOrganizations.aggregate.count,
      };
      const propertyInfo: CardInfo = {
        value: counters.properties.aggregate.count,
        monthValue: counters.lastMonthProperties.aggregate.count,
      };
      const alertInfo: CardInfo = {
        value: counters.alerts.aggregate.count,
        monthValue: 0,
      };

      this.deviceInfo = deviceInfo;
      this.alertInfo = alertInfo;
      this.organizationInfo = organizationInfo;
      this.propertyInfo = propertyInfo;
    } catch (error: any) {
      console.log(error);
    } finally {
      this.isLoading = false;
    }
  }
}
