// In the MVP we'll only have 'organization master' and 'customer' roles

export enum UserRole {
  ADMIN = 'ADMIN',
  OTHER = 'OTHER',
  RESIDENT = 'RESIDENT',
  BUILDING_MANAGEMENT = 'BUILDING_MANAGEMENT',
  PROPERTY_MASTER = 'PROPERTY_MASTER',

  ORGANIZATION_MASTER = 'ORGANIZATION_MASTER',
  CUSTOMER = 'CUSTOMER',
}

export enum UserRoleValues {
  ADMIN = 600,
  BUILDING_MANAGEMENT = 500,
  ORGANIZATION_MASTER = 400,
  PROPERTY_MASTER = 300,
  CUSTOMER = 200,
  RESIDENT = 100,
  OTHER = 0,
}

export enum AllowedCreationUserRoles {
  ADMIN = 'ADMIN',
  ORGANIZATION_MASTER = 'ORGANIZATION_MASTER',
  CUSTOMER = 'CUSTOMER',
}

export enum UserRoleLabel {
  ADMIN = 'Admin',
  OTHER = 'Other',
  RESIDENT = 'Resident',
  BUILDING_MANAGEMENT = 'Building Management',
  PROPERTY_MASTER = 'Property Master',

  ORGANIZATION_MASTER = 'Organization Master',
  CUSTOMER = 'Customer',
}
