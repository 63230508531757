import { CommonModule } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { ButtonComponent } from '../../components';

export type DialogConfirmActionParams = {
  title: string;
  text: string;
};

@Component({
  selector: 'arkiq-dialog-confirm-action',
  templateUrl: 'dialog-confirm-action.component.html',
  standalone: true,
  imports: [CommonModule, MatDialogModule, ButtonComponent],
})
export class DialogConfirmActionComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogConfirmActionParams,
    public dialogRef: MatDialogRef<DialogConfirmActionComponent>,
  ) {}
}
