import {
  DeviceCategory,
  DeviceOverview,
  DevicesOverviewService,
  DeviceStatus,
  DeviceStatusLabel,
  DeviceType,
  Inventories,
  ListDevicesOverviewParams,
  Organization,
  OrganizationsService,
  PropertiesService,
  Property,
  SortOrder,
  UserRole,
  UsersService,
  UserType,
} from '@arkiq-portals/sdk';

import { CommonModule, Location } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router, RouterModule } from '@angular/router';
import {
  ButtonComponent,
  DialogAlertComponent,
  DialogAlertParams,
  DialogAlertVariant,
  HasRoleDirective,
  InputComponent,
  Tab,
  TableColumn,
  TableColumnHeaderFilterType,
  TableColumnHeaderOptions,
  TableColumnType,
  TableComponent,
  TableRowClickedEvent,
  TableSortChangeEvent,
  TabsComponent,
} from '@arkiq-portals/ui';
import { format } from 'date-fns';
import { Subscription, take } from 'rxjs';
import { AddDeviceModalComponent } from './components/add-device-modal/add-device-modal.component';
import { DeleteDeviceModalComponent } from './components/delete-device-modal/delete-device-modal.component';
import { ReturnDeviceModalComponent } from './components/return-device-modal/return-device-modal.component';

const ALLOWED_USER_ROLES = [UserRole.ADMIN];

@Component({
  selector: 'app-devices',
  templateUrl: 'devices-list.component.html',
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    ButtonComponent,
    InputComponent,
    TableComponent,
    TabsComponent,
    HasRoleDirective,
    AddDeviceModalComponent,
  ],
  styles: [':host {display:flex; flex-direction: column; flex:1}'],
})
export class DevicesListComponent implements OnInit, OnDestroy {
  public isLoading = false;

  public devices: DeviceOverview[] = [];
  public selectedDevices: DeviceOverview[] = [];
  public totalDevices = 0;
  public totalPages = 0;

  public search = '';

  public limit = 10;
  public page = 1;

  public INVENTORIES = Inventories;
  public USER_ROLES = UserRole;
  public isInInventoryTab = this.router.url.includes('inventory');
  public selectedInventory = this.INVENTORIES.CUSTOMERS;

  public organizationsOptions: TableColumnHeaderOptions[] = [];
  public propertiesOptions: TableColumnHeaderOptions[] = [];

  public deviceTypeOptions: TableColumnHeaderOptions[] = Object.keys(
    DeviceType,
  ).map(type => ({
    label: DeviceType[type],
    value: DeviceType[type],
    isSelected: false,
  }));

  public statusOptions: TableColumnHeaderOptions[] = Object.keys(
    DeviceStatus,
  ).map(type => ({
    label: DeviceStatusLabel[type],
    value: type.toLowerCase(),
    isSelected: false,
  }));
  public DEFAULT_MODAL_SETTINGS = {
    hasBackdrop: true,
    disableClose: false,
    width: '90vw',
    maxWidth: '700px',
    maxHeight: '90dvh',
  };
  public filterForm = new FormGroup({
    search: new FormControl(''),
    organizationId: new FormControl<string[]>([]),
    propertyId: new FormControl<string[]>([]),
    orderBy: new FormControl('organization'),
    order: new FormControl<SortOrder>('desc_nulls_last'),
    type: new FormControl<string[]>([]),
    status: new FormControl<string[]>([]),
    battery: new FormControl<string[]>([]),
  });
  public columns: TableColumn<DeviceOverview>[] = [];
  public tabs: Tab[] = [
    {
      route: '/devices',
      text: 'All',
      allowedRoles: ALLOWED_USER_ROLES,
    },
    {
      route: '/devices/installed',
      text: 'Installed',
    },
    {
      text: 'Inventory',
      route: '/devices/inventory',
      allowedRoles: ALLOWED_USER_ROLES,
    },
  ];
  private subscriptions = new Subscription();

  constructor(
    private router: Router,
    private location: Location,
    private devicesOverviewService: DevicesOverviewService,
    private organizationsService: OrganizationsService,
    private propertiesService: PropertiesService,
    private dialog: MatDialog,
    private userService: UsersService,
  ) {}

  public get isLoggedUserArkIQAdmin() {
    return this.userService.loggedUser?.type === UserType.ARKIQ;
  }

  public ngOnInit() {
    if (
      !this.router.url.includes('installed') &&
      !this.userService.validateUserPermission(ALLOWED_USER_ROLES)
    ) {
      this.router.navigateByUrl('/devices/installed');
      return;
    }

    const state = this.location.getState();

    if (state && state['tab']) {
      switch (state['tab']) {
        case 'installed':
          this.router.navigateByUrl(
            '/devices/installed',
            { state: { ...state, tab: '' } },
          );
          break;

        case 'inventory':
          this.router.navigateByUrl(
            '/devices/inventory',
            { state: { ...state, tab: '' } },
          );
          break;
      }
    }

    if (state && state['organizationId']) {
      this.filterForm.patchValue(
        { organizationId: [String(state['organizationId'])] },
      );
    }

    if (state && state['propertyId']) {
      this.filterForm.patchValue(
        { propertyId: [String(state['propertyId'])] },
      );
    }

    if (state && state['status']) {
      this.filterForm.patchValue(
        { status: [String(state['status'])] },
      );
    }

    if (state && state['type']) {
      this.filterForm.patchValue(
        { type: [String(state['type'])] },
      );
    }

    this.listDevices(false);

    this.subscriptions.add(
      this.filterForm.valueChanges.subscribe(() => {
        this.listDevices();
      }),
    );
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  public async listDevices(loadJustDevices = true) {
    try {
      this.isLoading = true;

      const isPageInventory = this.router.url.includes('inventory');
      const isPageInstalledDevices = this.router.url.includes('installed');

      let category: DeviceCategory;

      if (isPageInventory) {
        category = DeviceCategory.INVENTORY;
      } else if (isPageInstalledDevices) {
        category = DeviceCategory.INSTALLED;
      } else {
        category = DeviceCategory.ALL;
      }

      const searchParams: ListDevicesOverviewParams = {
        limit: this.limit,
        page: this.page,
        category,
        inventory: this.selectedInventory,
        order: this.filterForm.value.order || 'asc_nulls_first',
        orderBy: this.filterForm.value.orderBy || 'organization_id',
        organizationId: this.filterForm.value.organizationId
          ? this.filterForm.value.organizationId.map(Number)
          : [],
        propertyId: this.filterForm.value.propertyId
          ? this.filterForm.value.propertyId.map(Number)
          : [],
        type: this.filterForm.value.type || null,
        status: this.filterForm.value.status || null,
        battery: this.filterForm.value.battery || null,
        search: this.filterForm.value.search || '',
      };

      const devices = await this.devicesOverviewService.list(searchParams);

      this.devices = devices;

      await this.devicesOverviewService
        .count(searchParams)
        .then(totalDevices => {
          this.totalDevices = totalDevices;

          const totalPages = Math.ceil(this.totalDevices / this.limit);
          this.totalPages = totalPages;
        });

      if (!loadJustDevices) {
        const [organizations, { properties }] = await Promise.all([
          this.organizationsService.list({ limit: 999999 }),
          this.propertiesService.list({ limit: 9999999 }),
        ]);
        this.buildOrganizationOptions(organizations.organizations);
        this.buildPropertiesOptions(properties);
        this.buildTableColumns();
      }

      const totalPages = Math.ceil(this.totalDevices / this.limit);
      this.totalPages = totalPages;
    } catch (error) {
      console.error(error);
      
      this.dialog.open(
        DialogAlertComponent,
        {
          hasBackdrop: true,
          disableClose: false,
          data: {
            variant: DialogAlertVariant.ERROR,
            title: 'Unable to list devices',
            text: error,
          } as DialogAlertParams,
        }
      );
    } finally {
      this.isLoading = false;
    }
  }

  public onSearch(search: string) {
    this.filterForm.patchValue({ search });
  }

  public onDeviceClicked(event: TableRowClickedEvent<DeviceOverview>) {
    const device = event.data;
    this.router.navigateByUrl(`/devices/${device.id}`, {
      state: {
        device,
      },
    });
  }

  public onTableRowsLimitChanged(newLimit: number): void {
    this.limit = newLimit;
    this.listDevices();
  }

  public onTablePageChanged(newPage: number): void {
    this.page = newPage;
    this.listDevices();
  }

  public onTableSortChanged(event: TableSortChangeEvent) {
    switch (event.columnId) {
      case 'type':
        this.filterForm.patchValue({
          orderBy: 'type',
          order: event.order,
        });
        break;

      case 'organization':
        this.filterForm.patchValue({
          orderBy: 'organization_id',
          order: event.order,
        });
        break;

      case 'property':
        this.filterForm.patchValue({
          orderBy: 'organization',
          order: event.order,
        });
        break;

      case 'last_alert':
        this.filterForm.patchValue({
          orderBy: 'last_alert',
          order: event.order,
        });
        break;

      case 'alert_7_days':
        this.filterForm.patchValue({
          orderBy: 'alerts_in_last_7_days',
          order: event.order,
        });
        break;

      case 'status':
        this.filterForm.patchValue({
          orderBy: 'status',
          order: event.order,
        });
        break;

      case 'battery': // TODO
        break;
    }
  }

  public onTableRowsSelected(devices: DeviceOverview[]) {
    this.selectedDevices = devices;
  }

  public handleAddDevice() {
    this.router.navigateByUrl('/devices/create');
    // this.dialog.open(AddDeviceModalComponent, {
    //   hasBackdrop: true,
    //   disableClose: false,
    // }); TODO -> Enable this when we have .CSV file support
  }

  public handleDeleteDevices() {
    this.dialog.open(DeleteDeviceModalComponent, {
      ...this.DEFAULT_MODAL_SETTINGS,
      data: {
        selectedDevices: this.selectedDevices,
      },
    });
  }

  public handleTransferDevices() {
    this.router.navigateByUrl('/devices/transfer', {
      state: { devices: this.selectedDevices },
    });
  }

  public handleReturnDevices() {
    const ref = this.dialog.open(ReturnDeviceModalComponent, {
      ...this.DEFAULT_MODAL_SETTINGS,
      data: {
        selectedDevices: this.selectedDevices,
      },
    });

    ref
      .afterClosed()
      .pipe(take(1))
      .subscribe(data => {
        if (data && data.reload) {
          this.listDevices();
        }
      });
  }

  // TODO -> OPEN A MODAL FOR UNINSTALL
  public handleUninstallDevices() {
    console.log('handleUninstallDevices');
  }

  public handleInventoryChange(inventory: Inventories) {
    this.selectedInventory = inventory;
    this.listDevices();
  }

  private buildOrganizationOptions(organizations: Organization[]) {
    const options: TableColumnHeaderOptions[] = organizations.map(
      organization => ({
        label: organization.name,
        value: String(organization.id),
        isSelected: false,
      }),
    );

    this.organizationsOptions = options;
  }

  private buildPropertiesOptions(properties: Property[]) {
    const options: TableColumnHeaderOptions[] = properties.map(property => ({
      label: property.name,
      value: String(property.id),
      isSelected: false,
    }));

    this.propertiesOptions = options;
  }

  private buildTableColumns(): void {
    this.columns = [
      {
        type: TableColumnType.TEXT,
        label: 'ID',
        valueKey: 'id',
        id: 'id',
        headerClasses: 'text-left',
        valueClasses: 'text-left text-black',
        columnLink: device => ({ url: `/devices/${device.id}`, state: {} }),
      },
      {
        type: TableColumnType.TEXT,
        label: 'Type',
        id: 'type',
        sortable: true,
        valueFn: device => device.type || '-',
        headerClasses: 'text-center justify-center',
        valueClasses: 'text-center',
        filter: {
          type: TableColumnHeaderFilterType.MULTIPLE_SELECT,
          options: this.deviceTypeOptions,
          control: this.filterForm.controls.type,
        },
      },
      {
        type: TableColumnType.TEXT,
        label: 'Organization',
        id: 'organization',
        sortable: true,
        valueFn: device => device.organization,
        headerClasses: 'text-center justify-center',
        valueClasses: 'text-center text-black',
        filter: {
          type: TableColumnHeaderFilterType.MULTIPLE_SELECT,
          options: this.organizationsOptions,
          control: this.filterForm.controls.organizationId,
        },
        columnLink: device => ({ url: `/organizations/${device.organization_id}`, state: {} }),
      },
      {
        type: TableColumnType.TEXT,
        label: 'Property',
        id: 'property',
        sortable: true,
        valueFn: device => device.property,
        headerClasses: 'text-center justify-center',
        valueClasses: 'text-center text-black',
        filter: {
          type: TableColumnHeaderFilterType.MULTIPLE_SELECT,
          options: this.propertiesOptions,
          control: this.filterForm.controls.propertyId,
        },
        columnLink: device => ({ url: `/properties/${device.property_id}`, state: {} }),
      },
      {
        type: TableColumnType.TEXT,
        label: 'Last Alert',
        id: 'last_alert',
        sortable: true,
        valueFn: device =>
          device.last_alert
            ? format(device.last_alert, 'MMM dd, yyyy hh:mm a')
            : '-',
        headerClasses: 'text-center justify-center',
        valueClasses: 'text-center',
      },
      {
        type: TableColumnType.TEXT,
        label: 'Alerts Weekly',
        id: 'alert_7_days',
        sortable: true,
        valueFn: device => `${device.alerts_in_last_7_days} Alerts`,
        headerClasses: 'text-center justify-center',
        headerPicture: 'assets/warning.svg',
        valueClasses: 'text-center justify-center',
        valueClassesFn: device => device.alerts_in_last_7_days > 0 ? 'text-black' : '',
        columnLink: device => ({
          url: '/alerts',
          state: {
            organizationId: device.organization_id,
            propertyId: device.property_id,
          },
        }),
      },
      {
        type: TableColumnType.TEXT,
        label: 'Status',
        id: 'status',
        sortable: true,
        valueFn: device => device.status.toUpperCase(),
        valueClassesFn: device => device.status === 'online'
          ? 'text-blue-500'
          : 'text-red-500',
        headerClasses: 'text-center justify-center',
        valueClasses: 'text-center',
        filter: {
          type: TableColumnHeaderFilterType.MULTIPLE_SELECT,
          options: this.statusOptions,
          control: this.filterForm.controls.status,
        },
      },
    ];
  }


  public get selectionMode(){
    const isPageInventory = this.router.url.includes('inventory');
    const isPageInstalledDevices = this.router.url.includes('installed');

    return !isPageInventory && !isPageInstalledDevices ? "NONE" : "MULTIPLE"
  }
}
