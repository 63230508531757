import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Property, PropertyTypeLabel } from '@arkiq-portals/sdk';
import { IconButtonComponent } from '@arkiq-portals/ui';
import { SavePropertyEvent } from '../add-organization-property-form/add-organization-property-form.component';
import { MatTooltipModule } from '@angular/material/tooltip';

@Component({
  selector: 'app-organization-property-card',
  templateUrl: './organization-property-card.component.html',
  standalone: true,
  imports: [CommonModule, IconButtonComponent,
    MatTooltipModule,
  ],
})
export class OrganizationPropertyCardComponent {
  @Input({ required: true }) properties!: SavePropertyEvent[];

  @Output() edit = new EventEmitter();
  @Output() delete = new EventEmitter();

  public PROPERTY_TYPE_LABEL = PropertyTypeLabel;


  public propertyPhoto(property: Partial<Property>) {
    return property.photo ? property.photo : `https://ui-avatars.com/api/?background=random&name=${property.name}`
  }

  public propertyAddress(property: Partial<Property>) {
    return `${property.address_street}, ${property.address_city}`
  }
}
