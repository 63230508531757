import { CommonModule } from '@angular/common';
import {
    Component,
    ElementRef,
    Input,
    OnDestroy,
    OnInit,
    ViewChild
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import {
    DeviceOverview,
    DevicesToiletSensorService,
    DeviceToiletSensorView,
    SortOrder,
} from '@arkiq-portals/sdk';
import {
    DialogAlertComponent,
    DialogAlertParams,
    DialogAlertVariant,
    SpinnerComponent,
    TableColumn,
    TableColumnHeaderFilterType,
    TableColumnType,
    TableComponent,
    TableSortChangeEvent,
    TextCardComponent,
} from '@arkiq-portals/ui';
import { MINUTES_DIFFERENCE_UTC_TO_EST } from 'apps/admin/src/utils';
import Chart from 'chart.js/auto';
import { differenceInSeconds, formatDate, subMinutes } from 'date-fns';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-device-toilet-sensor-grid',
  templateUrl: './device-toilet-sensor-grid.component.html',
  standalone: true,
  imports: [
    CommonModule,
    MatDialogModule,
    SpinnerComponent,
    TextCardComponent,
    TableComponent,
  ],
})
export class DeviceToiletSensorGridComponent implements OnInit, OnDestroy {
  @Input({ required: true }) device!: DeviceOverview;

  @ViewChild('chartRef', { static: true }) chartRef!: ElementRef;

  public isLoading = false;
  public isLoadingTable = false;

  public tableOrder: SortOrder = 'asc_nulls_first';
  public tableOrderBy = 'date_time';

  public dateRangeControl = new FormGroup({
    startDate: new FormControl(''),
    endDate: new FormControl(''),
  });

  public tableColumns: TableColumn<DeviceToiletSensorView>[] = [
    {
      id: 'date_time',
      type: TableColumnType.TEXT,
      label: 'Date/Time',
      headerClasses: 'text-center justify-center',
      valueFn: row => this.formattedDateForTimezone(row.date_time),
      valueClasses: 'text-center',
      sortable: true,
      filter: {
        type: TableColumnHeaderFilterType.DATE_RANGE,
        options: [],
        control: this.dateRangeControl,
      },
    },
    {
      id: 'flush_status',
      type: TableColumnType.TEXT,
      label: 'Flush Status',
      headerClasses: 'text-center justify-center',
      valueFn: row => row.flush_status.toUpperCase(),
      valueClasses: 'text-center',
      sortable: true,
    },
    {
      id: 'volume',
      type: TableColumnType.TEXT,
      label: 'Volume',
      headerClasses: 'text-center justify-center',
      valueFn: row => row.volume?.toString() ?? '-',
      valueClasses: 'text-center',
      sortable: true,
    },
  ];

  public paginatedData: DeviceToiletSensorView[] = [];
  public totalTablePages = 1;

  public totalData: DeviceToiletSensorView[] = [];

  public gridData = {
    flushes: 0,
    totalVolume: 0,
    averageGallonsPerFlush: 0,
    averageDuration: 0,
    totalDuration: 0,
  };

  public limit = 6;
  public page = 1;

  public subscription = new Subscription();

  constructor(
    private matDialog: MatDialog,
    private devicesToiletSensorViewService: DevicesToiletSensorService,
  ) {}

  public async ngOnInit() {
    await this.getToiletSensorData();

    this.subscription.add(
      this.dateRangeControl.valueChanges.subscribe(dateRange => {
        this.getToiletSensorData(false);
      }),
    );
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public async getToiletSensorData(buildChart = true) {
    try {
      this.isLoading = true;

      const [tableResponse, chartResponse] = await Promise.all([
        this.devicesToiletSensorViewService.listByDeviceId(this.device.id, {
          limit: this.limit,
          page: this.page,
          startDate: this.dateRangeControl.value.startDate ?? '',
          endDate: this.dateRangeControl.value.endDate ?? '',
        }),
        this.devicesToiletSensorViewService.listByDeviceId(this.device.id, {
          limit: 999999,
        }),
      ]);

      this.paginatedData = tableResponse.devices;
      this.totalTablePages = Math.ceil(tableResponse.totalItems / this.limit);

      this.totalData = chartResponse.devices;

      if (buildChart) {
        this.buildChart();
      }
      
      this.getGridData();

      this.isLoading = false;
    } catch (error: any) {
      console.error(error);

      this.isLoading = false;

      this.matDialog.open(DialogAlertComponent, {
        hasBackdrop: true,
        disableClose: false,
        data: {
          title: 'Unable to fetch device data',
          text:
            error?.message || 'Unexpected error while recovering device data',
          variant: DialogAlertVariant.ERROR,
        } as DialogAlertParams,
      });
    }
  }

  public async listTableData() {
    try {
      this.isLoadingTable = true;

      const tableResponse =
        await this.devicesToiletSensorViewService.listByDeviceId(
          this.device.id,
          {
            limit: this.limit,
            page: this.page,
            order: this.tableOrder,
            orderBy: this.tableOrderBy,
          },
        );

      this.paginatedData = tableResponse.devices;
      this.totalTablePages = Math.ceil(tableResponse.totalItems / this.limit);

      this.isLoadingTable = false;
    } catch (error: any) {
      console.error(error);

      this.isLoadingTable = false;

      this.matDialog.open(DialogAlertComponent, {
        hasBackdrop: true,
        disableClose: false,
        data: {
          title: 'Unable to fetch device data',
          text:
            error?.message || 'Unexpected error while recovering device data',
          variant: DialogAlertVariant.ERROR,
        } as DialogAlertParams,
      });
    }
  }

  public getGridData() {
    this.gridData = this.totalData.reduce(
      (accumulator, currentItem, currentIndex) => {
        if (currentItem.flush_status === 'flush stopped') {
          accumulator.flushes += 1;
        }

        accumulator.totalVolume += currentItem.volume
          ? Number(currentItem.volume.toFixed(2))
          : 0;

        if (currentIndex === this.totalData.length - 1) {
          accumulator.averageGallonsPerFlush = Number(
            (accumulator.totalVolume / accumulator.flushes).toFixed(1),
          );

          accumulator.averageDuration = Number(
            (accumulator.totalDuration / accumulator.flushes).toFixed(1),
          );
        }

        if (currentIndex !== 0 && currentIndex % 2 !== 0) {
          const startDateTime = currentItem.date_time;
          const stopDateTime = this.totalData[currentIndex - 1].date_time;

          accumulator.totalDuration += differenceInSeconds(
            stopDateTime,
            startDateTime,
          );
        }

        return accumulator;
      },
      {
        flushes: 0,
        totalVolume: 0,
        averageGallonsPerFlush: 0,
        averageDuration: 0,
        totalDuration: 0,
      },
    );
  }
  private formattedDateForTimezone(date) {
    const estDate = subMinutes(new Date(date), MINUTES_DIFFERENCE_UTC_TO_EST);
    const formattedDate = formatDate(estDate, 'MMM dd, yyyy HH:mm aa');

    return formattedDate;
  }

  public onTablePageChanged(newPage: number): void {
    this.page = newPage;
    this.listTableData();
  }

  public onSortOrderChange(event: TableSortChangeEvent) {
    this.tableOrder = event.order;
    this.tableOrderBy = event.columnId;
    this.listTableData();
  }

  public buildChart() {
    new Chart(this.chartRef.nativeElement, {
      type: 'bar',
      data: {
        labels: this.paginatedData
          .filter(item => item.volume)
          .map((item, index) => formatDate(item.date_time, 'MMM dd, yyyy')),
        datasets: [
          {
            data: this.paginatedData
              .filter(item => item.volume)
              .map(item => item.volume ?? 0),
            backgroundColor: '#0192CE',
            borderRadius: 5,
          },
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        normalized: true,
        interaction: {
          mode: 'index',
          intersect: false,
        },
        scales: {
          y: {
            beginAtZero: true,
          },
          x: {
            grid: {
              display: false,
            },
          },
        },
        plugins: {
          legend: { display: false },
        },
      },
    });
  }
}
