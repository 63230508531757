<div class="flex-1 w-full h-full flex flex-col">
  @if(showHeader()){
    <header
      class="w-full flex gap-4 items-center justify-between bg-white py-[15px] px-[22px] rounded-t-xl"
      [ngClass]="[customHeaderClasses]"
    >
      @if (!hideTitle) {
      <span class="font-bold text-base">
        {{
          isLoading
            ? 'Loading...'
            : 'Total ' + (totalItems || data.length) + ' ' + label
        }}
      </span>
      }

      <arkiq-input
        *ngIf="enableSearch"
        class="max-w-[577px]"
        icon="bi-search"
        iconPosition="right"
        [placeholder]="searchInputLabel"
        [rounded]="false"
        size="md"
        [control]="searchControl"
      />

      <section class="flex-1 flex gap-4 items-center justify-end">
        <arkiq-button
          *ngIf="enableDownloadCsv"
          text="Download.csv"
          icon="bi-download"
          variant="outline"
          size="md"
          [rounded]="false"
          (click)="handleExportAsCsv()"
        />
        @if(enableClearAllFilters) {
          @if (clearFiltersType === CLEAR_ALL_FILTER_BUTTON_TYPE.BUTTON) {
            <arkiq-button
              text="Clear All Filters"
              variant="outline-primary"
              size="md"
              [rounded]="false"
              (click)="handleClearAllFilters()"
              class="text-nowrap"
            />
          } @else {
            <button
              class="text-primary underline px-2 py-3 font-semibold hover:opacity-75 transition-opacity text-sm"
              (click)="handleClearAllFilters()"

            >
              Clear All Filters
            </button>

          }
        } @for (button of buttons; track $index) { @if
        (validateUserPermission(button.allowedRoles)) { @if (button.type ===
        'default-button') {
        <arkiq-button
          [text]="button.text ?? ''"
          [icon]="button.icon ?? 'bi-cog'"
          [variant]="button.variant ?? 'primary'"
          size="md"
          [rounded]="false"
          (click)="button.handler()"
        />
        } @else {
        <arkiq-icon-button
          [icon]="button.icon ?? 'bi-cog'"
          [tooltipText]="button.tooltip ?? ''"
          (click)="button.handler()"
        />
        } } }
      </section>
    </header>
  }
  <main class="flex-1 bg-white overflow-x-auto custom-scrollbar" [ngClass]="[maxHeightPageClasses , !showHeader() ? 'rounded-t-xl' : '' ]">
    <div
      *ngIf="isLoading"
      class="w-full h-full flex items-center justify-center"
    >
      Loading...
    </div>

    <div
      *ngIf="!isLoading && !data.length"
      class="w-full h-full flex items-center justify-center opacity-30 pb-4"
    >
      {{ 'No ' + label.toLowerCase() + ' to list' }}
    </div>

    <table
      class="w-full max-w-full overflow-x-auto table-auto"
      *ngIf="!isLoading && data.length"
    >
      <thead class="bg-white sticky -top-[16px] z-[999]">
        @if (selectionMode !== 'NONE' &&
        validateUserPermission(rolesAllowedToSelect)) {
        <th
          class="text-sm font-semibold text-[#252525] w-[32px]"
          [ngClass]="{'border-t border-t-[#E0E0E0]': showHeader()}"
        >
          <div
            class="h-full flex items-center justify-center p-4"
            for="checkbox"
          >
            <input
              type="checkbox"
              [checked]="selectedRows.length === data.length"
              id="checkbox"
              (change)="handleSelectAllRows()"
            />
          </div>
        </th>
        } @for (column of columns; track $index) { @if
        (validateUserPermission(column.allowedRoles)) {
        <th
          [ngClass]="[
            'text-sm font-semibold text-[#252525] py-4 px-4 border-t border-t-[#E0E0E0]',
            column.headerClasses ?? ''
          ]"
        >
          <div
            [ngClass]="[
              !!column.filter ? 'flex flex-col' : '',
              column.headerClasses ?? ''
            ]"
          >
            <div
              class="w-full flex items-center relative"
              [ngClass]="[column.headerClasses ?? '']"
            >
              @if (column.headerPicture) {
                <img
                  [src]="column.headerPicture"
                  [alt]="column.label"
                  class="!size-[14px] rounded-none mr-2"
                />
              }

              <span class="relative">{{ column.label }}

              @if (column.filter || column.sortable) {
                <arkiq-table-column-header-button
                  [isSortable]="!!column.sortable"
                  [filter]="column.filter"
                  (closeMenu)="onColumnHeaderMenuClosed(column, $event)"
                  class="absolute -translate-y-1/2 top-[50%]"
                />
              }
              </span>
            </div>

            @if (column.filter && getSelectedFiltersCount(column) > 1) {
            <span
              class="text-[#9D9D9D] text-[10px] font-medium whitespace-nowrap"
            >
              ({{ getSelectedFiltersCount(column) }} shown)
            </span>
            }
          </div>
        </th>
        } }
      </thead>

      <tbody class="w-full max-w-full overflow-x-auto">
        <tr
          *ngFor="let item of data; let rowIndex = index"
          class="bg-white hover:brightness-95 transition-all cursor-default text-[#666666]"
          (click)="handleRowClicked(rowIndex, item)"
        >
          @if (selectionMode !== 'NONE' &&
          validateUserPermission(rolesAllowedToSelect)) {
          <td class="w-fit max-w-full border-t border-t-[#E0E0E0]">
            <label
              class="h-full flex items-center justify-center p-4"
              [for]="rowIndex"
              (click)="onLabelCheckboxClicked($event)"
            >
              <input
                type="checkbox"
                [name]="rowIndex"
                [id]="rowIndex"
                [checked]="isRowSelected(item)"
                (change)="handleRowSelected(item)"
              />
            </label>
          </td>
          } @for (column of columns; track $index) { @if
          (validateUserPermission(column.allowedRoles)) {
          <td
            [ngClass]="[
              'px-4 py-[10px] text-xs font-medium w-fit max-w-full border-t border-t-[#E0E0E0]',
              column.valueClasses ?? '',
              column.valueClassesFn ? column.valueClassesFn(item) : ''
            ]"
            [ngSwitch]="column.type"
            (click)="$event.stopPropagation(); handleGoToColumnLink(column, item)"
          >
            <ng-container *ngSwitchDefault>
              <ng-container
                *ngTemplateOutlet="
                          defaultCellTemplate;
                          context: {
                            column: column,
                            item: item,
                          }
                        "
              ></ng-container>
            </ng-container>

            <ng-container *ngSwitchCase="'PICTURE_AND_ALT'">
              <ng-container
                *ngTemplateOutlet="
                  pictureAndAltCellTemplate;
                  context: column?.pictureAndAlt(item)
                "
              ></ng-container>
            </ng-container>

            <ng-container *ngSwitchCase="'PICTURE_AND_TEXT'">
              <ng-container
                *ngTemplateOutlet="
                  pictureAndTextCellTemplate;
                  context: column?.pictureAndTextValueFn(item)
                "
              ></ng-container>
            </ng-container>

            <ng-container *ngSwitchCase="'BADGE'">
              <ng-container
                *ngTemplateOutlet="
                  badgeCellTemplate;
                  context: {
                    column: column,
                    item: item,
                    badge: column?.badge(item)
                  }
                "
              ></ng-container>
            </ng-container>

            <ng-container *ngSwitchCase="'SHOW_MORE'">
              <ng-container
                *ngTemplateOutlet="
                  showMoreCellTemplate;
                  context: {
                    object: column?.showMoreObject(item)
                  }
                "
              ></ng-container>
            </ng-container>

            <ng-container *ngSwitchCase="'ACTIONS'">
              <ng-container
                *ngTemplateOutlet="
                  actionsCellTemplate;
                  context: { buttons: column.actionButtons, item: item }
                "
              ></ng-container>
            </ng-container>
          </td>
          } }
        </tr>
      </tbody>
    </table>
  </main>

  <footer
    class="rounded-b-xl bg-white flex items-center justify-between py-4 px-6 border-t border-t-[#E0E0E0] flex-col md:flex-row gap-4"
  >
    @if (!hideItemsPerPageToggle) {
    <section class="flex items-center gap-[10px]">
      <span class="text-[#AAAAAA] font-medium text-sm">Results Per Page</span>

      <select
        class="h-[34px] min-w-[54px] border border-[#9D9D9D] rounded-[5px] text-center text-sm"
        [formControl]="rowsLimitControl"
      >
        <option value="5">5</option>
        <option value="10" selected>10</option>
        <option value="25">25</option>
        <option value="50">50</option>
        <option value="75">75</option>
        <option value="100">100</option>
      </select>
    </section>
    }

    <p class="text-sm text-[#AAA] font-medium mx-auto">
      {{paginationTextFormatted}}
    </p>

    <section
      class="flex items-center gap-2"
      [ngClass]="{ 'invisible opacity-50': totalPages === 0 }"
    >

      <button
        class="px-3 py-2 text-[#6B7280] font-medium text-base flex items-center gap-2 border border-[#E5E7EB] rounded-lg hover:bg-slate-100 transition-colors"
        [ngClass]="{ 'cursor-not-allowed invisible': totalPages === 0 }"
        (click)="previousPage()"
        [disabled]="totalPages === 0"
      >
        <span class="text-[#4B5563]">«</span> Previous
      </button>

      <input
        class="max-h-[34px] aspect-square text-tertiary border border-[#E5E7EB] outline-none font-medium text-[15px] rounded text-center focus:text-black focus:ring-1 focus:ring-offset-1 focus:ring-primary focus:border-none focus:bg-slate-200 hover:bg-slate-100 transition-colors [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"
        [ngClass]="{
          'focus:ring-[#E5E7EB] cursor-not-allowed select-none focus:ring-offset-0':
            totalPages === 0
        }"
        [value]="pageControl.value"
        [formControl]="pageInputControl"
        type="number"
        [disabled]="totalPages === 0"
        [readonly]="totalPages === 0"
      />

      <span class="text-[#6B7280] font-medium text-base text-nowrap">
        of {{ totalPages === 0 ? 1 : totalPages }}
      </span>


      <button
        class="px-3 py-2 text-[#6B7280] font-medium text-base flex items-center gap-2 border border-[#E5E7EB] rounded-lg hover:bg-slate-100 transition-colors"
        [ngClass]="{ 'cursor-not-allowed invisible': totalPages === 0 }"
        (click)="nextPage()"
        [disabled]="totalPages === 0"
      >
        Next <span class="text-[#4B5563]">»</span>
      </button>
    </section>
  </footer>
</div>

<ng-template #defaultCellTemplate let-column="column" let-item="item">
  <div class="w-full truncate max-w-60">
    <span class="text-xs font-medium truncate block" [matTooltip]="column.valueFn
    ? column.valueFn(item)
    : column.valueKey
    ? item[column.valueKey]
    : ''">
      {{
        column.valueFn
          ? column.valueFn(item)
          : column.valueKey
          ? item[column.valueKey]
          : ''
      }}
    </span>
  </div>
</ng-template>

<ng-template
  #pictureAndTextCellTemplate
  let-text="text"
  let-picture="picture"
  let-pictureClasses="pictureClasses"
  let-textClasses="textClasses"
  let-item="item"
  let-valueClasses="valueClasses"
>
  <div
    class="w-full truncate flex items-center gap-2"
    [ngClass]="[valueClasses ?? '']"
  >
    <img
      [src]="picture"
      [alt]="text"
      [ngClass]="[
        'size-[28px] rounded-full object-cover',
        pictureClasses ?? ''
      ]"
    />
    <span [ngClass]="['truncate', textClasses ?? '']" [matTooltip]="text">
      {{
        text.length > 20
          ? text.substr(0, 20) + '...'
          : text
      }}
    </span>
  </div>
</ng-template>

<ng-template #actionsCellTemplate let-buttons="buttons" let-item="item">
  <div class="w-full flex items-center justify-center gap-1">
    @for (button of buttons; track $index) { @if
    (validateUserPermission(button.allowedRoles)) { @if (button.type ===
    'icon-button') {
    <arkiq-icon-button
      [icon]="button.icon"
      [matTooltip]="button.tooltip ?? ''"
      (click)="$event.stopPropagation(); button.handler(item)"
    />
    } @else {
    <arkiq-button
      [text]="button.text"
      [variant]="button.variant"
      size="md"
      [rounded]="false"
      [icon]="button.icon"
      [matTooltip]="button.tooltip ?? ''"
      (click)="$event.stopPropagation(); button.handler(item)"
    />
    } } }
  </div>
</ng-template>

<ng-template #pictureAndAltCellTemplate let-alt="alt" let-picture="picture">
  <div class="w-full truncate flex items-center justify-center">
    <img
      [src]="picture"
      [alt]="alt"
      class="size-[28px] object-cover"
      [matTooltip]="alt"
    />
  </div>
</ng-template>

<ng-template
  #badgeCellTemplate
  let-column="column"
  let-item="item"
  let-badge="badge"
>
  <div
    class="w-full flex items-center justify-center rounded px-1.5 py-0.5"
    [class.flex-row-reverse]="badge.iconPosition === 'RIGHT'"
    [class.bg-badge-offline-container]="badge.badgeStatus === 'OFFLINE'"
    [class.bg-badge-online-container]="badge.badgeStatus === 'ONLINE'"
  >
    <i
      class="bi text-[#717579] text-[20px]"
      [ngClass]="badge.icon"
      [class.text-badge-offline-text]="badge.badgeStatus === 'OFFLINE'"
      [class.text-badge-online-text]="badge.badgeStatus === 'ONLINE'"
    ></i>
    <span
      class="text-xs font-medium"
      [class.text-badge-offline-text]="badge.badgeStatus === 'OFFLINE'"
      [class.text-badge-online-text]="badge.badgeStatus === 'ONLINE'"
    >
      {{
        column.valueFn
          ? column.valueFn(item)
          : column.valueKey
          ? column[item.valueKey]
          : ''
      }}
    </span>
  </div>
</ng-template>

<!-- SHOW_MORE -->
<ng-template #showMoreCellTemplate let-object="object">
  <div class="w-full text-center">
    <span class="text-xs font-medium truncate block" [matTooltip]="object.first">
      {{
        object.first.length > 20
          ? object.first.substr(0, 20) + '...'
          : object.first
      }}

      @if(object.more.length > 0) {
      <p
        class="text-primary text-xs underline font-semibold"
        [matTooltip]="object.more.join(', ')"
      >
        {{ object.more.length }} more
      </p>
      }
    </span>
  </div>
</ng-template>
