import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  FormArray,
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { Organization, OrganizationsService, User } from '@arkiq-portals/sdk';
import {
  ButtonComponent,
  SelectComponent,
  SpinnerComponent,
} from '@arkiq-portals/ui';
import { UserOrganizationRoleFormLineComponent } from './user-organization-role-form-line/user-organization-role-form-line.component';

@Component({
  selector: 'app-user-organization-role-form',
  templateUrl: 'user-organization-role-form.component.html',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SelectComponent,
    ButtonComponent,
    UserOrganizationRoleFormLineComponent,
    SpinnerComponent,
  ],
})
export class UserOrganizationRoleFormComponent implements OnInit {
  @Input() user?: User;

  @Input() form!: FormArray<
    FormGroup<{
      id: FormControl<string | null>;
      role: FormControl<string | null>;
      organizationId: FormControl<string | null>;
      propertyId: FormControl<string | null>;
    }>
  >;

  @Output() addProperty = new EventEmitter<string>();

  public buildItemForm = () =>
    new FormGroup({
      id: new FormControl(''),
      role: new FormControl('', [Validators.required]),
      organizationId: new FormControl('', [Validators.required]),
      propertyId: new FormControl('', [Validators.required]),
    });

  public isLoading = false;
  public organizations: Organization[] = [];

  constructor(private organizationsService: OrganizationsService) {}

  public ngOnInit(): void {
    this.getOrganizations();
  }

  private async getOrganizations() {
    try {
      this.isLoading = true;

      const response = await this.organizationsService.list({
        limit: 999999,
      });

      this.organizations = response.organizations;
      this.isLoading = false;
    } catch (error) {
      this.isLoading = false;
      console.error(error);
      alert(error);
    }
  }

  public handleAddAnotherRole() {
    this.form.push(this.buildItemForm());
  }

  public onAddProperty(organizationId: string): void {
    this.addProperty.emit(organizationId);
  }

  public onRemoveLine(index: number) {
    this.form.removeAt(index);
  }
}
