<input
  #input
  type="file"
  accept="image/*"
  hidden
  (change)="handleUploadPicture($event)"
/>

<form class="w-full flex flex-col gap-6">
  <header
    class="w-full flex flex-col gap-4 pb-6 border-b border-dashed border-b-black/20"
  >
    <h3 class="m-0 font-bold text-base">Create Property</h3>

    <section class="w-full flex gap-[30px] items-start">
      <button
        type="button"
        class="min-w-[174px] min-h-[174px] max-w-[174px] max-h-[174px] rounded-[21px] bg-[#EEEEEE] flex items-center justify-center hover:brightness-90 transition-all"
        (click)="input.click()"
      >
        <img
          *ngIf="form.controls.photoUrl.value"
          [src]="form.controls.photoUrl.value"
          alt="New Property Picture"
          class="min-w-[174px] min-h-[174px] max-w-[174px] max-h-[174px] rounded-[21px] object-cover"
        />

        <span
          *ngIf="!form.controls.photoUrl.value"
          class="text-lg font-normal text-[#AAAAAA]"
        >
          Image
        </span>
      </button>

      <div class="flex flex-col gap-5">
        <arkiq-input
          type="text"
          [required]="true"
          label="Name"
          placeholder="Name"
          [control]="form.controls.name"
          [rounded]="false"
          size="md"
          (keyup.enter)="handleSave()"
        />

        <arkiq-select
          label="Type"
          [required]="true"
          size="md"
          [rounded]="false"
          [control]="form.controls.type"
          [options]="propertyTypeOptions"
        />
      </div>
    </section>
  </header>

  <main
    class="w-full flex flex-col gap-6 pb-6 border-b border-dashed border-b-black/20"
  >
    <h3 class="m-0 font-bold text-base">Address</h3>

    <arkiq-input
      type="text"
      [required]="true"
      label="Street"
      placeholder="Street"
      [control]="form.controls.address_street"
      [rounded]="false"
      size="md"
      (keyup.enter)="handleSave()"
    />

    <fieldset class="w-full grid grid-cols-1 lg:grid-cols-4 gap-4">
      <arkiq-input
        class="col-span-1 lg:col-span-2"
        type="text"
        [required]="true"
        label="City"
        placeholder="City"
        [control]="form.controls.address_city"
        [rounded]="false"
        size="md"
        (keyup.enter)="handleSave()"
      />

      <arkiq-input
        class="col-span-1"
        type="text"
        [required]="true"
        label="State"
        placeholder="State"
        [control]="form.controls.address_state"
        [rounded]="false"
        size="md"
        (keyup.enter)="handleSave()"
      />

      <arkiq-input
        class="col-span-1"
        type="text"
        [required]="true"
        label="Zip Code"
        placeholder="Zip Code"
        [control]="form.controls.address_zip_code"
        [rounded]="false"
        size="md"
        (keyup.enter)="handleSave()"
      />
    </fieldset>
  </main>

  <footer class="w-full flex flex-col gap-6">
    <h3 class="m-0 font-bold text-base">Property Master</h3>

    <arkiq-select-organization-master
      title="Select Property Master User"
      placeholder="Select Property Master User"
      addBtnLabel="Add property master"
      (selectUser)="form.controls.master.setValue($event)"
      (createUser)="form.controls.master.setValue($event)"
      (resetUser)="form.controls.master.setValue(null)"
      [initialValue]="form.value.master"
      (masterFormTouchedAndValid)="isMastersTouchedAndValid($event)"
    />

    <div class="w-full flex justify-end gap-2 border-t border-t-black/20 pt-6">
      <arkiq-button
        text="Discard"
        variant="tertiary"
        [rounded]="false"
        size="md"
        (click)="handleDiscard()"
      />
      <arkiq-button
        text="Save Property"
        variant="outline-primary"
        [rounded]="false"
        size="md"
        [disabled]="form.invalid || !isMastersValid"
        (click)="handleSave()"
      />
    </div>
  </footer>
</form>
