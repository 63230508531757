<arkiq-button
  variant="primary"
  icon="bi-chevron-down"
  text="Add"
  size="md"
  [rounded]="false"
  [matMenuTriggerFor]="menu"
/>

<mat-menu #menu="matMenu">
  <button
    type="button"
    class="w-full flex items-center gap-2 p-2 py-4 border-b border-dashed border-gray-300 hover:bg-gray-100 transition-all"
    (click)="handleAddFixture()"
  >
    <span>Fixture</span>
  </button>

  <button
    type="button"
    class="w-full flex items-center gap-2 p-2 py-4 hover:bg-gray-100 transition-all"
    (click)="handleAddLocation()"
  >
    <span>Sub-Location</span>
  </button>
</mat-menu>
