<div class="w-full flex items-center gap-4">
  <fieldset class="w-full grid grid-cols-3 gap-[10px]">
    <arkiq-select
      label="Role"
      [required]="true"
      size="md"
      [rounded]="false"
      [options]="roleOptions"
      [control]="form.controls.role"
    />
  
    <div class="flex flex-col gap-2">
      <arkiq-select
        label="Organization"
        [required]="true"
        size="md"
        [rounded]="false"
        [options]="organizationOptions"
        [control]="form.controls.organizationId"
      />
  
      <button
        type="button"
        class="underline text-primary font-semibold text-sm self-end hover:opacity-75 transition-opacity"
        (click)="handleAddOrganization()"
      >
        Add organization
      </button>
    </div>
  
    <div class="flex flex-col gap-2">
      <arkiq-select
        label="Property"
        [required]="true"
        size="md"
        [rounded]="false"
        [options]="propertyOptions"
        [control]="form.controls.propertyId"
      />
  
      @if (form.value.organizationId) {
        <button
          type="button"
          class="underline text-primary font-semibold text-sm self-end hover:opacity-75 transition-opacity"
          (click)="handleAddProperty()"
        >
          Add property
        </button>
      }
    </div>
  </fieldset>

  @if (enableRemoveButton) {
    <arkiq-icon-button icon="bi-x-lg" (click)="handleRemoveLine()" />
  }
</div>