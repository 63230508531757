<main class="flex flex-col flex-1 max-w-full">
  <arkiq-table
    label="Organization(s)"
    [isLoading]="isLoading"
    [data]="organizations"
    [columns]="columns"
    [enableSearch]="true"
    searchInputLabel="Search by Name"
    [buttons]="tableHeaderButtons"
    (searchChanged)="onSearch($event)"
    (rowClicked)="onOrganizationClicked($event)"
    [totalPages]="totalPages"
    [totalItems]="totalItems"
    (rowsLimitChanged)="onTableRowsLimitChanged($event)"
    (pageChanged)="onTablePageChanged($event)"
    (sortOrderChanged)="onTableSortChanged($event)"
    (clearAllFilters)="onClearFilters()"
    [enableClearAllFilters]="true"
    [clearFiltersType]="CLEAR_FILTERS_BUTTON_TYPE"
    customHeaderClasses="flex-col md:flex-row"
    maxHeightPageClasses="max-h-[calc(100dvh-440px)] md:max-h-[calc(100dvh-275px)]"
  />
</main>
