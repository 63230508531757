<div
  class="bg-white flex flex-col w-full max-h-[300px]"
  [ngClass]="{ 'pb-2': anyAlerts() }"
>
  <header class="py-3 pl-4 pr-1 flex items-center border-b border-b-[#E0E0E0]">
    <h3 class="text-[#121212] font-semibold text-base">Rate of Alerts</h3>
    <i class="bi bi-exclamation-triangle-fill text-[#CE5347] ml-1"></i>

    <a
      [routerLink]="['/alets']"
      routerLinkActive="router-link-active"
      class="text-primary font-medium text-sm ml-auto hover:bg-[#EEE] rounded-full px-4 py-1 transition-all"
    >
      See all</a
    >
  </header>
  <main class="flex flex-col gap-3 p-3 overflow-y-auto custom-scrollbar">
    @if(anyAlerts()) { @for(alert of alerts; track $index) {
    <rate-card [alert]="alert" />
    } } @else {
    <span class="text-[#CCC] font-semibold text-center">No Alerts</span>
    }
  </main>
</div>
