import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { FunctionsService } from '@arkiq-portals/sdk';
import {
  DialogAlertComponent,
  DialogAlertParams,
  DialogAlertVariant,
} from '@arkiq-portals/ui';
import { take } from 'rxjs';
import {
  ButtonComponent,
  CheckboxComponent,
  InputComponent,
  PasswordInputComponent,
} from '../../../../components';
import { AuthStepsComponent } from '../../components';

@Component({
  selector: 'arkiq-auth-reset-password',
  templateUrl: 'reset-password.component.html',
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    AuthStepsComponent,
    InputComponent,
    PasswordInputComponent,
    CheckboxComponent,
    ButtonComponent,
  ],
})
export class AuthResetPasswordComponent implements OnInit {
  public isLoading = false;

  public key = '';
  public email = '';

  public form = new FormGroup({
    key: new FormControl('', [Validators.required]),
    email: new FormControl('', [Validators.required, Validators.email]),
    password: new FormControl('', [Validators.required]),
    passwordConfirmation: new FormControl('', [Validators.required]),
  });

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private matDialog: MatDialog,
    private functionsService: FunctionsService,
  ) {}

  public ngOnInit(): void {
    this.getKeyAndEmail();
  }

  private getKeyAndEmail() {
    const key = this.activatedRoute.snapshot.queryParamMap.get('key');
    const email = this.activatedRoute.snapshot.queryParamMap.get('email');

    if (!key || !email) {
      const ref = this.showAlert({
        variant: DialogAlertVariant.ERROR,
        title: 'Reset password key and user email are required',
        text: 'Please check if the email you received is correct',
      });

      ref
        .afterClosed()
        .pipe(take(1))
        .subscribe(() => {
          this.router.navigateByUrl('/');
        });

      return;
    }

    this.key = key;
    this.email = email.split(' ').join('+');

    this.form.patchValue({ key: this.key, email: this.email });
  }

  public async handleResetPassword() {
    if (
      !this.form.value.email ||
      !this.form.value.key ||
      !this.form.value.password ||
      !this.form.value.passwordConfirmation
    ) {
      return;
    }

    try {
      this.isLoading = true;

      await this.functionsService.setPassword(
        this.form.value.email,
        this.form.value.key,
        this.form.value.password,
        this.form.value.passwordConfirmation,
      );

      const ref = this.showAlert({
        variant: DialogAlertVariant.SUCCESS,
        title: 'Password created with success,',
        text: 'Check your email',
      });

      ref
        .afterClosed()
        .pipe(take(1))
        .subscribe(() => {
          this.router.navigateByUrl('/auth/sign-in');
        });
    } catch (error) {
      console.error(error);

      this.showAlert({
        variant: DialogAlertVariant.ERROR,
        title: 'Unable to sign in',
        text: 'Please, check your credentials and try again',
      });
    } finally {
      this.isLoading = false;
    }
  }

  public showAlert(params: DialogAlertParams) {
    const ref = this.matDialog.open(DialogAlertComponent, {
      hasBackdrop: true,
      disableClose: false,
      data: params,
    });

    return ref;
  }
}
