import { CommonModule } from '@angular/common';
import { Component, EventEmitter, OnInit,OnDestroy, Output, Input } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { User, UserRole, UserRoleLabel, UserType, UsersService } from '@arkiq-portals/sdk';
import { InputComponent } from '../input/input.component';
import { ButtonComponent } from '../button/button.component';
import { IconButtonComponent } from '../icon-button/icon-button.component';
import { Subscription } from 'rxjs';
import { SelectComponent, SelectOption } from '../select/select.component';

@Component({
  selector: 'arkiq-add-organization-master-form',
  templateUrl: 'add-organization-master-form.component.html',
  standalone: true,
  imports: [CommonModule, InputComponent, ButtonComponent, IconButtonComponent, SelectComponent],
})
export class AddOrganizationMasterFormComponent implements OnInit, OnDestroy {
  @Output() saveForm = new EventEmitter<Partial<User>>();
  @Output() cancelForm = new EventEmitter();
  @Output() resetForm = new EventEmitter();
  @Output() onFormTouchedAndValid = new EventEmitter<boolean>();
  @Input() isRoleSelectionShowing = false;
  @Output() showSelectManaginUsers = new EventEmitter();

  private subscriptions = new Subscription()
  private touched = false;
  public roleOptions!: SelectOption[];
  public lock = false;

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe()
  }
  ngOnInit(): void {
    this.selectRoleBasedOnUserType()
    this.subscriptions.add(
      this.form.valueChanges.subscribe((values) => {
        if(Object.values(values).every(value => value?.length == 0)) {
          this.form.markAsPristine()
          this.form.markAsUntouched()
          this.touched = false
          this.onFormTouchedAndValid.emit(this.formFieldsIsEmptyOrValid())
        } else {
          if (!this.touched) {
            this.onFormTouchedAndValid.emit(this.formFieldsIsEmptyOrValid())
          }
          this.touched = true
        }
      })
    )

    this.updateRoleValidator()
  }

  private formFieldsIsEmptyOrValid() {
    const isEmpty = Object.values(this.form.value).every(value => !value);
    const isValid = this.form.valid;
    return isEmpty || isValid
  }

  public form = new FormGroup({
    firstName: new FormControl({ value: '', disabled: this.lock }, [
      Validators.required,
    ]),
    lastName: new FormControl({ value: '', disabled: this.lock }, [
      Validators.required,
    ]),
    email: new FormControl({ value: '', disabled: this.lock }, [
      Validators.required,
      Validators.email,
    ]),
    phoneNumber: new FormControl({ value: '', disabled: this.lock }, [
      Validators.pattern("[0-9]+"),
      Validators.required,
    ]),
    role: new FormControl({value:'', disabled: this.lock})
  });

  public handleSave() {
    if (this.form.invalid) {
      return;
    }
    this.saveForm.emit(this.form.value as Partial<User>);
    this.form.controls.email.disable();
    this.form.controls.firstName.disable();
    this.form.controls.lastName.disable();
    this.form.controls.phoneNumber.disable();
    this.lock = true;
    this.onFormTouchedAndValid.emit(true)
  }

  public handleCancel() {
    this.onFormTouchedAndValid.emit(true)
    this.cancelForm.emit();
  }

  public selectAssignUser() {
    this.onFormTouchedAndValid.emit(true)
    this.showSelectManaginUsers.emit()
  }

  public handleEdit() {
    this.lock = false;
    this.form.controls.email.enable();
    this.form.controls.firstName.enable();
    this.form.controls.lastName.enable();
    this.form.controls.phoneNumber.enable();
  }

  public handleResetForm() {
    this.lock = false;
    this.form.setValue({
      firstName: '',
      lastName: '',
      email: '',
      phoneNumber: '',
      role: ''
    });
  }

  public async selectRoleBasedOnUserType() {
    this.roleOptions = [UserRole.ORGANIZATION_MASTER,UserRole.PROPERTY_MASTER].map(role => {
        return {
          label: UserRoleLabel[role],
          value: role,
        };
      });
  }

  public updateRoleValidator():void {
    this.isRoleSelectionShowing && this.form.controls.role.setValidators( [Validators.required])
  }
}
