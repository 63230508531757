import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router, RouterModule } from '@angular/router';
import { DeviceOverview, DeviceType, Fixture, FixturesService, Location, LocationsService, PropertyLayoutService } from '@arkiq-portals/sdk';
import { DialogDeviceKeysComponent } from '@arkiq-portals/ui';
import { formatDate } from 'date-fns';

@Component({
  selector: 'app-device-details-header',
  templateUrl: './device-details-header.component.html',
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
  ],
})
export class DeviceDetailsHeaderComponent implements OnInit {
  @Input({ required: true }) device!: DeviceOverview;

  public DEVICE_TYPE = DeviceType;
  public batteryLevelIcon;
  public statusClass;
  public deviceFixture?: Fixture;
  public locationPath: Location[] = [];

  constructor(
    private matDialog: MatDialog,
    private fixturesService: FixturesService,
    private locationsService: LocationsService,
    private propertyLayoutService: PropertyLayoutService,
    private router: Router,
  ) {}

  public ngOnInit(): void {
    this.batteryLevelIcon = this.deviceBatteryLevel();
    this.statusClass = this.findDeviceStatus();

    this.getDeviceFixture();
  }

  public async getDeviceFixture(): Promise<void> {
    try {
      const [fixture] = await this.fixturesService.listByDeviceId(this.device.id);
      this.deviceFixture = fixture;

      if (fixture) {
        await this.getDeviceLocation(fixture);
      }
    } catch (error) {
      console.error(error);
    }
  }

  public async getDeviceLocation(fixture: Fixture): Promise<void> {
    try {
      const response = await this.propertyLayoutService.listByLocationId(
        fixture.location_id,
        fixture.property_id,
        this.device.device.property.root_location_id,
      );

      const propertyLayoutItem = response.data[0];

      const locationsIds = propertyLayoutItem.pl_path
        .split('.')
        .map(Number);

      const locations = await this.locationsService.getManyById(locationsIds);
      for (const id of locationsIds) {
        const location = locations.find(loc => loc.id === id);
        this.locationPath.push(location as Location);
      }
    } catch (error) {
      console.error(error);
    }
  }

  public handleKeysClick() {
    this.matDialog.open(DialogDeviceKeysComponent, {
      hasBackdrop: true,
      disableClose: false,
      data: {
        text: 'abc',
      },
    });
  }

  public handleGoToDeviceLocation(): void {
    const location = this.locationPath.at(-1);
    if(!this.deviceLocationPath) return
    this.router.navigateByUrl(
      `/properties/${this.device.property_id}/layout`,
      {
        state: { location },
      },
    );
  }

  public handleLinkedDeviceTextClick() {
    console.log('handleLinkedDeviceTextClick');
  }

  public get organizationName() {
    return this.device.device.organization?.name ?? '';
  }

  public get propertyName() {
    return this.device.device.property?.name ?? '';
  }

  public get propertyAddress() {
    return (
      this.device.device.property.address_street +
      ' ' +
      this.device.device.property.address_zip_code +
      ' • ' +
      this.device.device.property.address_city +
      ' • ' +
      this.device.device.property.address_state
    );
  }

  public get deviceLocationPath(): string {
    return this.locationPath.
      map(location => location.name)
      .join(' - ');
  }

  public deviceBatteryLevel() {
    if (!this.device.battery_level) {
      return '/assets/uncertain-battery-level.svg';
    }
    if (Number(this.device.battery_level) > 74) {
      return '/assets/high-battery-level.svg';
    }

    if (Number(this.device.battery_level) > 49) {
      return '/assets/medium-battery-level.svg';
    }

    return '/assets/low-battery-level.svg';
  }

  public findDeviceStatus() {
    return this.device.status === 'online'
      ? {
          container: 'bg-state-completed/15',
          dot: 'bg-state-completed',
          text: 'text-state-completed',
        }
      : {
          container: 'bg-state-cancel/15',
          dot: 'bg-state-completed',
          text: 'text-state-cancel',
        };
  }

  public get purchasedOn(): string {
    return this.device.purchased_on
      ? formatDate(this.device.purchased_on, 'MMM dd, yyyy')
      : '-';
  }

  public get soldOn(): string {
    return this.device.sold_on
      ? formatDate(this.device.sold_on, 'MMM dd, yyyy')
      : '-';
  }

  public get installedOn(): string {
    return this.device.installed_on
      ? formatDate(this.device.installed_on, 'MMM dd, yyyy')
      : '-';
  }
}
