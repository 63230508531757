<div class="w-full flex items-start justify-between gap-6">
  <h1 class="m-0 text-[22px] font-bold text-[#D7A339] uppercase">
    {{ title }}
  </h1>

  <!-- <div
    class="w-full flex-1 flex-row lg:max-w-[70%] flex lg:items-center justify-center gap-[15px]"
  >
    <arkiq-input
      *ngIf="!hideSearchBar"
      class="lg:max-w-[368px] flex-1"
      [control]="searchControl"
      placeholder="Search here..."
      size="md"
      variant="secondary"
      icon="bi-search"
      iconPosition="right"
    />

    <arkiq-icon-button icon="bi-bell-fill" />

    <arkiq-icon-button icon="bi-gear-fill" />
  </div> -->

  <div class="flex items-center gap-[15px]">
    <arkiq-user-badge />

    <!-- <arkiq-language-selector /> -->
  </div>
</div>
