<div class="border border-black/15 rounded-2xl max-h-[400px] overflow-y-auto custom-scrollbar">
  @for (property of properties; track $index) {
    <div class="relative [&:not(:last-child)]:border-b [&:not(:last-child)]:border-b-black/35 [&:not(:last-child)]:bg-red">
      <p class="absolute top-[4px] left-[8px] text-[#AAA] font-bold">
        {{$index + 1}}
      </p>
        <header class="py-5 px-[21px] flex gap-4 items-center border-b border-black/15">
          <img [src]="propertyPhoto(property.property)" alt="Property Photo" class="h-[63px] w-[63px] rounded-[7px]">
          <div class="flex flex-col justify-between">
            <h3 class="font-semibold text-lg">{{property.property.name}}</h3>
            <p class="text-[#888] text-base">{{property.property.type}}</p>
          </div>

          <div class="flex items-center gap-3 ml-auto">
            <arkiq-icon-button icon="bi-pencil-square" (click)="edit.emit($index)" />

            <arkiq-icon-button icon="bi-trash-fill" (click)="delete.emit($index)" />
          </div>
        </header>

        <section class="py-5 px-[21px] flex flex-col gap-4">
          <h4 class="font-bold text-[#121212] text-base">Address</h4>
          <h5 class="font-bold text-sm text-[#121212]">Street/House no</h5>
          <p class="text-[#888] text-base">{{propertyAddress(property.property)}}</p>
        </section>

        <section class="pb-5 px-[21px] flex items-center flex-wrap justify-between gap-2"
        [ngClass]="{'border-b border-b-black/15': property.master}"
        >
          <div class="flex flex-col truncate">
            <h5 class="font-bold text-sm text-[#121212]">City</h5>
            <p class="text-[#888] text-base truncate" [matTooltip]="property.property.address_city">{{property.property.address_city}}</p>
          </div>
          <div class="flex flex-col">
            <h5 class="font-bold text-sm text-[#121212]">State</h5>
            <p class="text-[#888] text-base truncate" [matTooltip]="property.property.address_state">{{property.property.address_state}}</p>
          </div>
          <div class="flex flex-col">
            <h5 class="font-bold text-sm text-[#121212]">Zip Code</h5>
            <p class="text-[#888] text-base truncate" [matTooltip]="property.property.address_zip_code">{{property.property.address_zip_code}}</p>
          </div>
        </section>
        @if(property.master) {
          <section class="py-5 px-[21px] flex flex-col gap-4">
            <h4 class="font-bold text-[#121212] text-base">Property Master User</h4>
            <div class="flex justify-between flex-wrap items-center gap-2 truncate">
              <div class="flex flex-col gap-2 truncate">
                <h5 class="font-bold text-sm text-[#121212]">First Name</h5>
                <p class="text-[#888] text-base truncate" [matTooltip]="property.master.firstName">{{property.master.firstName}}</p>
              </div>
              <div class="flex flex-col gap-2 truncate">
                <h5 class="font-bold text-sm text-[#121212]">Last Name</h5>
                <p class="text-[#888] text-base truncate" [matTooltip]="property.master.lastName">{{property.master.lastName}}</p>
              </div>
              <div class="flex flex-col gap-2 truncate">
                <h5 class="font-bold text-sm text-[#121212]">Email</h5>
                <p class="text-[#888] text-base truncate" [matTooltip]="property.master.email">{{property.master.email}}</p>
              </div>
              <div class="flex flex-col gap-2 truncate">
                <h5 class="font-bold text-sm text-[#121212]">Phone Number</h5>
                <p class="text-[#888] text-base truncate" [matTooltip]="property.master.phoneNumber">{{property.master.phoneNumber}}</p>
              </div>
            </div>
          </section>
        }

    </div>
  }

</div>
