import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { RouterModule } from '@angular/router';

@Component({
  selector: 'arkiq-auth',
  templateUrl: 'auth.component.html',
  standalone: true,
  imports: [CommonModule, RouterModule],
})
export class AuthComponent {
  constructor() {}
}
