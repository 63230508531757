import { CommonModule, Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, RouterModule, Router } from '@angular/router';
import {
  PropertiesService,
  Property,
  UserRole,
  PropertyTypeLabel,
} from '@arkiq-portals/sdk';
import {
  IconButtonComponent,
  SpinnerComponent,
  ButtonComponent,
  HasRoleDirective,
} from '@arkiq-portals/ui';
import { PropertyDetailsTabsComponent } from '../components/property-details-tabs/property-details-tabs';

@Component({
  selector: 'app-property-details',
  templateUrl: 'property-details.component.html',
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    SpinnerComponent,
    IconButtonComponent,
    PropertyDetailsTabsComponent,
    ButtonComponent,
    HasRoleDirective,
  ],
})
export class PropertyDetailsComponent implements OnInit {
  public property!: Property;

  public isLoading = false;

  public USER_ROLES = UserRole;

  constructor(
    private activatedRoute: ActivatedRoute,
    private propertiesService: PropertiesService,
    private location: Location,
    private router: Router
  ) {}

  public ngOnInit(): void {
    const routeParams = this.activatedRoute.snapshot.params;

    if ('id' in routeParams) {
      this.getProperty(Number(routeParams['id']));
    }
  }

  private async getProperty(propertyId: number) {
    try {
      this.isLoading = true;

      this.property = await this.propertiesService.getById(propertyId);

      this.isLoading = false;
    } catch (error) {
      console.error(error);
      this.isLoading = false;
      alert(error);
    }
  }

  public handleEditProperty() {
    this.router.navigateByUrl(`/properties/${this.property.id}/edit`, {
      state: { property: this.property },
    });
  }

  public handleGoBack() {
    this.location.back();
  }

  private managerRoles = [
    UserRole.PROPERTY_MASTER,
    UserRole.BUILDING_MANAGEMENT,
  ];

  public get propertyManagersAmount() {
    const amount = this.property.users_organizations_roles?.filter(({ role }) =>
      this.managerRoles.includes(role)
    ).length;
    return amount;
  }

  public get propertyResidentsAmount() {
    const amount = this.property.users_organizations_roles?.filter(
      ({ role }) => role === UserRole.RESIDENT
    ).length;
    return amount;
  }
  public get propertyMaster() {
    const master = this.property.users_organizations_roles?.find(
      (userRole) => userRole.role === UserRole.PROPERTY_MASTER
    );
    return master?.user;
  }

  public get propertyType() {
    const propertyType = PropertyTypeLabel[this.property.type];
    return propertyType;
  }

  public get organizationName() {
    const organization = this.property.organization;
    if (!organization) return '';
    return organization.name;
  }
}
