import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFireDatabase } from '@angular/fire/compat/database';
import { Router } from '@angular/router';

import { Observable, first, firstValueFrom, map, of, switchMap } from 'rxjs';

import { Apollo, gql } from 'apollo-angular';
import { ENVIRONMENT, Environment } from '../sdk.module';

export type RegisterUserParams = {
  password: string;
  email: string;
};

export type SignInParams = {
  email: string;
  password: string;
};

export type ChangePasswordParams = {
  oldPassword: string;
  newPassword: string;
};

export type ResetPasswordParams = {
  key: string;
  password: string;
};

@Injectable({ providedIn: 'root' })
export class AuthService {
  private environment: Environment;

  public firebaseUser: any | undefined;

  constructor(
    @Inject(ENVIRONMENT) environment: Environment,
    private http: HttpClient,
    private auth: AngularFireAuth,
    private db: AngularFireDatabase,
    private router: Router,
    private apollo: Apollo,
  ) {
    this.environment = environment;
  }

  public async deleteAccount() {
    const user = await firstValueFrom(this.auth.user);

    if (user) {
      await user.delete();
    }
  }

  public async register(data: RegisterUserParams): Promise<void> {
    try {      
      const result = await this.auth.createUserWithEmailAndPassword(data.email, data.password);

      if (!result.user?.uid) {
        return;
      }

      await this.metadataCreateWatcher(result.user.uid);

      await result.user?.getIdToken(true);

      await firstValueFrom(
        this.apollo.mutate({
          mutation: gql`
            mutation InsertUser($email: String = "${data.email}") {
              insert_front_users_one(object: {email: $email}) {
                id
                email
              }
            }
          `,
        }),
      );

      this.router.navigateByUrl('/');
    } catch (error: any) {
      throw error?.error?.message || error?.message;
    }
  }

  public async signIn({ email, password }: SignInParams): Promise<boolean> {
    try {
      const result = await this.auth.signInWithEmailAndPassword(email, password);

      this.firebaseUser = result.user;

      await result.user?.getIdTokenResult();

      return true;
    } catch (error: any) {
      throw error?.error?.message || error?.message;
    }
  }

  public async signOut(): Promise<void> {
    console.log('chamou');
    try {
      await this.auth.signOut();
      this.router.navigateByUrl('/auth');
    } catch (error: any) {
      throw error?.message || error?.error?.message;
    }
  }

  public async sendForgotPasswordEmail(email: string): Promise<void> {
    try {
      await this.auth.sendPasswordResetEmail(email);
    } catch (error: any) {
      throw error?.message || error?.error?.message;
    }
  }

  public async changePassword({ oldPassword, newPassword }: ChangePasswordParams): Promise<void> {
    if (!this.firebaseUser) {
      return;
    }

    try {
      await this.signIn({ email: this.firebaseUser.email, password: oldPassword });

      const user = await this.auth.currentUser;

      await user?.updatePassword(newPassword);
    } catch (error: any) {
      throw error?.message || error?.error?.message;
    }
  }

  public async resetPassword({ key, password }: ResetPasswordParams): Promise<void> {
    try {
      const url = `${this.environment.apiUrl}/v2/auth/reset-password`;

      await firstValueFrom(this.http.post(url, { key, password }));
    } catch (error: any) {
      throw error?.message || error?.error?.message;
    }
  }

  public getToken(): Observable<string | null> {
    return this.auth.idToken;
  }

  public getTokenResult() {
    return this.auth.idTokenResult;
  }

  public reload(): void {
    this.auth.currentUser.then(user => user?.reload());
  }

  public async metadataCreateWatcher(firebaseUid: string): Promise<void> {
    await firstValueFrom(
      this.db
        .object(`metadata/${firebaseUid}/refreshTime`)
        .valueChanges()
        .pipe(
          first((refreshTime) => !!refreshTime)
        )
    );
  }

  public async refreshToken() {
    const user = await this.auth.currentUser;
    await user?.getIdToken(true);
  }

  public get isLoggedIn(): Observable<boolean> {
    return this.auth.user.pipe(
      switchMap(user => (user ? user.getIdTokenResult() : of(null))),
      map(token => !!token),
    );
  }
}
