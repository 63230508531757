<div class="w-full border-y border-y-[#E0E0E0] flex flex-col md:flex-row">
  <section
    class="flex-1 flex items-center gap-5 px-3 py-6 md:border-r md:border-r-[#E0E0E0]"
  >
    <div
      class="size-[91px] rounded-[20px] border border-black/10 flex items-center justify-center"
    >
      <i class="bi bi-wifi text-[30px] text-[#AAAAAA]"></i>
    </div>

    <div class="flex flex-col gap-1">
      <h2 class="font-bold text-[22px]">{{ device.type }}</h2>

      <p class="text-[#666666]">{{ device.id }}</p>

      <div class="flex items-center gap-3">
        <img class="w-8" [src]="batteryLevelIcon" alt="Battery Level Icon" />

        <div
          class="flex items-center gap-1 p-2 rounded-[6px]"
          [ngClass]="[statusClass.container]"
        >
          <div
            class="size-[6px] rounded-full"
            [ngClass]="[statusClass.dot]"
          ></div>
          <span class="capitalize" [ngClass]="[statusClass.text]">{{
            device.status
          }}</span>
        </div>
      </div>
    </div>
  </section>
  @if (organizationName) {
    <section class="flex justify-between md:justify-normal">
      <ul class="flex flex-col justify-center gap-1 p-6">
        <!-- max-w-[270px] border-r border-r-[#E0E0E0] border-dashed  Add these classes when keys is working-->
        <li
          class="flex items-baseline gap-2 text-primary hover:underline cursor-pointer"
          [routerLink]="'/organizations/' + device.device.organization.id"
        >
          <i class="bi bi-briefcase-fill text-[18px] "></i>
          <p class="">{{ organizationName }}</p>
        </li>

        <li
          class="flex items-baseline gap-2 text-primary hover:underline cursor-pointer"
          [routerLink]="'/properties/' + device.device.property.id"
        >
          <i class="bi bi-building-fill text-[18px] "></i>
          <p class="">{{ propertyName }}</p>
        </li>
        <li
          class="flex items-baseline gap-2"
          [ngClass]="{'text-primary hover:underline cursor-pointer': deviceLocationPath}"
          (click)="handleGoToDeviceLocation()"
        >
          <i class="bi bi-geo-alt-fill text-[18px]"
            [ngClass]="{'text-[#AAA]': !deviceLocationPath}"
          ></i>
          <p class="">
            {{ deviceLocationPath.length > 1 ? deviceLocationPath : "—" }}
          </p>
        </li>

        @if (deviceFixture) {
          <li class="flex items-baseline gap-2">
            <i class="bi bi-wrench text-[18px] text-[#AAAAAA]"></i>
            <p class="text-[#AAAAAA] capitalize">{{ deviceFixture.type }}</p>
          </li>
        }
      </ul>
      <div class="flex flex-col items-start justify-center gap-2 p-6 border-l border-l-black/20 border-dashed">
        @if (device.type === DEVICE_TYPE.TOILET_SENSOR) {
          <p
            class="text-[#888] font-extrabold text-[13px] w-full flex items-center justify-between gap-2"
          >
            Linked Device:
            <button
              (click)="handleLinkedDeviceTextClick()"
              class="text-[#00B4FF] underline font-medium text-[16px]"
            >
              {{ device.id }}
            </button>
          </p>
        }
        <ul class="flex flex-col justify-center gap-1">
          <li class="flex items-center gap-2">
            <p class="font-bold text-[#888888] text-[13px]">Purchased On:</p>
            <p class="text-[#888888] text-[16px] font-medium ml-auto">{{ purchasedOn }}</p>
          </li>

          <li class="flex items-center gap-2">
            <p class="font-bold text-[#888888] text-[13px]">Sold On:</p>
            <p class="text-[#888888] text-[16px] font-medium ml-auto">{{ soldOn }}</p>
          </li>

          <li class="flex items-center gap-2">
            <p class="font-bold text-[#888888] text-[13px]">Installed On:</p>
            <p class="text-[#888888] text-[16px] font-medium ml-auto">{{ installedOn }}</p>
          </li>
        </ul>

      </div>
      <!-- @else { bring it back when exist keys working
      <div class="flex flex-col items-start justify-center gap-2 px-6">

      <button
        class="flex items-center justify-center gap-2 px-2 py-1 font-medium text-[#AAAAAA] rounded-[7px] hover:bg-black/5 transition-colors border border-black/15"
        (click)="handleKeysClick()"
      >
        <i class="bi text-[24px] bi-key-fill"></i>
        View Keys
      </button>
      } -->

      <!--
    </div>
      -->
    </section>
  }
</div>
