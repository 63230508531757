import { CommonModule } from "@angular/common";
import { Component, Input } from "@angular/core";
import { MatTooltipModule } from "@angular/material/tooltip";

@Component({
  selector: 'arkiq-text-card',
  templateUrl: './text-card.component.html',
  standalone: true,
  imports: [CommonModule, MatTooltipModule],
})
export class TextCardComponent {
  @Input({ required: true }) text!: string;
  @Input() title?: string;
  @Input() subTitle?: string;

  public get tooltipText() {
    if (!this.title) {
      return this.text;
    }

    if (this.subTitle) {
      return `${this.title} | ${this.subTitle} | ${this.text}`;
    }

    return `${this.title} | ${this.text}`;
  }
}
