import { CommonModule, Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import {
  FunctionsService,
  Organization,
  OrganizationsService,
  PropertiesService,
  Property,
  StorageService,
  User,
  UserOrganizationRoleService,
  UserRole,
  UsersService,
  UserType,
} from '@arkiq-portals/sdk';
import {
  ButtonComponent,
  DialogAlertComponent,
  DialogAlertParams,
  DialogAlertVariant,
  IconButtonComponent,
  InputComponent,
  SelectOrganizationMasterComponent,
  SpinnerComponent,
  TextAreaComponent,
} from '@arkiq-portals/ui';
import { cloneDeep } from 'lodash';
import { take } from 'rxjs';
import { v7 as idGenerator } from 'uuid';
import {
  AddOrganizationPropertyFormComponent,
  SavePropertyEvent,
} from './add-organization-property-form/add-organization-property-form.component';
import { OrganizationPropertyCardComponent } from './organization-property-card/organization-property-card.component';

interface HandlePropertyParams  {
  organization: Organization
  organizationMaster?: Partial<User> | null
  propertiesWithMasters: SavePropertyEvent[]
  propertiesWithoutMasters: SavePropertyEvent[]
}
@Component({
  selector: 'app-create-organization',
  templateUrl: 'create-organization.component.html',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatDialogModule,
    SpinnerComponent,
    ButtonComponent,
    InputComponent,
    TextAreaComponent,
    SelectOrganizationMasterComponent,
    AddOrganizationPropertyFormComponent,
    OrganizationPropertyCardComponent,
    IconButtonComponent,
  ],
})
export class CreateOrganizationComponent implements OnInit {
  public organization?: Organization;

  public form = new FormGroup({
    name: new FormControl('', [Validators.required]),
    description: new FormControl(''),
    master: new FormControl<User | Partial<User> | null>(null),
    properties: new FormControl<Property[]>([]),
  });
  public propertyToEdit?: SavePropertyEvent;
  
  public shouldCreateProperty = false;

  public propertiesToSave: SavePropertyEvent[] = [];

  public isLoading = false;
  public isSaving = false;
  public isMastersValid = true;
  private emailsOfUsersCreated!:Map<string, User>

  constructor(
    private location: Location,
    private activatedRoute: ActivatedRoute,
    private organizationsService: OrganizationsService,
    private propertiesService: PropertiesService,
    private functionsService: FunctionsService,
    private usersService: UsersService,
    private userOrganizationRoleService: UserOrganizationRoleService,
    private storageService: StorageService,
    private matDialog: MatDialog,
  ) {}

  public ngOnInit(): void {
    const routeParams = this.activatedRoute.snapshot.params;
    this.emailsOfUsersCreated = new Map<string,User>()
    if ('id' in routeParams) {
      this.getOrganization(Number(routeParams['id']));
    }
  }

  private async getOrganization(organizationId: number) {
    try {
      this.isLoading = true;

      this.organization = await this.organizationsService.getById(
        organizationId,
      );

      this.populateForm();

      this.isLoading = false;
    } catch (error) {
      console.error(error);
      this.isLoading = false;
      alert(error);
    }
  }

  private populateForm() {
    if (!this.organization) {
      return;
    }

    this.form.patchValue({
      name: this.organization.name,
      description: this.organization.description,
      properties: this.organization.properties ?? [],
    });

    const master = this.organization.users_organizations_roles?.find(
      userRole => userRole.role === UserRole.ORGANIZATION_MASTER,
    );

    if (master?.user) {
      this.form.controls.master.setValue(master.user);
    }

    if (this.organization.properties?.length) {
      this.propertiesToSave = this.organization.properties.map(property => ({
        photo: undefined,
        master: property.users_organizations_roles?.find(
          userRole => userRole.role === UserRole.PROPERTY_MASTER,
        )?.user as Partial<User>,
        property,
        id: idGenerator()
      }));
    }


    this.form.markAllAsTouched();
  }

  public handleGoBack() {
    this.location.back();
  }

  public async handleSave(): Promise<void> {
    try {
      this.isSaving = true;

      const organization = await this.saveOrUpdateOrganization();


      const organizationMaster = this.form.value.master;
      const propertiesWithMasters = this.propertiesToSave.filter(properties => properties.master);
      const propertiesWithoutMasters = this.propertiesToSave.filter(properties => !properties.master)

      const validOrganizationMaster = organizationMaster && 'email' in organizationMaster
      ? organizationMaster
      : null;


      await this.handleProperty({
        organization,
        organizationMaster: validOrganizationMaster,
        propertiesWithoutMasters,
        propertiesWithMasters,
      });

      this.showAlert({
        variant: DialogAlertVariant.SUCCESS,
        title: 'Organization saved',
        text: `The ${organization.name} organization has been saved.`,
      })
        .afterClosed()
        .pipe(take(1))
        .subscribe(() => this.location.back());
    } catch (error) {
      console.error(error);
      this.showAlert({
        variant: DialogAlertVariant.ERROR,
        title: 'Organization not saved',
        text: error as string,
      });
    } finally {
      this.isSaving = false;
    }
  }

  private async saveOrUpdateOrganization(): Promise<Organization> {
    const organizationData = {
      name: this.form.value.name ?? '',
      description: this.form.value.description ?? '',
    };

    return this.organization?.id
      ? await this.organizationsService.update(
          this.organization.id,
          organizationData,
        )
      : await this.organizationsService.create(organizationData);
  }

  private async handleProperty(params: HandlePropertyParams ) {
    const organization = params.organization
    const organizationMaster = params.organizationMaster
    const propertiesWithMasters = params.propertiesWithMasters
    const propertiesWithoutMasters = params.propertiesWithoutMasters
    if (this.propertiesToSave.length == 0) {
      if (organizationMaster) {
        await this.saveOrganizationMaster(organization);
      } else {
        await this.removeOrganizationMaster(organization);
      }
      // await this.removeProperties() // TODO -> REMOVE PROPERTIES FROM ORGANIZATION
      return;
    }

    // Create Properties Without Property Masters
    if (propertiesWithoutMasters.length > 0 ) {
      await this.saveProperties(organization, propertiesWithoutMasters)
    }

    let alreadySavedOrganizationMaster = false;
    if (propertiesWithMasters.length > 0) {
      const propertiesCreatedWithMasters = await this.saveProperties(organization, propertiesWithMasters)
      // Save Masters
      for (let i=0; i<propertiesCreatedWithMasters.length; i++) {
        const property = propertiesCreatedWithMasters[i]
        const propertyMaster = propertiesWithMasters[i].master

        if(organizationMaster && propertyMaster.email === organizationMaster.email) {
          await this.saveUserAsOrganizationMasterAndPropertyMaster(organization,property);
          alreadySavedOrganizationMaster = true
          continue
        }
        await this.savePropertyMaster(organization, property, propertyMaster);
      }
    }
    if (!alreadySavedOrganizationMaster && organizationMaster) {
      await this.saveOrganizationMaster(organization)
    }
  }


  private async saveUserAsOrganizationMasterAndPropertyMaster(
    organization: Organization,
    property: Property,
  ) {
    const currentOrganizationMaster =
      organization.users_organizations_roles?.find(
        userRole => userRole.role === UserRole.ORGANIZATION_MASTER,
      );

    if (currentOrganizationMaster) {
      await this.userOrganizationRoleService.delete(
        currentOrganizationMaster.id,
      );
    }
    const masterAlreadyExists = Boolean(this.emailsOfUsersCreated.get(this.form.value.master?.email ?? ""));

    // User Already Exists
    if (this.form.value.master?.id || masterAlreadyExists) {
      const userId = this.form.value.master?.id

      const userObject = {
        organizationId: organization.id,
        userId: userId,
        propertyId: property.id,
      }
      await Promise.all([
        this.userOrganizationRoleService.create({
          ...userObject,
          role: UserRole.ORGANIZATION_MASTER,
        }),
        this.userOrganizationRoleService.create({
          ...userObject,
          role: UserRole.PROPERTY_MASTER,
        }),
      ]);
      return;
    }

    const { uid: firebaseId } = await this.functionsService.registerUser(
      this.form.value.master?.email as string,
      `${this.form.value.master?.firstName} ${this.form.value.master?.lastName}`,
    );

    const organizationMasterUser = await this.usersService.create({
      firebaseId,
      email: this.form.value.master?.email,
      firstName: this.form.value.master?.firstName,
      lastName: this.form.value.master?.lastName,
      phoneNumber: this.form.value.master?.phoneNumber,
      type: UserType.CUSTOMER,
      photo: '',
    });

    await Promise.all([
      this.userOrganizationRoleService.create({
        organizationId: organization.id,
        userId: organizationMasterUser.id,
        propertyId: property.id,
        role: UserRole.ORGANIZATION_MASTER,
      }),
      this.userOrganizationRoleService.create({
        organizationId: organization.id,
        userId: organizationMasterUser.id,
        propertyId: property.id,
        role: UserRole.PROPERTY_MASTER,
      }),
    ]);

    this.emailsOfUsersCreated.set(organizationMasterUser.email, organizationMasterUser)
  }

  private async saveProperties(organization: Organization, properties: SavePropertyEvent[]) {
    const propertiesWithStoragePhoto: (SavePropertyEvent & { storagePhoto?: string })[] = []
    for (const property of properties) {
      if(!property.photo || !property.property.id) {
        propertiesWithStoragePhoto.push(property)
        continue
      }
      const [_,photo] = await Promise.all([
        this.storageService.deleteFile(
          property.property.photo as string
        ),
        this.storageService.uploadPropertyPhoto(
          property.photo,
        )
      ])
      propertiesWithStoragePhoto.push({
        ...property,
        storagePhoto: photo
      })
    }
    // Update or Insert Property
    const propertiesPromises: Promise<Property>[] = []
    for (const property of propertiesWithStoragePhoto) {
      if (property.property.id) {
        propertiesPromises.push(this.propertiesService.update(
          property.property.id,
          {
            organizationId: organization.id,
            name: property.property.name,
            type: property.property.type,
            address_street: property.property.address_street,
            address_city: property.property.address_city,
            address_state: property.property.address_state,
            address_zip_code: property.property.address_zip_code,
            photo: property.storagePhoto,
          },
        ))

      } else {
        propertiesPromises.push(this.propertiesService.create({
          organizationId: organization.id,
          name: property.property.name,
          type: property.property.type,
          address_street: property.property.address_street,
          address_city: property.property.address_city,
          address_state: property.property.address_state,
          address_zip_code: property.property.address_zip_code,
          photo: property.storagePhoto ?? '',
        }))
      }
    }

    const propertiesCreated = await Promise.all(propertiesPromises)
    return propertiesCreated
  }

  private async removeOrganizationMaster(organization: Organization) {
    const currentOrganizationMaster =
      organization.users_organizations_roles?.find(
        userRole => userRole.role === UserRole.ORGANIZATION_MASTER,
      );

    if (currentOrganizationMaster) {
      await this.userOrganizationRoleService.delete(
        currentOrganizationMaster.id,
      );
    }
  }

  private async saveOrganizationMaster(organization: Organization) {


    if (this.form.value.master?.id) {
      await this.userOrganizationRoleService.create({
        organizationId: organization.id,
        userId: this.form.value.master.id,
        role: UserRole.ORGANIZATION_MASTER,
      });

      return;
    }

    const { uid: firebaseId } = await this.functionsService.registerUser(
      this.form.value.master?.email as string,
      `${this.form.value.master?.firstName} ${this.form.value.master?.lastName}`,
    );

    const organizationMasterUser = await this.usersService.create({
      firebaseId,
      email: this.form.value.master?.email,
      firstName: this.form.value.master?.firstName,
      lastName: this.form.value.master?.lastName,
      phoneNumber: this.form.value.master?.phoneNumber,
      type: UserType.CUSTOMER,
      photo: '',
    });

    await this.userOrganizationRoleService.create({
      organizationId: organization.id,
      userId: organizationMasterUser.id,
      role: UserRole.ORGANIZATION_MASTER,
    });
  }

  private async savePropertyMaster(
    organization: Organization,
    property: Property,
    user: Partial<User>
  ) {
    const currentPropertyMaster = organization.users_organizations_roles?.find(
      userRole => userRole.role === UserRole.PROPERTY_MASTER,
    );

    if (currentPropertyMaster) {
      await this.userOrganizationRoleService.delete(currentPropertyMaster.id);
    }

    if (user.id) {
      await this.userOrganizationRoleService.create({
        organizationId: organization.id,
        userId: user.id,
        propertyId: property.id,
        role: UserRole.PROPERTY_MASTER,
      });
      return;
    }

    const { uid: firebaseId } = await this.functionsService.registerUser(
      user.email as string,
      `${user.firstName} ${user.lastName}`,
    );

    const propertyMasterUser = await this.usersService.create({
      firebaseId,
      email: user.email,
      firstName: user.firstName,
      lastName: user.lastName,
      phoneNumber: user.phoneNumber,
      type: UserType.CUSTOMER,
      photo: '',
    });

    await this.userOrganizationRoleService.create({
      organizationId: organization.id,
      userId: propertyMasterUser.id,
      propertyId: property.id,
      role: UserRole.PROPERTY_MASTER,
    });
  }

  public showAlert(params: DialogAlertParams) {
    const ref = this.matDialog.open(DialogAlertComponent, {
      hasBackdrop: true,
      disableClose: false,
      data: params,
    });

    return ref;
  }

  public onSaveProperty(event: SavePropertyEvent) {
    console.log({event});

    const alreadyExistsIndex = this.propertiesToSave
      .findIndex(
        (property) => event.id &&
          (
            event.id === property.id 
            // property.property.id === event.property.id
          )
      );

    if (alreadyExistsIndex < 0) {
      this.propertiesToSave.push(cloneDeep(event));
    } else {
      this.propertiesToSave[alreadyExistsIndex] = cloneDeep(event);
    }
    this.propertyToEdit = undefined
    this.shouldCreateProperty = false
  }

  public toggleCreateNewProperty() {
    this.shouldCreateProperty = !this.shouldCreateProperty;
  }

  public discardPropertyForm() {
    this.propertyToEdit = undefined

    this.toggleCreateNewProperty()
  }

  public isMastersTouchedAndValid(event) {
    this.isMastersValid = event
  }

  public editProperty(event: number) {
    this.propertyToEdit = this.propertiesToSave[event]
    this.shouldCreateProperty = true
  }
  public deleteProperty(event: number) {
    if (!this.propertiesToSave[event]) {
      this.showAlert({
        variant: DialogAlertVariant.ERROR,
        title: 'Failed trying to delete property',
        text: "Couldn't find the property to delete",
      });
      return
    }
    const filteredProperties = this.propertiesToSave.filter((_,index) =>
      index !== event
    )
    this.propertiesToSave = filteredProperties

  }

  public get propertyQuantity(){
    return this.propertiesToSave.length > 0  ? `(${this.propertiesToSave.length})` : ''
  }

}
