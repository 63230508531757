import { CommonModule } from "@angular/common";
import { provideHttpClient, withInterceptorsFromDi } from "@angular/common/http";
import { InjectionToken, NgModule } from "@angular/core";

export type Environment = {
  production: boolean;
  apiUrl: string;
  functionsUrl: string;
  arkiqApiUrl: string;
  firebase: {
    apiKey: string;
    authDomain: string;
    projectId: string;
    storageBucket: string;
    messagingSenderId: string;
    appId: string;
  };
};

export const ENVIRONMENT = new InjectionToken<Environment>('ENVIRONMENT');

@NgModule({
  imports: [CommonModule],
  providers: [provideHttpClient(withInterceptorsFromDi())]
  // providers: [{ provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }]
})
export class SdkModule {}
