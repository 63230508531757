import { CommonModule } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
    MAT_DIALOG_DATA,
    MatDialog,
    MatDialogModule,
    MatDialogRef,
} from '@angular/material/dialog';
import {
    Location,
    LocationsService,
    Property,
    PropertyLayoutService,
} from '@arkiq-portals/sdk';
import { take } from 'rxjs';
import {
    ButtonComponent,
    IconButtonComponent,
    InputComponent,
    SelectComponent,
    SelectOption,
    SpinnerComponent,
} from '../../components';
import {
    DialogAlertComponent,
    DialogAlertParams,
    DialogAlertVariant,
} from '../dialog-alert/dialog-alert.component';

export type DialogAddLocationParams = {
  property: Property;
  parentLocationId?: number;
  parentLocationPath?: string;
  loadPropertyLayoutItem?: boolean;
  disableParentLocation?: boolean;
};

@Component({
  selector: 'arkiq-dialog-add-location',
  templateUrl: './dialog-add-location.component.html',
  standalone: true,
  imports: [
    CommonModule,
    MatDialogModule,
    IconButtonComponent,
    InputComponent,
    SelectComponent,
    ButtonComponent,
    SpinnerComponent,
  ],
})
export class DialogAddLocationComponent implements OnInit {
  public loadPropertyLayoutItem = false;

  public isLoading = false;
  public isCreatingLocation = false;

  public form = new FormGroup({
    name: new FormControl('', [Validators.required]),
    parent: new FormControl('', []),
  });

  public locations: Location[] = [];
  public locationsOptions: SelectOption[] = [];

  public disableParentLocation = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: DialogAddLocationParams,
    public dialogRef: MatDialogRef<DialogAddLocationComponent>,
    private locationsService: LocationsService,
    private propertyLayoutService: PropertyLayoutService,
    private matDialog: MatDialog,
  ) {
    this.loadPropertyLayoutItem = !!this.data.loadPropertyLayoutItem;
    this.disableParentLocation = !!this.data.disableParentLocation;
  }

  public ngOnInit(): void {
    if (!this.data.property) {
      this.dialogRef.close();
    }

    this.listLocations();
  }

  public async listLocations(): Promise<void> {
    try {
      this.isLoading = true;

      this.locations = await this.locationsService.listLocationsByProperty(
        this.data.property.id,
      );

      this.locationsOptions = this.buildLocationsOptions();

      if (this.data.parentLocationId) {
        this.form.patchValue({ parent: this.data.parentLocationId.toString() });
      }

      this.isLoading = false;
    } catch (error) {
      console.log(error);

      this.isLoading = false;

      const ref = this.matDialog.open(DialogAlertComponent, {
        hasBackdrop: true,
        disableClose: false,
        data: {
          variant: DialogAlertVariant.ERROR,
          title: 'Unable to fetch property locations',
          text: error,
        } as DialogAlertParams,
      });

      ref
        .afterClosed()
        .pipe(take(1))
        .subscribe(() => this.dialogRef.close());
    }
  }

  public async handleCreateLocation(): Promise<void> {
    if (this.form.invalid) return;

    try {
      this.isCreatingLocation = true;

      const location = await this.locationsService.create({
        name: this.form.value.name,
        parent_id: this.form.value.parent || this.data.property.root_location_id,
        property_id: this.data.property.id,
      } as Partial<Location>);

      await this.propertyLayoutService.create({
        propertyId: this.data.property.id,
        path: this.data.parentLocationPath
          ? `${this.data.parentLocationPath}.${location.id}`
          : `${this.data.property.root_location_id}.${location.id}`
      });

      this.isCreatingLocation = false;

      if (this.loadPropertyLayoutItem) {
        const response = await this.propertyLayoutService.listByPropertyId(
          this.data.property.id,
          this.data.property.root_location_id,
        );

        const propertyLayoutItem = response.data.find(item => item.l_id === location.id);

        this.dialogRef.close({ reload: true, location, propertyLayoutItem });
      } else {
        this.dialogRef.close({ reload: true, location });
      }
    } catch (error) {
      console.log(error);

      this.isCreatingLocation = false;

      const ref = this.matDialog.open(DialogAlertComponent, {
        hasBackdrop: true,
        disableClose: false,
        data: {
          variant: DialogAlertVariant.ERROR,
          title: 'Unable add location',
          text: error,
        } as DialogAlertParams,
      });

      ref
        .afterClosed()
        .pipe(take(1))
        .subscribe(() => this.dialogRef.close());
    }
  }

  public buildLocationsOptions(): SelectOption[] {
    return this.locations.map(location => ({
      label: location.name,
      value: location.id.toString(),
    }));
  }
}
