<div class="flex justify-between items-center px-4 py-2 rounded-xl gap-2">
  @if(alert.photo) {
  <img [src]="alert.photo" alt="" class="size-11 rounded-full" />

  } @else {
  <div class="size-11 rounded-full bg-slate-400"></div>
  }
  <div
    class="flex flex-1 flex-col py-2 justify-between border-b border-b-[#E8E8E8]"
  >
    <p class="text-base font-semibold text-[#121212]">{{ alert.name }}</p>
    <div class="flex items-center gap-2">
      <div class="flex items-center justify-center">
        <span class="text-primary font-normal">{{ alert.leak }}</span>
        <i class="bi bi-droplet text-primary mr-1"></i>
      </div>

      <div class="flex items-center justify-center">
        <span class="text-[#D7A339] font-normal">{{ alert.temperature }}</span>
        <i class="bi bi-fire text-[#D7A339] mr-1"></i>
      </div>

      <div class="flex items-center justify-center">
        <span class="text-[#FF5757] font-normal">{{ alert.tamper }}</span>
        <i class="bi bi-wifi-off text-[#FF5757] mr-1"></i>
      </div>

      <div class="flex items-center justify-center">
        <span class="text-[#FF5757] font-normal">{{ alert.humidity }}</span>
        <i class="bi bi-moisture text-emerald-500 mr-1"></i>
      </div>

      <span class="text-[#FF5757] text-sm font-semibold ml-auto"
        >Total: {{ alert.total }}
      </span>
    </div>
  </div>
</div>
