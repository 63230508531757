<section class="w-full flex justify-center mb-6">
  <arkiq-tabs [tabs]="tabs" />
</section>

@if (selectedDevices.length > 0) {
<section
  class="flex items-center justify-between rounded-lg px-6 py-3 bg-[#FFF] mb-4"
>
  <h4 class="text-base text-[#727272] font-semibold mr-auto]">
    {{ selectedDevices.length }} Device Selected
  </h4>

  <div class="flex items-center gap-2">
    @if(!isInInventoryTab || selectedInventory === INVENTORIES.CUSTOMERS ) {
    <arkiq-button
      variant="outline-primary"
      text="Return"
      size="md"
      [rounded]="false"
      (click)="handleReturnDevices()"
    ></arkiq-button>
    } @if(!isInInventoryTab) {
    <arkiq-button
      variant="primary"
      text="Uninstall"
      size="md"
      [rounded]="false"
      *hasRole="[USER_ROLES.ORGANIZATION_MASTER]"
      (click)="handleUninstallDevices()"
    ></arkiq-button>
    } @if(isInInventoryTab && selectedInventory === INVENTORIES.ARKIQ) {
    <arkiq-button
      variant="danger"
      text="Delete"
      size="md"
      [rounded]="false"
      *hasRole="[USER_ROLES.ORGANIZATION_MASTER]"
      (click)="handleDeleteDevices()"
    ></arkiq-button>

    <arkiq-button
      variant="outline-primary"
      text="Transfer"
      size="md"
      [rounded]="false"
      *hasRole="[USER_ROLES.ORGANIZATION_MASTER]"
      (click)="handleTransferDevices()"
    ></arkiq-button>
    }
  </div>
</section>
} @if (isInInventoryTab) {
<div
  class="flex items-center justify-between rounded-lg px-6 py-3 bg-[#FFF] mb-4"
  *hasRole="[USER_ROLES.ORGANIZATION_MASTER, USER_ROLES.ADMIN]"
>
  <h4 class="text-base text-[#727272] font-semibold">Select Your Inventory</h4>

  <div class="rounded-[5px] overflow-hidden flex">
    <button
      *ngIf="isLoggedUserArkIQAdmin"
      class="py-2 px-4 text-sm font-medium border-y hover:opacity-75 transition-opacity rounded-l-[5px] border-l"
      [ngClass]="[
        selectedInventory === INVENTORIES.ARKIQ
          ? 'bg-primary text-white border-primary'
          : 'bg-white text-[#AAAAAA] border-[#AAAAAA]'
      ]"
      (click)="handleInventoryChange(INVENTORIES.ARKIQ)"
    >
      {{ INVENTORIES.ARKIQ }}
    </button>
    <button
      class="py-2 px-4 text-sm font-medium border-y hover:opacity-75 transition-opacity rounded-r-[5px] border-r"
      [ngClass]="[
        selectedInventory === INVENTORIES.CUSTOMERS
          ? 'bg-primary text-white border-primary'
          : 'bg-white text-[#AAAAAA] border-[#AAAAAA]'
      ]"
      (click)="handleInventoryChange(INVENTORIES.CUSTOMERS)"
    >
      {{ INVENTORIES.CUSTOMERS }}
    </button>
  </div>
</div>
}

<arkiq-table
  (pageChanged)="onTablePageChanged($event)"
  (rowClicked)="onDeviceClicked($event)"
  (rowsLimitChanged)="onTableRowsLimitChanged($event)"
  (searchChanged)="onSearch($event)"
  (selectedRowsChanged)="onTableRowsSelected($event)"
  (sortOrderChanged)="onTableSortChanged($event)"
  [columns]="columns"
  [data]="devices"
  [enableDownloadCsv]="false"
  [enableSearch]="true"
  [isLoading]="isLoading"
  [totalItems]="totalDevices"
  [totalPages]="totalPages"
  label="Device(s)"
  [selectionMode]="selectionMode"
  class="flex-1"
  maxHeightPageClasses="max-h-[calc(100dvh-400px)] md:max-h-[calc(100dvh-323px)]"
/>
