import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { format } from 'date-fns';

@Component({
  standalone: true,
  imports: [CommonModule],
  selector: 'alert-card',
  templateUrl: 'alert-card.component.html',
})
export class AlertCardComponent implements OnInit {
  @Input({ required: true }) date!: Date;
  @Input({ required: true }) address!: string;
  @Input({ required: true }) time!: string;
  @Input({ required: true }) fixture!: string;
  @Input({ required: true }) alertType!: string;

  constructor() {}

  ngOnInit() {}

  public day() {
    return format(this.date, 'dd');
  }

  public month() {
    return format(this.date, 'LLL');
  }
}
