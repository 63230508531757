<ng-container *ngIf="!showAddOrganizationMasterForm">
  <div *ngIf="!selectedUser" class="w-full flex flex-col gap-1">
    <arkiq-input
      type="text"
      [label]="title"
      [placeholder]="placeholder"
      label="Select Master User"
      placeholder="Select Master User"
      [control]="searchControl"
      [rounded]="false"
      size="md"
      [matAutocomplete]="auto"
    />

    <div class="w-full flex items-start justify-between">
      <small [ngClass]="isLoading ? 'text-gray-500' : 'text-white'" class="text-xs">
        Loading users...
      </small>

      <button
        type="button"
        class="underline text-primary font-semibold text-sm hover:opacity-75 transition-opacity"
        (click)="showAddOrganizationMasterForm = true"
      >
        {{ addBtnLabel }}
      </button>
    </div>

    <mat-autocomplete
      (optionSelected)="onUserSelected($event)"
      [autoActiveFirstOption]="true"
      #auto="matAutocomplete"
    >
      <mat-option *ngFor="let user of users" [value]="user.id">
        <div class="w-full flex items-center gap-3">
          <img [src]="user.photo" [alt]="user.id" class="size-10 rounded-full object-cover" />
          <span class="font-bold">{{ user.firstName + ' ' + user.lastName }}</span>
        </div>
      </mat-option>
    </mat-autocomplete>
  </div>

  <div *ngIf="selectedUser" class="w-full flex items-center gap-3 p-2 border border-[#EEEEEE] rounded-[5px] truncate">
    <section class="flex-1 flex items-center gap-3 truncate">
      <img [src]="selectedUser.photo" [alt]="selectedUser.id" class="size-10 rounded-full object-cover" />
      <span class="font-bold truncate">{{ selectedUser.firstName + ' ' + selectedUser.lastName }}</span>
    </section>

    <button
      type="button"
      class="size-7 rounded-full hover:bg-black/5 transition-colors flex items-center justify-center"
      (click)="handleRemoveSelection()"
      matTooltip="Remove selection"
    >
      <i class="bi bi-x-lg text-[16px] text-[#AAAAAA]"></i>
    </button>
  </div>
</ng-container>

<ng-container *ngIf="showAddOrganizationMasterForm">
  <arkiq-add-organization-master-form
    (cancelForm)="showAddOrganizationMasterForm = false"
    (saveForm)="onAddOrganizationMasterFormSaved($event)"
    (resetForm)="handleRemoveSelection()"
    (onFormTouchedAndValid)="onMasterFormTouchedAndValid($event)"
  />
</ng-container>
