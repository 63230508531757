<div class="w-full flex flex-col gap-5">
  <header *ngIf="lock" class="flex items-center justify-end gap-1">
    <arkiq-icon-button
      icon="bi-pencil-square"
      (click)="handleEdit()"
    />

    <arkiq-icon-button
      icon="bi-trash"
      (click)="handleResetForm()"
    />
  </header>

  <form class="w-full grid grid-cols-1 lg:grid-cols-2 gap-3">
    <arkiq-input
      type="text"
      [required]="true"
      label="First Name"
      placeholder="First Name"
      [control]="form.controls.firstName"
      [rounded]="false"
      size="md"
      (keyup.enter)="handleSave()"
    />

    <arkiq-input
      type="text"
      [required]="true"
      label="Last Name"
      placeholder="Last Name"
      [control]="form.controls.lastName"
      [rounded]="false"
      size="md"
      (keyup.enter)="handleSave()"
    />

    <arkiq-input
      type="text"
      [required]="true"
      label="Email Address"
      placeholder="Email Address"
      [control]="form.controls.email"
      [rounded]="false"
      size="md"
      (keyup.enter)="handleSave()"
    />

    <arkiq-input
      type="text"
      [required]="true"
      label="Phone Number"
      placeholder="Phone Number"
      [control]="form.controls.phoneNumber"
      [rounded]="false"
      size="md"
      (keyup.enter)="handleSave()"
    />

    @if (isRoleSelectionShowing) {
      <arkiq-select
        label="Role"
        [required]="true"
        size="md"
        [rounded]="false"
        [options]="roleOptions"
        [control]="form.controls.role"
      />
    }

  </form>

  <footer *ngIf="!lock" class="flex gap-2"
    [ngClass]="{'justify-end': isRoleSelectionShowing}"
  >
    @if(isRoleSelectionShowing) {
      <button
      type="button"
      class="underline text-primary font-semibold text-sm hover:opacity-75 transition-opacity"
      (click)="selectAssignUser()"
    >
      Select Managing User
    </button>

    }
    <arkiq-button
      text="Cancel"
      size="md"
      variant="tertiary"
      [rounded]="false"
      (click)="handleCancel()"
      [ngClass]="{'ml-auto': isRoleSelectionShowing}"
    />

    <arkiq-button
      text="Save"
      size="md"
      variant="primary"
      [rounded]="false"
      [disabled]="form.invalid"
      (click)="handleSave()"
    />
  </footer>
</div>
