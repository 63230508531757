import { Injectable } from '@angular/core';
import { Apollo, gql } from 'apollo-angular';
import { firstValueFrom } from 'rxjs';
import { BuildUserOrganizationRole, UserOrganizationRole } from '../models';

@Injectable({ providedIn: 'root' })
export class UserOrganizationRoleService {
  constructor(private apollo: Apollo) {}

  public async listByUserId(userId: number): Promise<UserOrganizationRole[]> {
    try {
      const response = await firstValueFrom(
        this.apollo.query<{ front_users_organizations_roles: UserOrganizationRole[] }>({
          query: gql`
            query ListUserOrganizationRoles {
              front_users_organizations_roles(where: {userId: {_eq: ${userId}}}, order_by: {id: asc_nulls_first}) {
                id
                organizationId
                propertyId
                role
                userId
              }
            }
        `,
        fetchPolicy: 'no-cache',
        }),
      );

      return response.data.front_users_organizations_roles.map(BuildUserOrganizationRole);
    } catch (error: any) {
      throw new Error(error?.error?.message || error?.message || error);
    }
  }

  public async create(data: Partial<UserOrganizationRole>): Promise<UserOrganizationRole> {
    try {
      if (data.propertyId) {
        const response = await firstValueFrom(
          this.apollo.mutate<{ insert_front_users_organizations_roles_one: UserOrganizationRole }>({
            mutation: gql`
            mutation InsertUserOrganizationRole($userId: Int = ${data.userId}, $role: String = "${data.role}", $propertyId: Int = ${data.propertyId}, $organizationId: Int = ${data.organizationId}) {
              insert_front_users_organizations_roles_one(object: {organizationId: $organizationId, propertyId: $propertyId, role: $role, userId: $userId}) {
                id
                organizationId
                propertyId
                role
                userId
              }
            }
          `,
          }),
        );

        return BuildUserOrganizationRole(response.data?.insert_front_users_organizations_roles_one);
      } else {
        const response = await firstValueFrom(
          this.apollo.mutate<{ insert_front_users_organizations_roles_one: UserOrganizationRole }>({
            mutation: gql`
            mutation InsertUserOrganizationRole($userId: Int = ${data.userId}, $role: String = "${data.role}", $organizationId: Int = ${data.organizationId}) {
              insert_front_users_organizations_roles_one(object: {organizationId: $organizationId, role: $role, userId: $userId}) {
                id
                organizationId
                propertyId
                role
                userId
              }
            }
          `,
          }),
        );

        return BuildUserOrganizationRole(response.data?.insert_front_users_organizations_roles_one);
      }
    } catch (error: any) {
      console.error(error);
      throw new Error(error?.error?.message || error?.message || error);
    }
  }

  public async update(id: number, data: Partial<UserOrganizationRole>): Promise<UserOrganizationRole> {
    try {
      const response = await firstValueFrom(
        this.apollo.mutate<{ update_front_users_organizations_roles: { returning: UserOrganizationRole[] } }>({
          mutation: gql`
            mutation UpdateUser($_eq: Int = ${id}, $userId: Int = ${data.userId}, $role: String = "${data.role}", $propertyId: Int = ${data.propertyId}, $organizationId: Int = ${data.organizationId}) {
              update_front_users_organizations_roles(where: {id: {_eq: $_eq}}, _set: {organizationId: $organizationId, propertyId: $propertyId, role: $role, userId: $userId}) {
                returning {
                  id
                  organizationId
                  propertyId
                  role
                  userId
                }
              }
            }
          `,
        }),
      );

      return BuildUserOrganizationRole(response.data?.update_front_users_organizations_roles.returning[0]);
    } catch (error: any) {
      throw new Error(error?.error?.message || error?.message || error);
    }
  }

  public async delete(id: number): Promise<void> {
    try {
      await firstValueFrom(
        this.apollo.mutate({
          mutation: gql`
            mutation DeleteUserOrganizationRole {
              delete_front_users_organizations_roles(where: {id: {_eq: ${id}}}) {
                returning {
                  id
                }
              }
            }
          `,
        }),
      );
    } catch (error: any) {
      throw new Error(error?.error?.message || error?.message || error);
    }
  }

  public async deleteByUserId(userId: number): Promise<void> {
    try {
      await firstValueFrom(
        this.apollo.mutate({
          mutation: gql`
            mutation DeleteUserOrganizationRole {
              delete_front_users_organizations_roles(where: {userId: {_eq: ${userId}}}) {
                returning {
                  id
                }
              }
            }
          `,
        }),
      );
    } catch (error: any) {
      throw new Error(error?.error?.message || error?.message || error);
    }
  }

  public async deleteManyByUserId(usersIds: number[]): Promise<void> {
    try {
      await firstValueFrom(
        this.apollo.mutate({
          mutation: gql`
            mutation DeleteManyUserOrganizationRoles {
              delete_front_users_organizations_roles(where: {
                id: {
                  _in: [${usersIds.join(',')}]
                }
              }) {
                returning {
                  id
                }
              }
            }
          `,
        }),
      );
    } catch (error: any) {
      throw new Error(error?.error?.message || error?.message || error);
    }
  }

  public async deleteByOrganizationId(organizationId: number): Promise<void> {
    try {
      await firstValueFrom(
        this.apollo.mutate({
          mutation: gql`
            mutation DeleteUserOrganizationRole {
              delete_front_users_organizations_roles(where: {organizationId: {_eq: ${organizationId}}}) {
                returning {
                  id
                }
              }
            }
          `,
        }),
      );
    } catch (error: any) {
      throw new Error(error?.error?.message || error?.message || error);
    }
  }
}
