<arkiq-table
  label="Property(ies)"
  [isLoading]="isLoading"
  [data]="properties"
  [columns]="columns"
  [enableSearch]="true"
  [enableDownloadCsv]="false"
  [buttons]="tableHeaderButtons"
  [totalPages]="totalPages"
  [totalItems]="totalItems"
  (searchChanged)="onSearch($event)"
  (rowClicked)="onPropertyClicked($event)"
  (sortOrderChanged)="onTableSortChanged($event)"
  (pageChanged)="onTablePageChanged($event)"
  (rowsLimitChanged)="onTableRowsLimitChanged($event)"
  maxHeightPageClasses="max-h-[calc(100dvh-360px)] md:max-h-[calc(100dvh-275px)]"
  [enableClearAllFilters]="true"
  [clearFiltersType]="CLEAR_FILTERS_BUTTON_TYPE"

/>
