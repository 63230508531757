import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatMenuModule } from '@angular/material/menu';
import { ButtonComponent } from '@arkiq-portals/ui';

@Component({
  selector: 'app-add-fixture-or-location-button',
  templateUrl: './add-fixture-or-location-button.component.html',
  standalone: true,
  imports: [CommonModule, MatMenuModule, ButtonComponent],
})
export class AddFixtureOrLocationButtonComponent {
  @Output() addFixture = new EventEmitter();
  @Output() addLocation = new EventEmitter();

  public handleAddFixture() {
    this.addFixture.emit();
  }

  public handleAddLocation() {
    this.addLocation.emit();
  }
}
