<div class="rounded-[5px] overflow-hidden flex border border-[#AAAAAA]">
  @for (tab of tabs; track $index) {
    <a
      class="py-2 px-4 text-sm font-medium border-0 hover:opacity-75 transition-opacity"
      [ngClass]="[
        $index === activeTabIndex ? 'bg-primary text-white border-primary' : 'bg-white text-[#AAAAAA] border-[#AAAAAA]',
        $first ? 'rounded-l-[5px] border-l' : '',
        $last ? 'rounded-r-[5px] border-r' : '',
      ]"
      [routerLink]="tab.route"
      *hasRole="tab.allowedRoles ?? ALL_USER_ROLES"
    >
      {{ tab.text }}
    </a>
  }
</div>
