import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import {
    FormControl,
    FormGroup,
    FormsModule,
    ReactiveFormsModule,
} from '@angular/forms';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import {
    Organization,
    UserOrganizationRole,
    UserRole,
    UserRoleLabel,
} from '@arkiq-portals/sdk';
import { DialogCreateOrganizationComponent, IconButtonComponent, SelectComponent, SelectOption } from '@arkiq-portals/ui';
import { distinctUntilChanged, Subscription, take } from 'rxjs';

@Component({
  selector: 'app-user-organization-role-form-line',
  templateUrl: 'user-organization-role-form-line.component.html',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatDialogModule,
    SelectComponent,
    IconButtonComponent,
  ],
})
export class UserOrganizationRoleFormLineComponent implements OnInit, OnDestroy {
  @Input() organizations: Organization[] = [];
  @Input() data?: Partial<UserOrganizationRole>;
  @Input() form!: FormGroup<{
    id: FormControl<string | null>;
    role: FormControl<string | null>;
    organizationId: FormControl<string | null>;
    propertyId: FormControl<string | null>;
  }>;
  @Input() enableRemoveButton = true;

  @Output() addProperty = new EventEmitter<string>();
  @Output() removeLine = new EventEmitter();

  public organizationOptions: SelectOption[] = [];
  public propertyOptions: SelectOption[] = [];

  public roleOptions!: SelectOption[];

  private subscriptions = new Subscription();

  constructor(
    private matDialog: MatDialog,
  ) {}

  public ngOnInit(): void {
    this.selectRoleBasedOnUserType();
    this.organizationOptions = this.organizations
      // .filter(organization => organization.properties?.length)
      .map(organization => ({
        label: organization.name,
        value: String(organization.id),
      }));

    if (this.form.value.organizationId) {
      this.buildPropertiesOptions(this.form.value.organizationId);
    }

    this.subscriptions.add(
      this.form.controls.organizationId.valueChanges
        .pipe(distinctUntilChanged())
        .subscribe(
          organizationId => {
            if (organizationId) {
              this.buildPropertiesOptions(organizationId);
            } else {
              this.propertyOptions = [
                { label: 'Select', value: '' },
              ];
            }
          },
        ),
    );
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  public async selectRoleBasedOnUserType() {
    this.roleOptions = [UserRole.ORGANIZATION_MASTER,UserRole.PROPERTY_MASTER,UserRole.CUSTOMER]
      .map(role => {
        return {
          label: UserRoleLabel[role],
          value: role,
        };
      });
  }

  public handleAddOrganization(): void {
    const ref = this.matDialog.open(
      DialogCreateOrganizationComponent,
      {
        disableClose: false,
        hasBackdrop: true,
      },
    );

    ref
      .afterClosed()
      .pipe(take(1))
      .subscribe(
        data => {
          if (data && data.organization) {
            this.organizations.push(data.organization);

            this.organizationOptions.push({
              label: data.organization.name,
              value: data.organization.id,
            });

            this.form.patchValue({ organizationId: data.organization.id });
          }
        },
      );
  }

  public handleAddProperty(): void {
    if (!this.form.value.organizationId) {
      return;
    }

    this.addProperty.emit(this.form.value.organizationId);
  }

  public handleRemoveLine(): void {
    this.removeLine.emit();
  }

  private buildPropertiesOptions(organizationId: string): void {
    const selectedOrganizationId = Number(organizationId);
    const organization = this.organizations.find(
      organization => organization.id === selectedOrganizationId,
    );

    const properties =
      organization?.properties?.map(property => ({
        label: property?.name ?? '',
        value: String(property?.id) ?? '',
      })) ?? [];

    this.propertyOptions = properties;

    // this.form.controls.propertyId.setValue('');
  }
}
