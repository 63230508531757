<div class="w-full h-full flex flex-col">
  <header
    class="w-full px-10 md:px-16 lg:px-28 2xl:px-44 py-8 md:py-14 flex flex-col gap-11 md:gap-14"
  >
    <!-- <arkiq-auth-steps /> -->

    <div class="w-full flex flex-col gap-5 items-center">
      <h1 class="m-0 font-semibold text-black text-4xl text-center">
        Forgot Password
      </h1>

      <p class="m-0 text-base text-[#9B9B9B] text-center">
        Please enter the email address associated with your account. We will
        send you instructions to reset your password.
      </p>
    </div>
  </header>

  <main
    class="flex-1 items-center w-full px-14 md:px-40 lg:px-44 2xl:px-56 pb-14"
  >
    <form class="w-full flex flex-col gap-32">
      <arkiq-input
        label="Email Address"
        placeholder="Your email address"
        [control]="email"
        size="lg"
      />

      <arkiq-button
        text="Continue"
        [disabled]="email.invalid"
        [loading]="isLoading"
        (click)="handleForgotPassword()"
      />
    </form>
  </main>

  <footer class="flex items-center justify-center gap-2 pb-14">
    <span class="font-medium text-sm">Go back to</span>
    <a
      routerLink="/auth/sign-in"
      class="font-medium text-sm text-primary hover:underline"
      >Sign in</a
    >
  </footer>
</div>
