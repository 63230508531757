import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  AddOrganizationMasterFormComponent,
  ButtonComponent,
  InputComponent,
  SelectComponent,
  SelectOption,
} from '@arkiq-portals/ui';
import {
  MatAutocomplete,
  MatAutocompleteSelectedEvent,
  MatOption,
} from '@angular/material/autocomplete';
import { MatTooltip } from '@angular/material/tooltip';
import {
  User,
  UserRole,
  UserRoleLabel,
  UsersService,
} from '@arkiq-portals/sdk';
import { FormControl } from '@angular/forms';
import { debounceTime, distinctUntilChanged, Subscription } from 'rxjs';

export type ManagingUser = {
  user: User | Partial<User>;
  role: UserRole;
};

@Component({
  selector: 'app-select-property-managing-user',
  templateUrl: './select-property-managing-user.component.html',
  standalone: true,
  imports: [
    CommonModule,
    AddOrganizationMasterFormComponent,
    InputComponent,
    MatAutocomplete,
    MatOption,
    MatTooltip,
    SelectComponent,
    ButtonComponent,
  ],
})
export class SelectPropertyManagingUserComponent implements OnInit, OnDestroy {
  @Input() initialValue?: ManagingUser;

  @Output() assignUser = new EventEmitter<ManagingUser>();
  @Output() isManageUserValid = new EventEmitter<boolean>();
  @Output() showAssignUsers = new EventEmitter();

  public searchControl = new FormControl('');
  public roleControl = new FormControl('');

  public isLoading = false;
  public users: User[] = [];

  private subscription = new Subscription();

  public showAddManagingUserForm = false;

  public selectedUser?: User;

  constructor(private usersService: UsersService) {}

  public ngOnInit(): void {
    this.selectRoleBasedOnUserType();
    this.searchUsers();

    this.subscription.add(
      this.searchControl.valueChanges
        .pipe(distinctUntilChanged(), debounceTime(500))
        .subscribe(value => {
          this.searchUsers(value ?? '');
        }),
    );
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  private async searchUsers(search?: string) {
    try {
      this.isLoading = true;

      const response = await this.usersService.list({ search, limit: 999 });
      this.users = response.users;

      if (this.initialValue) {
        this.selectedUser = this.initialValue.user as User;
        this.roleControl.setValue(this.initialValue.role);

        this.assignUser.emit(this.initialValue);
      }

      this.isLoading = false;
    } catch (error) {
      this.isLoading = false;
      console.error(error);
    }
  }

  public onUserSelected(event: MatAutocompleteSelectedEvent) {
    const user = this.users.find(u => u.id === event.option.value);

    if (user) {
      this.selectedUser = user;
    } else {
      this.searchControl.setValue('');
    }
  }

  public onAddManagingUserFormSaved(event: Partial<User>) {

    if (!event.role) {
      return;
    }

    this.assignUser.emit({
      user: event,
      role: event.role,
    });

    this.showAddManagingUserForm = false
  }

  public handleRemoveSelection() {
    this.selectedUser = undefined;
    this.roleControl.setValue('');
    this.searchControl.setValue('');
  }

  public handleAssign() {
    if (!this.selectedUser || !this.roleControl.value) {
      return;
    }

    this.assignUser.emit({
      user: this.selectedUser,
      role: this.roleControl.value as UserRole,
    });

    this.handleRemoveSelection();
  }

  public async selectRoleBasedOnUserType() {
    this.roleOptions =
      [UserRole.ORGANIZATION_MASTER,UserRole.PROPERTY_MASTER].map(role => {
        return {
          label: UserRoleLabel[role],
          value: role,
        };
      });
  }

  public handleCancel() {
    this.showAssignUsers.emit()
  }

  public roleOptions!: SelectOption[];

  public isMastersTouchedAndValid(event) {
    this.isManageUserValid.emit(event)
  }
}
