<div class="w-full h-full flex flex-col">
  <header
    class="w-full px-10 md:px-16 lg:px-28 2xl:px-44 py-8 md:py-14 flex flex-col gap-11 md:gap-14"
  >
    <!-- <arkiq-auth-steps /> -->

    <div class="w-full flex flex-col gap-5 items-center">
      <h1 class="m-0 font-semibold text-black text-4xl text-center">
        Recovery Email
      </h1>
    </div>
  </header>

  <main
    class="flex-1 items-center w-full px-14 md:px-40 lg:px-44 2xl:px-56 pb-14"
  >
    <p class="m-0 text-base text-[#9B9B9B] text-center">
      We have sent you an email with instructions to reset your password.
    </p>
    <p class="m-0 text-base text-[#9B9B9B] text-center">
      Please check your inbox and follow the link provided to create a new
      password.
    </p>
  </main>

  <footer class="flex items-center justify-center gap-2 pb-14">
    <arkiq-button
      text="Back to login"
      size="md"
      variant="outline-primary"
      [rounded]="false"
      (click)="handleGoBack()"
    />
  </footer>
</div>
