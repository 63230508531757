import { CommonModule } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { MatTooltipModule } from '@angular/material/tooltip';
import {
  Fixture,
  FixturesService,
  Location,
  LocationWithFixtureCounter,
  LocationsService,
  Property,
  PropertyLayoutTreeItem,
  UserRole,
} from '@arkiq-portals/sdk';
import {
  ButtonComponent,
  DialogAddFixtureComponent,
  DialogAddFixtureParams,
  DialogAddLocationComponent,
  DialogAddLocationParams,
  DialogAlertComponent,
  DialogAlertParams,
  DialogAlertVariant,
  DialogAssignDeviceComponent,
  DialogAssignDeviceParams,
  DialogConfirmActionComponent,
  HasRoleDirective,
  IconButtonComponent,
  SpinnerComponent,
  TableColumn,
  TableColumnType,
  TableComponent,
  TableHeaderButton,
  TableRowClickedEvent,
} from '@arkiq-portals/ui';
import { take } from 'rxjs';
import { AddFixtureOrLocationButtonComponent } from '../add-fixture-or-location-button/add-fixture-or-location-button.component';

@Component({
  selector: 'app-property-details-node-details',
  templateUrl: './property-details-node-details.component.html',
  standalone: true,
  imports: [
    CommonModule,
    MatTooltipModule,
    MatDialogModule,
    ButtonComponent,
    IconButtonComponent,
    TableComponent,
    AddFixtureOrLocationButtonComponent,
    HasRoleDirective,
    SpinnerComponent,
  ],
})
export class PropertyDetailsNodeDetailsComponent implements OnChanges {
  @Input({ required: true }) property!: Property;
  @Input() selectedNodePath: PropertyLayoutTreeItem[] = [];

  @Output() reload = new EventEmitter();
  @Output() navigateToSubLocation = new EventEmitter<Location>();

  public USER_ROLES = UserRole;

  public isLoading = false;

  public location!: LocationWithFixtureCounter ;

  public isFixturesCollapsed = false;
  public isSubLocationsCollapsed = false;

  public subLocationsTableData: Location[] = [];
  public fixturesTableData: Fixture[] = [];

  public allFixtures: Fixture[] = [];

  public subLocationsTableColumns: TableColumn<Location>[] = [
    {
      type: TableColumnType.TEXT,
      label: 'Name',
      valueFn: item => item.name,
      valueClasses: 'text-left',
      headerClasses: 'text-left',
    },
    {
      type: TableColumnType.TEXT,
      label: 'Total',
      valueFn: node => (node.fixtures ? String(node.fixtures.length) : '0'),
      valueClasses: 'text-center',
      headerClasses: 'text-center justify-center',
    },
    {
      type: TableColumnType.TEXT,
      label: 'Protected',
      valueFn: node =>
        node.fixtures
          ? String(node.fixtures.filter(fixture => !!fixture.device_id).length)
          : '0',
      valueClasses: 'text-center',
      headerClasses: 'text-center justify-center',
    },
    {
      type: TableColumnType.TEXT,
      label: 'Unprotected',
      valueFn: node =>
        node.fixtures
          ? String(node.fixtures.filter(fixture => !fixture.device_id).length)
          : '0',
      valueClasses: 'text-center',
      headerClasses: 'text-center justify-center',
    },
    // {
    //   type: TableColumnType.ACTIONS,
    //   label: 'Actions',
    //   allowedRoles: [UserRole.ORGANIZATION_MASTER],
    //   actionButtons: [
    //     {
    //       type: 'icon-button',
    //       tooltip: 'Edit',
    //       icon: 'bi-pencil-square',
    //       handler: data => {},
    //     },
    //     {
    //       type: 'icon-button',
    //       tooltip: 'Copy',
    //       icon: 'bi-copy',
    //       handler: data => {},
    //     },
    //   ],
    // },
  ];

  public subLocationsTableHeaderButtons: TableHeaderButton[] = [
    // {
    //   type: 'default-button',
    //   text: 'Move',
    //   icon: 'bi-arrows-move',
    //   handler: () => {},
    //   variant: 'outline',
    //   allowedRoles: [UserRole.ORGANIZATION_MASTER],
    // },
    // {
    //   type: 'default-button',
    //   text: 'Delete',
    //   icon: 'bi-trash',
    //   handler: () => {},
    //   variant: 'outline',
    //   allowedRoles: [UserRole.ORGANIZATION_MASTER],
    // },
    // {
    //   type: 'default-button',
    //   text: 'Undo',
    //   icon: 'bi-arrow-counterclockwise',
    //   handler: () => {},
    //   variant: 'outline',
    //   allowedRoles: [UserRole.ORGANIZATION_MASTER],
    // },
    // {
    //   type: 'default-button',
    //   text: 'Redo',
    //   icon: 'bi-arrow-clockwise',
    //   handler: () => {},
    //   variant: 'outline',
    //   allowedRoles: [UserRole.ORGANIZATION_MASTER],
    // },
    {
      type: 'icon-button',
      tooltip: 'Hide',
      icon: 'bi-chevron-up',
      handler: () => (this.isSubLocationsCollapsed = true),
      variant: 'outline',
    },
  ];

  public fixturesTableHeaderButtons: TableHeaderButton[] = [
    // {
    //   type: 'default-button',
    //   text: 'Move',
    //   icon: 'bi-arrows-move',
    //   handler: () => {},
    //   variant: 'outline',
    //   allowedRoles: [UserRole.ORGANIZATION_MASTER],
    // },
    // {
    //   type: 'default-button',
    //   text: 'Delete',
    //   icon: 'bi-trash',
    //   handler: () => {},
    //   variant: 'outline',
    //   allowedRoles: [UserRole.ORGANIZATION_MASTER],
    // },
    // {
    //   type: 'default-button',
    //   text: 'Undo',
    //   icon: 'bi-arrow-counterclockwise',
    //   handler: () => {},
    //   variant: 'outline',
    //   allowedRoles: [UserRole.ORGANIZATION_MASTER],
    // },
    // {
    //   type: 'default-button',
    //   text: 'Redo',
    //   icon: 'bi-arrow-clockwise',
    //   handler: () => {},
    //   variant: 'outline',
    //   allowedRoles: [UserRole.ORGANIZATION_MASTER],
    // },
    {
      type: 'icon-button',
      tooltip: 'Hide',
      icon: 'bi-chevron-up',
      handler: () => (this.isFixturesCollapsed = true),
      variant: 'outline',
    },
  ];

  public fixturesTableColumns: TableColumn<Fixture>[] = [
    {
      type: TableColumnType.TEXT,
      label: 'Name',
      valueFn: item => item.name,
      valueClasses: 'text-left',
      headerClasses: 'text-left',
    },
    {
      type: TableColumnType.TEXT,
      label: 'Type',
      valueFn: item => item.type,
      valueClasses: 'text-center',
      headerClasses: 'text-center justify-center',
    },
    {
      type: TableColumnType.TEXT,
      label: 'Device ID',
      valueFn: item => item.device_id ?? '-',
      valueClasses: 'text-center',
      headerClasses: 'text-center justify-center',
    },
    {
      type: TableColumnType.BADGE,
      label: 'Status',
      valueFn: item => item.status.toUpperCase(),
      badge: item => ({
        icon: 'bi-dot',
        badgeStatus: item.status.toUpperCase(),
      }),
      valueClasses: 'text-center',
      headerClasses: 'text-center justify-center',
    },
    {
      type: TableColumnType.ACTIONS,
      label: 'Assign Device',
      headerClasses: 'text-center flex justify-center',
      actionButtons: [
        {
          type: 'default-button',
          text: 'Assign Device',
          variant: 'primary',
          handler: data => this.handleAssignDevice(data),
        },
        {
          type: 'icon-button',
          tooltip: 'Edit Fixture',
          icon: 'bi-pencil-square',
          handler: data => this.handleEditFixture(data),
        },
        {
          type: 'icon-button',
          tooltip: 'Delete Fixture',
          icon: 'bi-trash',
          handler: data => this.handleDeleteFixture(data),
        },
      ],
    },
    // {
    //   type: TableColumnType.ACTIONS,
    //   label: 'Actions',
    //   actionButtons: [
    //     {
    //       type: 'default-button',
    //       text: 'Assign Device',
    //       variant: 'primary',
    //       handler: data => this.handleAssignDevice(data),
    //     },
    //   ],
    // },
  ];

  constructor(
    private matDialog: MatDialog,
    private locationsService: LocationsService,
    private fixturesService: FixturesService,
  ) {}

  public ngOnChanges(changes: SimpleChanges) {
    if (changes['selectedNodePath']?.currentValue.length) {
      this.loadLocationAndFixtures();
    }
  }

  public async loadLocationAndFixtures() {
    try {
      this.isLoading = true;

      const locationId = this.selectedNodePath.at(-1)?.l_id as number;

      const [location, subLocations, fixtures] = await Promise.all([
        this.locationsService.getById(locationId),
        this.locationsService.listLocationsByParentId(locationId),
        this.fixturesService.listByLocationId(locationId),
      ]);

      const allSublocationsIds = this.getCurrentAndChidrenLocationsId();
      const allFixtures = await this.fixturesService.listByManyLocationsIds(allSublocationsIds);
      this.allFixtures = allFixtures;

      this.location = location;
      this.subLocationsTableData = subLocations;
      this.fixturesTableData = fixtures;
      this.isLoading = false;
    } catch (error) {
      this.isLoading = false;

      console.error(error);

      this.matDialog.open(DialogAlertComponent, {
        hasBackdrop: true,
        disableClose: false,
        data: {
          variant: DialogAlertVariant.ERROR,
          title: 'Unable to fetch fixtures',
          text: error,
        } as DialogAlertParams,
      });
    }
  }

  public handleAssignDevice(fixture: Fixture) {
    const ref = this.matDialog.open(DialogAssignDeviceComponent, {
      hasBackdrop: true,
      disableClose: false,
      data: { fixture } as DialogAssignDeviceParams,
    });

    ref
      .afterClosed()
      .pipe(take(1))
      .subscribe(data => {
        if (data && data.deviceId) {
          fixture.device_id = data.deviceId;
        }
      });
  }

  public handleAddLocation() {
    const ref = this.matDialog.open(DialogAddLocationComponent, {
      hasBackdrop: true,
      disableClose: false,
      data: {
        property: this.property,
        parentLocationId: this.selectedNodePath.at(-1)?.l_id,
        parentLocationPath: this.selectedNodePath.at(-1)?.pl_path,
        loadPropertyLayoutItem: true,
      } as DialogAddLocationParams,
    });

    ref
      .afterClosed()
      .pipe(take(1))
      .subscribe(data => {
        if (data && data.propertyLayoutItem && this.selectedNodePath?.length) {
          this.selectedNodePath[this.selectedNodePath.length - 1].children.push(
            {
              ...data.propertyLayoutItem,
              isCollapsed: true,
              children: [],
            },
          );
        }

        if (data && data.location) {
          this.subLocationsTableData = [
            ...this.subLocationsTableData,
            data.location,
          ];
        }
      });
  }

  public handleEditFixture(fixtureToEdit: Fixture) {
    const ref = this.matDialog.open(DialogAddFixtureComponent, {
      hasBackdrop: true,
      disableClose: false,
      data: {
        propertyId: this.location?.property_id,
        locationId: this.location?.id,
        fixture: fixtureToEdit,
      } as DialogAddFixtureParams,
    });

    ref
      .afterClosed()
      .pipe(take(1))
      .subscribe(data => {
        if (data && data.fixture) {
          this.fixturesTableData = this.fixturesTableData.map(fixture =>
            fixture.id === fixtureToEdit.id ? data.fixture : fixture,
          );

          if (this.isFixturesCollapsed) {
            this.isFixturesCollapsed = false;
          }
        }
      });
  }

  public handleAddFixture() {
    const ref = this.matDialog.open(DialogAddFixtureComponent, {
      hasBackdrop: true,
      disableClose: false,
      data: {
        propertyId: this.location?.property_id,
        locationId: this.location?.id,
      } as DialogAddFixtureParams,
    });

    ref
      .afterClosed()
      .pipe(take(1))
      .subscribe(data => {
        if (data && data.fixture) {
          this.fixturesTableData = [...this.fixturesTableData, data.fixture];

          if (this.isFixturesCollapsed) {
            this.isFixturesCollapsed = false;
          }
        }
      });
  }

  public handleDeleteFixture(fixture: Fixture) {
    const ref = this.matDialog.open(DialogConfirmActionComponent, {
      hasBackdrop: true,
      disableClose: false,
      data: {
        title: 'Are you sure?',
        text: `Are you sure you want to delete this fixture '${fixture.name}'?`,
      },
    });

    ref
      .afterClosed()
      .pipe(take(1))
      .subscribe(async data => {
        if (data.confirm) {
          this._deleteFixture(fixture.id);
        }
      });
  }

  private async _deleteFixture(fixtureId: number) {
    try {
      await this.fixturesService.delete(fixtureId);

      this.fixturesTableData = this.fixturesTableData.filter(
        fixture => fixture.id !== fixtureId,
      );
    } catch (error) {
      console.error(error);
      alert(error);
    }
  }

  public onSubLocationClicked(event: TableRowClickedEvent<Location>) {
    this.navigateToSubLocation.emit(event.data);
  }

  public getCurrentAndChidrenLocationsId(item = this.selectedNodePath.at(-1)) {
    if (!item) {
      return [];
    }

    const ids: number[] = [];

    if (item.children && item.children.length) {
      for (const child of item.children) {
        const childIds = this.getCurrentAndChidrenLocationsId(child);
        ids.push(...childIds);
      }
    }

    ids.push(item.l_id);

    return ids;
  };

  public get compactSelectedNodePathLabels() {
    if (this.selectedNodePath.length > 2) {
      return [
        this.selectedNodePath[0].l_name,
        '...',
        this.selectedNodePath[this.selectedNodePath.length - 1].l_name,
      ];
    }

    return this.selectedNodePath.map(node => node.l_name);
  }

  public get fullSelectedNodePathLabels() {
    return this.selectedNodePath.map(node => node.pl_id);
  }

  // public get subLocationsTableData() {
  //   return (
  //     this.selectedNodePath
  //       .at(-1)
  //       ?.children.filter(item => !item.pl_is_fixture) ?? []
  //   );
  // }

  public get subLocationsWithoutDeviceId() {
    return this.subLocationsTableData.reduce((acc, curr) => {
      acc += curr.fixtures?.filter(fixture => !fixture.device_id).length ?? 0;
      return acc;
    }, 0);
  }

  public get fixturesWithoutDeviceId() {
    return this.allFixtures.filter(item => !item.device_id).length;
  }

  public get totalFixtures() {
    return this.allFixtures.length ?? 0;
  }

  public get totalProtectedFixtures() {
    return this.allFixtures.filter(item => item.device_id).length;
  }

  public get totalUnprotectedFixtures() {
    const count = this.fixturesWithoutDeviceId
    if (count == 0) {
      return '0 (0%)';
    }

    const percentage = Math.floor((count / this.totalFixtures) * 100);

    return `${count} (${percentage}%)`;
  }

  public get addLocationText() {
    return 'Sub-Location';
  }
}
