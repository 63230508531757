@if (isLoading) {
  <div class="w-full h-full flex items-center justify-center">
    <arkiq-spinner />
  </div>
} @else {
  <div class="w-full bg-white rounded-xl flex flex-col">
    <header class="flex items-center gap-2 py-5 px-6">
      <arkiq-icon-button icon="bi-chevron-left" [disabled]="isSaving" (click)="handleGoBack()" />

      <h2 class="m-0 font-bold text-base">Transfer Devices</h2>
    </header>

    <section class="border-y border-y-black/15 p-6 gap-5 flex flex-col">
      <h2 class="text-lg font-bold text-black">Select Destination</h2>
      <div class="flex gap-5 items-end flex-wrap">
        <arkiq-select
          label="Select Organization"
          [required]="true"
          size="md"
          [rounded]="false"
          [control]="form.controls.organization"
          [options]="organizationOptions"
          [disabled]="isSaving"
          class="flex-1 min-w-[180px]"
        />

        @if (isLoadingProperties) {
          <div class="flex-1 flex items-center justify-center gap-2">
            <arkiq-spinner />
            <p>Loading properties...</p>
          </div>
        } @else {
          <arkiq-select
            label="Select Property"
            [required]="true"
            size="md"
            [rounded]="false"
            [control]="form.controls.property"
            [options]="propertyOptions"
            [disabled]="!form.value.organization || isSaving"
            class="flex-1 min-w-[180px]"
          />
        }
      </div>
    </section>

    <section
      class="flex flex-col p-6 gap-6 border-b border-b-black/15"
      [ngClass]="{ 'gap-6': selectedDevices.length > 0 }"
    >
      <h2 class="text-lg font-bold text-black">
        Total {{ selectedDevices.length }} Devices Selected
      </h2>

      <div class="flex gap-4">
        @for (device of selectedDevices; track $index) {
          <span class="p-2 rounded-md text-primary border-primary border">
            #{{ device.id }}
          </span>
        }
      </div>
    </section>

    <footer class="flex justify-between px-6 py-4">
      <arkiq-button
        variant="tertiary"
        text="Cancel"
        size="md"
        [rounded]="false"
        [disabled]="isSaving"
        (click)="handleGoBack()"
      />

      <arkiq-button
        variant="primary"
        text="Transfer"
        size="md"
        [rounded]="false"
        [loading]="isSaving"
        [disabled]="isTransferButtonDisabled || isSaving"
        (click)="handleTransferTo()"
      />
    </footer>
  </div>
}
