<div class="flex items-center gap-3">
  <button
    *ngIf="!userPhoto"
    class="size-[42px] rounded-full bg-[#C4C4C4]"
    (click)="$event.stopPropagation()"
    [matMenuTriggerFor]="logoutDropdown"
  ></button>
  <button>
    <img
      *ngIf="userPhoto"
      [src]="userPhoto"
      [alt]="userName"
      class="size-[42px] rounded-full object-cover"
      (click)="$event.stopPropagation()"
      [matMenuTriggerFor]="logoutDropdown"
    />
  </button>

  <div class="flex flex-col gap-1">
    <p class="m-0 font-medium text-xs text-[#171B1E]">{{ userName }}</p>
    <p class="m-0 text-[8px] text-[#717579]">{{ userEmail }}</p>
  </div>
</div>

<mat-menu #logoutDropdown>
  <ng-template matMenuContent>
    <button
      type="button"
      class="w-full flex items-center gap-2 p-2 py-4 hover:bg-gray-100 transition-all"
      (click)="handleSignOut()"
    >
      <span>Logout</span>
    </button>
  </ng-template>
</mat-menu>
