import { Injectable } from '@angular/core';
import {
  BuildProperty,
  LocationsService,
  Property,
  PropertyLayoutService,
  PropertyType,
  SortOrder,
  UsersService,
} from '@arkiq-portals/sdk';
import { Apollo, gql } from 'apollo-angular';
import { firstValueFrom } from 'rxjs';

export type ListPropertiesParams = {
  search?: string | null;
  orderBy?: string;
  order?: SortOrder;
  limit?: number | null;
  page?: number | null;
  type?: string[] | null;
  organizationId?: number[] | null;
  city?: string[] | null;
  state?: string[] | null;
  propertiesIds?: Array<string| number>
};

export type PropertyWithDeviceCount = {
  id: number;
  devices_aggregate: {
    aggregate: {
      count: number;
    };
  };
};

export type ListPropertiesWithDeviceCountResponse = {
  nodes: Array<PropertyWithDeviceCount>;
};

export type ListPropertiesResponse = {
  properties: Property[];
  totalItems: number;
};

export type ListPropertiesQueryResult = {
  front_properties: Property[];
  front_properties_aggregate: {
    aggregate: {
      count: number;
    };
  };
};

export type PropertyDeviceAndUserByOrganizationParams = {
  organizationId: number;
  city?: string[];
  state?: string[];
  propertyType?: PropertyType[];
  search?:string;
}

export type PropertyAndUserByOrganizationResult = Property & {
  device_count: number;
  users_count: number;
}
export type PropertyAndUserByOrganizationQueryResult = Property & {
  device_count: {
    aggregate: {
      count: number;
    }
  };
  users_count: {
    aggregate: {
      count: number;
    }
  }
}

@Injectable({ providedIn: 'root' })
export class PropertiesService {
  constructor(
    private apollo: Apollo,
    private usersService: UsersService,
    private locationsService: LocationsService,
    private propertyLayoutService: PropertyLayoutService,
  ) {}

  public async getById(
    propertyId: number,
    forceRefresh = false,
  ): Promise<Property> {
    try {
      const response = await firstValueFrom(
        this.apollo.query<{ front_properties: Property[] }>({
          query: gql`
            query ListProperties {
              front_properties(where: {id: {_eq: ${propertyId}}}) {
                id
                name
                type
                address_street
                address_city
                address_state
                address_zip_code
                photo
                organizationId
                created_at
                updated_at
                root_location_id
                organization {
                  id
                  name
                  created_at
                  updated_at
                }
                users_organizations_roles {
                  id
                  organizationId
                  userId
                  role
                  propertyId
                  organization {
                    id
                    name
                    created_at
                    updated_at
                  }
                  user {
                    id
                    firstName
                    lastName
                    email
                    phoneNumber
                    photo
                    firebaseId
                    type
                    role
                    created_at
                    updated_at
                  }
                }
              }
            }
        `,
        fetchPolicy: 'no-cache',
        }),
      );
      return BuildProperty(response.data.front_properties[0]);
    } catch (error: any) {
      throw new Error(error?.error?.message || error?.message || error);
    }
  }

  public async create(data: Partial<Property>) {
    try {
      const response = await firstValueFrom(
        this.apollo.mutate<{ insert_front_properties_one: Property }>({
          mutation: gql`
            mutation InsertProperty(
              $name: String = "${data.name}",
              $type: String = "${data.type}",
              $address_street: String = "${data.address_street}",
              $address_city: String = "${data.address_city}",
              $address_state: String = "${data.address_state}",
              $address_zip_code: String = "${data.address_zip_code}",
              $organizationId: Int = ${data.organizationId},
              $photo: String = "${data.photo ?? ''}"
            ) {
              insert_front_properties_one(
                object: {
                  name: $name,
                  type: $type,
                  address_street: $address_street,
                  address_city: $address_city,
                  address_state: $address_state,
                  address_zip_code: $address_zip_code,
                  organizationId: $organizationId,
                  photo: $photo
                }
              ) {
                id
                name
                organizationId
                type
                address_street
                address_city
                address_state
                address_zip_code
                root_location_id
                created_at
                updated_at
              }
            }
          `,
        }),
      );
      const property = BuildProperty(response.data?.insert_front_properties_one);

      const propertyRootLocation = await this.locationsService.create({
        name: property.name,
        property_id: property.id,
      });

      await this.propertyLayoutService.create({
        propertyId: property.id,
        path: String(propertyRootLocation.id),
      });
      await this.updatePropertyRootLocationId(
        property.id,
        propertyRootLocation.id,
      );

      return property;
    } catch (error: any) {
      throw new Error(error?.error?.message || error?.message || error);
    }
  }

  public async update(propertyId: number, data: Partial<Property>) {
    try {
      const response = await firstValueFrom(
        this.apollo.mutate<{
          update_front_properties: { returning: Property[] };
        }>({
          mutation: gql`
            mutation UpdateProperty(
              $_eq: Int = ${propertyId},
              $name: String = "${data.name}",
              $type: String = "${data.type}",
              $address_street: String = "${data.address_street}",
              $address_city: String = "${data.address_city}",
              $address_state: String = "${data.address_state}",
              $address_zip_code: String = "${data.address_zip_code}",
              $organizationId: Int = ${data.organizationId},
              $photo: String = "${data.photo ?? ''}"
            ) {
              update_front_properties(
                where: {id: {_eq: $_eq}},
                _set: {
                  name: $name,
                  type: $type,
                  address_street: $address_street,
                  address_city: $address_city,
                  address_state: $address_state,
                  address_zip_code: $address_zip_code,
                  organizationId: $organizationId,
                  photo: $photo
                }
              ) {
                returning {
                  id
                  address_street
                  address_city
                  address_state
                  address_zip_code
                  created_at
                  name
                  type
                  updated_at
                  organizationId
                  root_location_id
                }
              }
            }
          `,
        }),
      );

      return BuildProperty(response.data?.update_front_properties.returning[0]);
    } catch (error: any) {
      throw new Error(error?.error?.message || error?.message || error);
    }
  }

  public async updatePropertyRootLocationId(propertyId: number, propertyRootLocationId: number) {
    try {
      const response = await firstValueFrom(
        this.apollo.mutate<{
          update_front_properties: { returning: Property[] };
        }>({
          mutation: gql`
            mutation UpdateProperty($_eq: Int = ${propertyId}, $root_location_id: Int = ${propertyRootLocationId}) {
              update_front_properties(where: {id: {_eq: $_eq}}, _set: {root_location_id: $root_location_id}) {
                returning {
                  id
                  address_street
                  address_city
                  address_state
                  address_zip_code
                  created_at
                  name
                  type
                  updated_at
                  organizationId
                  root_location_id
                }
              }
            }
          `,
        }),
      );

      return BuildProperty(response.data?.update_front_properties.returning[0]);
    } catch (error: any) {
      throw new Error(error?.error?.message || error?.message || error);
    }
  }

  public async deleteByOrganizationId(organizationId: number): Promise<void> {
    try {
      await firstValueFrom(
        this.apollo.mutate({
          mutation: gql`
            mutation DeleteProperties {
              delete_front_properties(where: {organizationId: {_eq: ${organizationId}}}) {
                returning {
                  id
                }
              }
            }
          `,
        }),
      );
    } catch (error: any) {
      throw new Error(error?.error?.message || error?.message || error);
    }
  }

  public async list(params?: ListPropertiesParams): Promise<ListPropertiesResponse> {
    const orderBy = params?.orderBy || 'created_at';
    const order = params?.order || 'desc_nulls_last';

    const limit = params?.limit ?? 10;
    const page = params?.page ?? 1;
    const search = params?.search ?? ""
    const loggedUserProperties = this.usersService.loggedUserProperties
    const propertiesIds  = params && params.propertiesIds ? params.propertiesIds : []

    const offset = (page - 1) * limit;

    try {
      const isUserCustomer = this.usersService.isLoggedUserCustomer;

      if (
        this.usersService.loggedUserProperties.length === 0 &&
        isUserCustomer
      ) {
        return {
          properties: [],
          totalItems: 0,
        };
      }

      const organizationsQuery =
        params?.['organizationId']
          ?.map(organizationId => '"' + organizationId + '"')
          .join(',') ?? '';


      const filteredPropertiesIds = propertiesIds.filter(id => loggedUserProperties.includes(Number(id)))

      const propertyFilter = !isUserCustomer
      ? (propertiesIds.length > 0
          ? `id: { _in: [${propertiesIds.join(',')}] }`
          : '')
      : (propertiesIds.length > 0
          ? `id: { _in: [${filteredPropertiesIds.join(',')}] }`
          : `id: { _in: [${this.usersService.loggedUserProperties.join(',')}] }`
      );

      const typesQuery =
        params?.['type']?.map(type => '"' + type + '"').join(',') ?? '';

      const citiesQuery =
        params?.['city']?.map(city => '"' + city + '"').join(',') ?? '';

      const statesQuery =
        params?.['state']?.map(state => '"' + state + '"').join(',') ?? '';

      const searchQuery = search ? ` name: { _ilike: "%${search}%" } ` : ''
      const query = `
        where: {
          ${
            propertyFilter
          }
          ${
            searchQuery
          }

          ${typesQuery ? `type: {_in: [${typesQuery}]}` : ''}
          ${statesQuery ? `address_state: {_in: [${statesQuery}]}` : ''}
          ${citiesQuery ? `address_city: {_in: [${citiesQuery}]}` : ''}
          ${organizationsQuery || isUserCustomer && !params?.['organizationId']
          ? `users_organizations_roles: {
            ${
              organizationsQuery
                ? `organizationId: {_in: [${organizationsQuery}]}`
                : ''
            }
            ${
              isUserCustomer &&
              !params?.['organizationId']
                ? `organizationId: {_in: [${this.usersService.loggedUserOrganizations.join(
                    ',',
                  )}]}`
                : ''
            }
          }`

            : ''
          }
        }
      `;
      const response = await firstValueFrom(
        this.apollo.query<ListPropertiesQueryResult>({
          query: gql`
          query ListProperties {
            front_properties(
              limit: ${limit}
              offset: ${offset}
              order_by: {${orderBy}: ${order}}
              ${query}
            ) {
                id
                name
                type
                address_street
                address_city
                address_state
                address_zip_code
                photo
                organizationId
                created_at
                updated_at
                root_location_id
                organization {
                  id
                  name
                  created_at
                  updated_at
                }
                users_organizations_roles {
                  id
                  organizationId
                  userId
                  role
                  propertyId
                  organization {
                    id
                    name
                    created_at
                    updated_at
                  }
                }
              }
              front_properties_aggregate(${query}) {
                aggregate {
                  count
                }
              }
            }
          `,
          fetchPolicy: 'no-cache',
        }),
      );

      return {
        properties: response.data.front_properties.map(BuildProperty),
        totalItems: response.data.front_properties_aggregate.aggregate.count,
      };
    } catch (error: any) {
      throw new Error(error?.error?.message || error?.message || error);
    }
  }

  public async searchByName(search: string): Promise<Property[]> {
    try {
      let propertiesIdSearch = '';

      if (this.usersService.isLoggedUserCustomer) {
        if (this.usersService.loggedUserProperties.length === 0) {
          return [];
        }

        propertiesIdSearch = `id: {_in: [${this.usersService.loggedUserProperties.join(
          ',',
        )}]}`;
      }

      const response = await firstValueFrom(
        this.apollo.query<{ front_properties: Property[] }>({
          query: gql`
            query ListProperties {
              front_properties(where: {
                  _or: [{
                    name: { _ilike: "%${search}%" },
                    ${propertiesIdSearch}
                  }]
                }) {
                id
                name
                type
                address_street
                address_city
                address_state
                address_zip_code
                photo
                organizationId
                created_at
                updated_at
                root_location_id
                organization {
                  id
                  name
                  created_at
                  updated_at
                }
                users_organizations_roles {
                  id
                  organizationId
                  userId
                  role
                  propertyId
                  organization {
                    id
                    name
                    created_at
                    updated_at
                  }
                }
              }
            }
        `,
        fetchPolicy: 'no-cache',
        }),
      );

      return response.data.front_properties.map(BuildProperty);
    } catch (error: any) {
      throw new Error(error?.error?.message || error?.message || error);
    }
  }

  public async delete(propertyId: number): Promise<void> {
    try {
      await firstValueFrom(
        this.apollo.mutate({
          mutation: gql`
            mutation DeleteProperty {
              delete_front_properties(where: {id: {_eq: ${propertyId}}}) {
                returning {
                  id
                }
              }
            }
          `,
        }),
      );
    } catch (error: any) {
      throw new Error(error?.error?.message || error?.message || error);
    }
  }

  public async listPropertiesWithDeviceCount(propertiesId: number[]) {
    try {
      const response = await firstValueFrom(
        this.apollo.query<{
          front_properties_aggregate: ListPropertiesWithDeviceCountResponse;
        }>({
          query: gql`
          query PropertiesWithDeviceCount {
            front_properties_aggregate(
              where: {
                id: {
                  _in: [${propertiesId.join(',')}]
                }
              }
            ) {
              nodes {
                id
                devices_aggregate {
                  aggregate {
                    count
                  }
                }
              }
            }
          }
          `,
          fetchPolicy: 'no-cache',
        }),
      );
      return response.data.front_properties_aggregate;
    } catch (error: any) {
      throw new Error(error?.error?.message || error?.message || error);
    }
  }
  public async propertiesWithDeviceAndUserCountByOrganization(params: PropertyDeviceAndUserByOrganizationParams): Promise<PropertyAndUserByOrganizationResult[]>{
      const userOrganizationsId = this.usersService.loggedUserOrganizations
      const isUserCustomer = this.usersService.isLoggedUserCustomer
      const organizationId = params.organizationId
      const searchForOrganizationId = isUserCustomer ? userOrganizationsId.find(id => id === organizationId) : organizationId

      if (userOrganizationsId.length === 0 || searchForOrganizationId === -1 && !isUserCustomer ) {
        return []
      }
      const city = params.city && params.city.length > 0 ? params.city : ""
      const state = params.state && params.state.length > 0 ? params.state : ""
      const type = params.propertyType && params.propertyType.length > 0 ? params.propertyType : ""
      const search = params.search ?? ""

      const cityQuery = city ? this.buildOrOperationsForStringArrays(city,"address_city") : ''
      const stateQuery = state ? this.buildOrOperationsForStringArrays(state,"address_state") : ''
      const typeQuery = type ? this.buildOrOperationsForStringArrays(type as string[],"type") : ''

      const searchQuery = search ? `name: {_ilike: "%${search}%"}` : ''

      const query = `query PropertiesWithDeviceAndUserCountByOrganization {
        front_properties(where: {
            organizationId: {_eq: ${searchForOrganizationId}}
            ${searchQuery}
            ${stateQuery}
            ${cityQuery}
            ${typeQuery}
          }
          ) {
          id
          name
          type
          address_street
          address_city
          address_state
          address_zip_code
          photo
          users_organizations_roles {
            role
            userId
            user {
              firstName
              lastName
              photo
            }
          }
          device_count: devices_aggregate {
            aggregate {
              count
            }
          }
          users_count: users_organizations_roles_aggregate {
            aggregate {
              count
            }
          }
        }
      }`

      const response = await firstValueFrom(
        this.apollo.query<{front_properties: PropertyAndUserByOrganizationQueryResult[]}>({
          query: gql`${query}`,
          fetchPolicy: 'no-cache',
        }))
      const formattedResponse: PropertyAndUserByOrganizationResult[] = response.data.front_properties.map((property) => {
        const device_count = property.device_count.aggregate.count;

        const userIds = property.users_organizations_roles?.map(
          user_organization_roles => user_organization_roles.userId,
        );
        const filteredUserId = new Set(userIds).size;
        const users_count = filteredUserId ?? 0;

        return {
          ...property,
          device_count,
          users_count
        }
      })

      return formattedResponse
    }
  private buildOrOperationsForStringArrays(stringArray:string[], field: string) {
      let query = ""

      if(stringArray.length > 1) {
        query+= "_or : ["
        stringArray.forEach((value,index) => {
          const addComma = index + 1 !== stringArray.length ? "," : ''
          query +=  `{${field}: {_ilike: "%${value}%"}}${addComma}`
        })
        query+= "]"

        return query
      }

      return `${field}:{_ilike: "%${stringArray[0]}%"}`
    }
}
