import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AlertsObject } from '@arkiq-portals/sdk';
import { format } from 'date-fns';
import { toTitleCase } from 'libs/ui/src/lib/utils';
@Component({
  standalone: true,
  imports: [CommonModule, RouterModule],
  selector: 'device-card',
  templateUrl: 'device-card.component.html',
})
export class DeviceCardComponent implements OnInit {
  constructor() {}
  @Input({ required: true }) alert!: AlertsObject;

  ngOnInit() {}

  public get formattedAlertType() {
    return toTitleCase(this.alert.alert_type.name);
  }

  public get formattedPropertyName() {
    if (!this.alert.property.name) {
      return 'Alerts';
    }
    return this.alert.property.name;
  }
  public get initiatedTime() {
    const formattedInitiatedTime = format(
      this.alert.alert_date_time,
      "MM'/'dd'/'yy '•' hh:mm a",
    );
    return formattedInitiatedTime;
  }

  public get clearedTime() {
    const formattedClearedTime = format(
      this.alert.time_cleared,
      "MM'/'dd'/'yy '•' hh:mm a",
    );
    return formattedClearedTime;
  }
  public get formattedLocation() {

    if (!this.alert.property.name || this.alert.property.name.length === 0) {
      return 'N/A';
    }
    return `${this.alert.property.name} — ${this.alert.fixture.location.name} — ${this.alert.fixture.name}`;
  }
}
