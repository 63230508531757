
<div class="w-full flex flex-1 flex-col lg:h-[calc(100vh-420px);]">
    <header class="px-[26px] py-5 border-b border-b-[#E0E0E0] flex items-center gap-4 justify-center sm:justify-between flex-wrap">
      <div class="flex items-center gap-5">
        <span class="text-[#727272] font-semibold">
          @if (properties.length > 0 || isLoading ){
            {{ 'Total ' + properties.length + ' Propertie(s)' }}
          }@else {
            No properties
          }
        </span>

        <arkiq-input
          class="max-w-[300px]"
          icon="bi-search"
          iconPosition="right"
          placeholder="Search Property Name..."
          [rounded]="false"
          [control]="filterForm.controls.search"
          size="md"
        />
      </div>
      <div class="flex items-center gap-3">
        @if (properties.length > 0 || isLoading ){
          <p class="flex items-center text-[#121212] text-lg font-bold">
            State
            <arkiq-table-column-header-button
              [isSortable]="false"
              [filter]="stateHeaderFilter"
              (closeMenu)="onStateFilterSelection($event)"
            />
          </p>
          <p class="flex items-center text-[#121212] text-lg font-bold">
            City
            <arkiq-table-column-header-button
              [isSortable]="false"
              (closeMenu)="onCityFilterSelection($event)"
              [filter]="cityHeaderFilter"
              />
          </p>
        <p class="flex items-center text-[#121212] text-lg font-bold">
          Property Type
          <arkiq-table-column-header-button
            [isSortable]="false"
            [filter]="buildingTypeHeaderFilter"
            (closeMenu)="onBuildingTypeFilterSelection($event)"
            />
        </p>
      }

      </div>

      <arkiq-button
        variant="primary"
        [rounded]="false"
        text="Add Property"
        (click)="onAddProperty()"
        size="md"
      />
    </header>

    <main class="flex flex-col flex-1 w-full px-[26px] pt-6 max-h-full overflow-hidden ">
      @if(isLoading) {
        <div class="w-full flex flex-col flex-1 items-center justify-center">
          <arkiq-spinner />
        </div>

      } @if(!isLoading && properties.length === 0) {
        <div class="flex flex-1 items-center justify-center text-[#CCC]">
          <span class="font-bold text-xl">No Properties Found</span>
        </div>
      }
      @if(!isLoading && properties.length > 0) {
        <ul class="flex flex-col gap-[15px] w-full pr-3 pb-4 overflow-hidden overflow-y-auto custom-scrollbar">
          <a
            *ngFor="let property of properties"
            class="flex flex-col gap-6 lg:gap-0 lg:grid lg:grid-cols-6 rounded-[15px] border border-black/15 p-[10px] hover:shadow-lg transition-shadow"
            [routerLink]="['/properties', property.id]"
          >
            <section class="col-span-4 flex flex-col lg:flex-row items-center gap-4">
              <img
                class="min-w-[63px] min-h-[63px] max-w-[63px] max-h-[63px] rounded-[7px] object-cover"
                [src]="getPropertyPhoto(property)"
                [alt]="property.name"
              />

              <div class="flex flex-col flex-1 gap-2">
                <div class="flex items-center gap-2">
                  <span class="font-semibold text-lg">{{ property.name }}</span>
                  <span class="text-[#888888] text-lg font-medium">{{
                    PROPERTY_TYPES[property.type]
                  }}</span>
                </div>

                <span class="text-[#888888] font-medium">
                  {{
                    property.address_street +
                      ' • ' +
                      property.address_city +
                      ' • ' +
                      property.address_state +
                      ' • ' +
                      property.address_zip_code
                  }}
                </span>
              </div>
            </section>
            <section class="col-start-2 col-end-3 lg:col-span-1 flex lg:flex-col gap-4 justify-around lg:justify-center">
              <div class="flex items-center gap-[10px]">
                <i class="bi bi-wifi text-[#727272] text-[20px]"></i>
                <span class="font-medium text-[15px] text-[#888888]">
                  {{ property.device_count || 0 }} Devices
                </span>
              </div>

              <div class="flex items-center gap-[10px]">
                <i class="bi bi-people-fill text-[#727272] text-[20px]"></i>
                <span class="font-medium text-[15px] text-[#888888]">
                  {{ property.users_count || 0 }} Users
                </span>
              </div>
            </section>

            <section class="col-span-1">
                <div
                  class="rounded-lg border border-[#E0E0E0] px-3 py-2"
                  [ngClass]="{ 'hidden': !getPropertyMaster(property.id) }"
                >
                @if(getPropertyMaster(property.id)){
                  <span class="text-sm font-semibold">Property Master</span>
                  <div class="flex items-center gap-3">
                    <img
                      class="min-w-[32px] min-h-[32px] max-w-[32px] max-h-[32px] rounded-full object-cover"
                      [src]="getUserPhoto(getPropertyMaster(property.id))"
                      [alt]="
                        getPropertyMaster(property.id)?.firstName +
                        ' ' +
                        getPropertyMaster(property.id)?.lastName
                      "
                    />
                    <span class="font-semibold">{{
                      getPropertyMaster(property.id)?.firstName +
                        ' ' +
                        getPropertyMaster(property.id)?.lastName
                    }}</span>
                  </div>}
                </div>
              </section>
          </a>
        </ul>
      }
    </main>
</div>
