<section
  class="w-full inline-grid auto-rows-max items-start grid-cols-[repeat(auto-fill,minmax(345px,2fr))] gap-[26px] p-6"
>
  <div class="shadow-lg rounded-[18px] flex flex-col">
    <header
      class="flex items-center justify-between border-b border-b-[#E0E0E0] px-[26px] py-5"
    >
      <div class="flex flex-col gap-2">
        <div class="flex items-center gap-[10px]">
          <span
            class="font-bold text-3xl"
            [routerLink]="'/organizations/' + organization.id + '/properties'"
          >
            {{ organizationProperties.total }}
          </span>

          <span
            class="text-xs text-[#666666] font-medium border-[1.5px] border-black/10 rounded-[5px] px-[6px] py-[3px]"
          >
            {{ organizationProperties.totalCities + ' Cities' }}
          </span>

          <span
            class="text-xs text-[#666666] font-medium border-[1.5px] border-black/10 rounded-[5px] px-[6px] py-[3px]"
          >
            {{ organizationProperties.totalStates + ' States' }}
          </span>
        </div>

        <span class="font-medium text-[#666666]">Total Properties</span>
      </div>

      <div
        class="flex min-w-[71px] min-h-[71px] max-w-[71px] max-h-[71px] rounded-[12px] border border-black/10 items-center justify-center"
      >
        <i class="bi bi-building-fill text-[31px] text-[#CCCCCC]"></i>
      </div>
    </header>

    <main class="w-full flex flex-col px-[26px] p-5">
      <ul class="w-full flex flex-col">
        <li
          *ngFor="let keyValue of organizationProperties.totalByType | keyvalue"
          class="font-semibold w-full flex items-center justify-between [&:not(:last-child)]:border-b [&:not(:last-child)]:pb-[15px] [&:not(:last-child)]:border-b-black/20 border-dashed"
          (click)="handleGoToOrganizationProperties(keyValue.key)"
        >
          <span>{{ PROPERTY_TYPES[keyValue.key] }}</span>
          <span>{{ keyValue.value }}</span>
        </li>
      </ul>
    </main>

    <!-- <footer class="w-full flex justify-end px-[26px] py-5"> // Uncomment
      <a
        class="text-primary underline text-sm font-semibold cursor-pointer hover:opacity-75 transition-opacity"
      >
        See more
      </a>
    </footer> -->
  </div>

  <div class="shadow-lg rounded-[18px] flex flex-col">
    <header
      class="flex items-center justify-between border-b border-b-[#E0E0E0] px-[26px] py-5"
    >
      <div class="flex flex-col gap-2">
        <div class="flex items-center gap-[10px]">
          <span
            class="font-bold text-3xl"
            (click)="handleGoToUsersList()"
          >
            {{ organizationUsers.total }}
          </span>
        </div>

        <span class="font-medium text-[#666666]">Total Users</span>
      </div>

      <div
        class="flex min-w-[71px] min-h-[71px] max-w-[71px] max-h-[71px] rounded-[12px] border border-black/10 items-center justify-center"
      >
        <i class="bi bi-people-fill text-[31px] text-[#CCCCCC]"></i>
      </div>
    </header>

    <main class="w-full flex-1 flex flex-col justify-center px-[26px] p-5">
      <ul
        class="w-full flex flex-col"
        *ngIf="(organizationUsers.totalByType | json) !== '{}'"
      >
        <li
          *ngFor="let keyValue of organizationUsers.totalByType | keyvalue"
          class="font-semibold w-full flex items-center justify-between [&:not(:last-child)]:pb-[15px] [&:not(:last-child)]:border-b [&:not(:last-child)]:border-b-black/20 border-dashed"
          (click)="handleGoToUsersListByRole(keyValue.key)"
        >
          <span>{{ USER_TYPES[keyValue.key] }}</span>
          <span>{{ keyValue.value }}</span>
        </li>
      </ul>
      <ul
        class="w-full flex flex-col"
        *ngIf="(organizationUsers.totalByType | json) === '{}'"
      >
        <li class="w-full flex items-center justify-center text-[#CCC]">
          <h4>No Residents</h4>
        </li>
      </ul>
    </main>

    <!-- <footer class="w-full flex justify-end px-[26px] py-5">
      <a
        class="text-primary underline text-sm font-semibold cursor-pointer hover:opacity-75 transition-opacity"
      >
        See more
      </a>
    </footer> -->
  </div>

  <div class="shadow-lg rounded-[18px] flex flex-col">
    <header
      class="flex items-center justify-between border-b border-b-[#E0E0E0] px-[26px] py-5"
    >
      <div class="flex flex-col gap-2">
        <div class="flex items-center gap-[10px]">
          <span
            class="font-bold text-3xl"
            (click)="handleGoToDevicesList()"
          >
            {{ totalDevices }}
          </span>
          @if(totalDevices > 0) {
          <span
            class="text-xs text-[#666666] font-medium border-[1.5px] border-black/10 rounded-[5px] px-[6px] py-[3px]"
            (click)="handleGoToInstalledDevicesList()"
          >
            {{ installedDevices }} Installed
          </span>

          <span
            class="text-xs text-[#666666] font-medium border-[1.5px] border-black/10 rounded-[5px] px-[6px] py-[3px]"
            (click)="handleGoToInstalledOfflineDevicesList()"
          >
            {{ totalDevices - installedDevices }} Offline
          </span>
          }
        </div>

        <span class="font-medium text-[#666666]">Total Devices</span>
      </div>

      <div
        class="flex min-w-[71px] min-h-[71px] max-w-[71px] max-h-[71px] rounded-[12px] border border-black/10 items-center justify-center"
      >
        <i class="bi bi-wifi text-[31px] text-[#CCCCCC]"></i>
      </div>
    </header>

    <main class="w-full flex flex-col px-[26px] p-5">
      @if(!isOrganizationDevicesEmpty) {
      <ul class="w-full flex flex-col">
        <li
          *ngFor="let device of organizationDevices() | keyvalue"
          class="font-semibold w-full flex items-center justify-between [&:not(:last-child)]:pb-[10px] [&:not(:last-child)]:border-b [&:not(:last-child)]:border-b-black/20 border-dashed [&:not(:first-child)]:pt-[10px]"
          (click)="handleGoToDevicesListByType(device.key)"
        >
          <span>{{ device.key }}</span>
          <span>{{ device.value }}</span>
        </li>
      </ul>
      } @else {
      <ul class="w-full flex flex-col">
        <li class="w-full flex items-center justify-center text-[#CCC]">
          <span>No Devices</span>
        </li>
      </ul>
      }
    </main>

    <!-- <footer class="w-full flex justify-end px-[26px] py-5">
      <a
        class="text-primary underline text-sm font-semibold cursor-pointer hover:opacity-75 transition-opacity"
      >
        See more
      </a>
    </footer> -->
  </div>
</section>
