import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { RouterModule } from '@angular/router';
import { RateCardComponent } from '../rate-card/rate-card.component';
import { Property } from '@arkiq-portals/sdk';

export type RateAlert = {
  name: string;
  total: number;
  photo?: string;
  tamper: number;
  leak: number;
  temperature: number;
  humidity: number;
};
@Component({
  standalone: true,
  imports: [CommonModule, RouterModule, RateCardComponent],
  selector: 'rate-alerts',
  templateUrl: 'rate-alerts.component.html',
})
export class RateAlertsComponent implements OnInit {
  @Input({ required: true }) alerts!: RateAlert[];
  constructor() {}
  ngOnInit() {}

  public anyAlerts() {
    return this.alerts.some(alert => alert.total > 0);
  }
}
