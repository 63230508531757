import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';

export type SelectOption = {
  value: string;
  label: string;
};

@Component({
  selector: 'arkiq-select',
  templateUrl: 'select.component.html',
  standalone: true,
  imports: [CommonModule, FormsModule, ReactiveFormsModule],
})
export class SelectComponent {
  @Input() label = '';
  @Input() control = new FormControl();
  @Input() disabled = false;
  // @Input() invalid = false;
  @Input() icon?: `bi-${string}`;
  @Input() iconPosition?: 'left' | 'right' = 'right';
  @Input() variant: 'primary' | 'secondary' = 'primary';
  @Input() rounded = true;
  @Input() size: 'lg' | 'md' = 'lg';
  @Input() required = false;
  @Input() options: SelectOption[] = [];

  public classes = {
    base: 'focus-within:border-primary border border-[#EEEEEE] flex items-center gap-4',
    primary: '',
    secondary: 'bg-[#EEEEEE]',
    invalid: 'border-state-cancel focus-within:border-state-cancel',
  };

  public sizes = {
    lg: 'h-[50px] text-[20px] px-[29px] py-[14px]',
    md: 'h-[34px] text-sm px-3 py-2',
  }

  constructor() { }

  public get invalid(): boolean {
    return this.control.invalid && (this.control.touched || this.control.dirty);
  }
}
