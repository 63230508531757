import { CommonModule } from "@angular/common";
import { Component, Input } from "@angular/core";
import { FormControl, ReactiveFormsModule } from "@angular/forms";

@Component({
  selector: 'arkiq-slide-toggle',
  templateUrl: './slide-toggle.component.html',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule],
})
export class SlideToggleComponent {
  @Input({ required: true }) control!: FormControl;
}
