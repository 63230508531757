import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatAutocompleteModule, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatTooltipModule } from '@angular/material/tooltip';
import { User, UsersService } from '@arkiq-portals/sdk';
import { debounceTime, distinctUntilChanged, Subscription } from 'rxjs';
import { AddOrganizationMasterFormComponent } from '../add-organization-master-form/add-organization-master-form.component';
import { InputComponent } from '../input/input.component';

@Component({
  selector: 'arkiq-select-organization-master',
  templateUrl: 'select-organization-master.component.html',
  standalone: true,
  imports: [
    CommonModule,
    MatAutocompleteModule,
    MatTooltipModule,
    AddOrganizationMasterFormComponent,
    InputComponent,
  ],
})
export class SelectOrganizationMasterComponent implements OnInit, OnDestroy {
  @Input() addBtnLabel = 'Add organization master user';
  @Input() title = 'Select Organization Master User';
  @Input() placeholder = 'Select Organization Master User';

  @Input() initialValue?: User | Partial<User> | null;

  @Output() selectUser = new EventEmitter<User>();
  @Output() createUser = new EventEmitter<Partial<User>>();
  @Output() resetUser = new EventEmitter();
  @Output() masterFormTouchedAndValid = new EventEmitter()

  public searchControl = new FormControl('');

  public isLoading = false;
  public users: User[] = [];

  private subscription = new Subscription();

  public showAddOrganizationMasterForm = false;

  public selectedUser?: User;

  constructor(private usersService: UsersService) {}

  public ngOnInit(): void {
    this.searchUsers();

    this.subscription.add(
      this.searchControl.valueChanges
        .pipe(
          distinctUntilChanged(),
          debounceTime(500),
        )
        .subscribe(value => {
          this.searchUsers(value ?? '');
        }),
    );
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public onMasterFormTouchedAndValid(event) {
    this.masterFormTouchedAndValid.emit(event)
  }

  private async searchUsers(search?: string) {
    try {
      this.isLoading = true;

      const response = await this.usersService.list({ search, limit: 999 });
      this.users = response.users;

      if (this.initialValue) {
        this.selectedUser = this.initialValue as User;
        this.selectUser.emit(this.initialValue as User);
      }

      this.isLoading = false;
    } catch (error) {
      this.isLoading = false;
      console.error(error);
    }
  }

  public onUserSelected(event: MatAutocompleteSelectedEvent) {
    const user = this.users.find(u => u.id === event.option.value);

    if (user) {
      this.selectedUser = user;
      this.masterFormTouchedAndValid.emit(true)
      this.selectUser.emit(user);
    } else {
      this.searchControl.setValue('');
    }
  }

  public onAddOrganizationMasterFormSaved(event: Partial<User>) {
    this.masterFormTouchedAndValid.emit(true)
    this.createUser.emit(event);
  }

  public handleRemoveSelection() {
    this.selectedUser = undefined;
    this.searchControl.setValue('');
    this.resetUser.emit();
  }
}
