<div
  class="w-full h-full items-center justify-center gap-2"
  [ngClass]="[isLoading ? 'flex' : 'hidden']"
>
  <arkiq-spinner />
  <span class="text-gray-500">Loading device data...</span>
</div>

<div
  class="w-full h-full flex-col gap-7"
  [ngClass]="[isLoading ? 'hidden' : 'flex']"
>
  <section class="grid grid-cols-1 lg:grid-cols-2 gap-4">
    <div class="grid grid-cols-1 lg:grid-cols-2 gap-x-6 gap-y-4">
      <arkiq-text-card
        [text]="gridData.flushes.toString()"
        title="Flushes"
      />

      <arkiq-text-card
        [text]="gridData.totalVolume.toString()"
        title="Total Volume"
        subTitle="(gal)"
      />

      <arkiq-text-card
        [text]="gridData.averageGallonsPerFlush.toString()"
        title="Average Gallons Per Flush"
      />

      <arkiq-text-card
        [text]="gridData.averageDuration + ' s'"
        title="Average Duration"
        subTitle="(approx.)"
      />
    </div>

    <div>
      <arkiq-table
        [columns]="tableColumns"
        [data]="paginatedData"
        [totalPages]="totalTablePages"
        [isLoading]="isLoadingTable"
        [hideTitle]="true"
        [hideItemsPerPageToggle]="true"
        (pageChanged)="onTablePageChanged($event)"
        (sortOrderChanged)="onSortOrderChange($event)"
        paginationText="Total Results"
      />
    </div>
  </section>

  <section class="w-full bg-white rounded-[18px] shadow-md flex flex-col">
    <header class="w-full px-6 py-4 border-b border-b-black/10">
      <h2 class="font-bold">Gallons Per Flush (GPF)</h2>
    </header>

    <main class="flex-1 w-full px-6 py-4 max-h-[383px] min-h-[383px]">
      <canvas class="h-full max-h-full w-full" #chartRef id="chart"></canvas>
    </main>
  </section>
</div>
