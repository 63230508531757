import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';

@Component({
  selector: 'arkiq-text-area',
  templateUrl: 'text-area.component.html',
  standalone: true,
  imports: [CommonModule, FormsModule, ReactiveFormsModule],
})
export class TextAreaComponent {
  @Input() label = '';
  @Input() placeholder = '';
  @Input() control = new FormControl();
  @Input() disabled = false;
  @Input() invalid = false;
  @Input() variant: 'primary' | 'secondary' = 'primary';
  @Input() rounded = true;
  @Input() size: 'lg' | 'md' = 'lg';
  @Input() required = false;

  public classes = {
    base: 'focus-within:border-primary border border-[#EEEEEE] flex items-center gap-4',
    primary: '',
    secondary: 'bg-[#EEEEEE]',
    invalid: 'border-state-cancel focus-within:border-state-cancel',
  };

  public sizes = {
    lg: 'text-[20px] px-[29px] py-[14px]',
    md: 'text-sm px-3 py-2',
  }

  constructor() { }
}
