import { CommonModule, Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import {
    FormControl,
    FormGroup,
    FormsModule,
    ReactiveFormsModule,
    Validators,
} from '@angular/forms';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import {
    DeviceOverview,
    DevicesOverviewService,
    OrganizationsService,
    PropertiesService,
} from '@arkiq-portals/sdk';
import {
    ButtonComponent,
    DialogAlertComponent,
    DialogAlertParams,
    DialogAlertVariant,
    IconButtonComponent,
    InputComponent,
    SelectComponent,
    SelectOption,
    SpinnerComponent,
} from '@arkiq-portals/ui';
import { distinctUntilChanged, Subscription, take } from 'rxjs';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    MatDialogModule,
    SpinnerComponent,
    IconButtonComponent,
    SelectComponent,
    FormsModule,
    ReactiveFormsModule,
    ButtonComponent,
    InputComponent,
  ],
  selector: 'app-device-transfer',
  templateUrl: 'device-transfer.component.html',
})
export class DeviceTransferComponent implements OnInit {
  public isLoading = true;
  public isLoadingProperties = false;
  public isSaving = false;

  public organizationOptions: SelectOption[] = [];
  public propertyOptions: SelectOption[] = [];
  public selectedDevices: DeviceOverview[] = [];

  public page = 1;
  public form = new FormGroup({
    organization: new FormControl('', [Validators.required]),
    property: new FormControl('', [Validators.required]),
  });

  private subscriptions = new Subscription();

  constructor(
    private location: Location,
    private organizationsService: OrganizationsService,
    private propertiesService: PropertiesService,
    private devicesOverviewService: DevicesOverviewService,
    private matDialog: MatDialog,
  ) {}

  public get isTransferButtonDisabled() {
    return !(this.form.valid && this.selectedDevices.length > 0);
  }

  public ngOnInit() {
    const state = this.location.getState() as any;

    if (state && state.devices) {
      this.selectedDevices = state.devices;

      this.listOrganizations();
    } else {
      this.location.back();
      return;
    }

    this.subscriptions.add(
      this.form.controls.organization.valueChanges
        .pipe(distinctUntilChanged())
        .subscribe(organization => {
          this.form.patchValue({ property: null }, { emitEvent: false });

          if (organization) {
            this.listProperties(organization);
          }
        }),
    );
  }

  public async listOrganizations() {
    try {
      this.isLoading = true;

      const response = await this.organizationsService.list({limit: 9999999});
      const organizations = response.organizations;
      this.organizationOptions = organizations.map(organization => ({
        label: organization.name,
        value: String(organization.id),
      }));

      this.isLoading = false;
    } catch (error) {
      this.isLoading = false;
      console.error(error);
    }
  }

  public async listProperties(organizationId: string) {
    try {
      this.isLoadingProperties = true;

      const { properties } = await this.propertiesService.list({
        organizationId: [Number(organizationId)],
        limit: 999999,
      });

      this.propertyOptions = properties.map(property => ({
        label: property.name,
        value: String(property.id),
      }));

      this.isLoadingProperties = false;
    } catch (error) {
      this.isLoadingProperties = false;
      console.error(error);
    }
  }

  public handleGoBack() {
    this.location.back();
  }

  public async handleTransferTo() {
    if (this.form.invalid) {
      return;
    }

    try {
      this.isSaving = true;

      const transferDevicesPromises = this.selectedDevices.map(device =>
        this.devicesOverviewService.assignOrganizationAndPropertyToDevice(
          device.id,
          this.form.value.organization
            ? Number(this.form.value.organization)
            : undefined,
          this.form.value.property
            ? Number(this.form.value.property)
            : undefined,
        ),
      );

      await Promise.all(transferDevicesPromises);

      this.isSaving = false;

      this.showSuccessMessage();
    } catch (error) {
      this.isSaving = false;
      console.error(error);

      this.matDialog.open(DialogAlertComponent, {
        hasBackdrop: true,
        disableClose: false,
        data: {
          title: 'Unable to transfer devices',
          text: error,
          variant: DialogAlertVariant.ERROR,
        } as DialogAlertParams,
      });
    }
  }

  private showSuccessMessage() {
    const ref = this.matDialog.open(DialogAlertComponent, {
      hasBackdrop: true,
      disableClose: false,
      data: {
        title: 'Devices transferred',
        text: `(${this.selectedDevices.length} Devices transferred successfully)`,
        variant: DialogAlertVariant.SUCCESS,
      } as DialogAlertParams,
    });

    ref
      .afterClosed()
      .pipe(take(1))
      .subscribe(() => this.location.back());
  }
}
