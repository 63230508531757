
import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { MatMenuModule } from '@angular/material/menu';
import {
    FixturesService,
    Location,
    LocationsService,
    Property,
    PropertyLayout,
    PropertyLayoutService,
    PropertyLayoutTreeItem,
    UserRole,
} from '@arkiq-portals/sdk';
import {
  DialogAddLocationComponent,
  DialogAddLocationParams,
  DialogAlertComponent,
  DialogAlertParams,
  DialogAlertVariant,
  DialogConfirmActionComponent,
  DialogConfirmActionParams,
  DialogRenameLocationComponent,
  HasRoleDirective,
  IconButtonComponent,
  SpinnerComponent,
  DialogRemoveLocationComponent,
  DialogRemoveLocationParams,
  DialogLoadingComponent
} from '@arkiq-portals/ui';
import { take } from 'rxjs';

@Component({
  selector: 'app-property-details-layout-tree',
  templateUrl: './property-details-layout-tree.component.html',
  standalone: true,
  imports: [
    CommonModule,
    MatMenuModule,
    MatDialogModule,
    IconButtonComponent,
    HasRoleDirective,
    SpinnerComponent,
  ],
})
export class PropertyDetailsLayoutTreeComponent implements OnInit {
  @Input({ required: true }) property!: Property;

  @Output() nodePathChange = new EventEmitter<PropertyLayoutTreeItem[]>();
  @Output() reload = new EventEmitter();

  public isLoading = false;

  public USER_ROLES = UserRole;

  public rawNodes: PropertyLayout[] = [];
  public nodes: PropertyLayoutTreeItem[] = [];
  public selectedNode?: PropertyLayoutTreeItem;

  constructor(
    private matDialog: MatDialog,
    private propertyLayoutService: PropertyLayoutService,
    private locationsService: LocationsService,
    private fixturesService: FixturesService
  ) {}

  public async ngOnInit() {
    try {
      this.isLoading = true;

      const response = await this.propertyLayoutService.listByPropertyId(
        this.property.id,
        this.property.root_location_id,
      );

      this.rawNodes = response.data;

      this.nodes = response.tree.map(item => ({
        ...item,
        isCollapsed: true,
      }));

      if (this.nodes.length) {
        this.handleNodeClicked(this.nodes[0], '0', 0);
      }

      this.isLoading = false;
    } catch (error) {
      console.error(error);
      this.isLoading = false;
    }
  }

  public handleNodeClicked(
    node: PropertyLayoutTreeItem,
    path: string,
    deep: number,
  ) {
    node.isCollapsed = !node.isCollapsed;
    this.selectedNode = node;

    const nodesPath: PropertyLayoutTreeItem[] = [];

    let index = 0;
    for (const nodeIndex of path.split('-')) {
      if (nodesPath.length === 0) {
        nodesPath.push(this.nodes[nodeIndex]);
      } else {
        const parentNode = nodesPath[index - 1];
        nodesPath.push(parentNode.children[nodeIndex]);
      }

      index++;
    }

    this.nodePathChange.emit(nodesPath);
  }

  public handleAddLocation() {
    const ref = this.matDialog.open(DialogAddLocationComponent, {
      hasBackdrop: true,
      disableClose: false,
      data: {
        property: this.property,
        parentLocationId: undefined,
        parentLocationPath: undefined,
      } as DialogAddLocationParams,
    });

    ref
      .afterClosed()
      .pipe(take(1))
      .subscribe(data => {
        if (data && data.reload) {
          this.reload.emit();
        }
      });
  }

  public forceNodePathChange(
    location: Location,
    currentPath: PropertyLayoutTreeItem[],
  ) {
    const locationNode = this.rawNodes.find(
      node => node.l_id === location.id,
    ) as PropertyLayout;
    const nodePath: PropertyLayoutTreeItem[] = [
      ...currentPath,
      {
        ...locationNode,
        isCollapsed: false,
        children: [],
      },
    ];

    this.selectedNode = nodePath.at(-1);
    this.nodePathChange.emit(nodePath);
  }

  public handleMoveNode(node: PropertyLayoutTreeItem) {
    console.log('move node', node);
  }

  public handleDuplicateNode(node: PropertyLayoutTreeItem) {
    console.log('duplicate node', node);
  }

  public handleRenameNode(node: PropertyLayoutTreeItem) {
    const ref = this.matDialog.open(DialogRenameLocationComponent, {
      hasBackdrop: true,
      disableClose: false,
      data: { locationId: node.l_id },
    });

    ref
      .afterClosed()
      .pipe(take(1))
      .subscribe(data => {
        if (data && data.name) {
          node.l_name = data.name;
        }
      });
  }

  public async handleDeleteNode(node: PropertyLayoutTreeItem) {
    let ref;
    const loadingRef = this.matDialog.open(DialogLoadingComponent, {
      hasBackdrop: true,
      disableClose: true
    })
    const locationFixtures = await this.fixturesService.listByLocationId(node.l_id)
    loadingRef.close()
    if (node.children.length === 0 && locationFixtures.length === 0) {
      ref = this.matDialog.open(DialogConfirmActionComponent, {
        hasBackdrop: true,
        disableClose: true,
        data: {
          title: 'Are you sure you want to delete this location?',
          text: `You are about to delete ${node.l_name}`,
        } as DialogConfirmActionParams,
      });
    } else {
      ref = this.matDialog.open(DialogRemoveLocationComponent, {
        hasBackdrop: true,
        disableClose: true,
        data: {
          node
        } as DialogRemoveLocationParams
      })
    }
    ref
      .afterClosed()
      .pipe(take(1))
      .subscribe(data => {
        console.log('deleted location', data, node);

        if (data && data.confirm) {
          const locationsIdsToDelete = this.getAllSubLocationsIds(node);
          const propertyLayoutIdsToDelete = this.getAllSubPropertyLayoutIds(node);

          this._deleteLocations(
            locationsIdsToDelete,
            propertyLayoutIdsToDelete,
          );
        }
      });
  }

  private async _deleteLocations(
    locationsIds: number[],
    propertyLayoutIdsToDelete: number[],
  ) {
    try {
      this.isLoading = true;

      await this.locationsService.deleteMany(locationsIds);
      await this.propertyLayoutService.deleteMany(propertyLayoutIdsToDelete);

      this.selectedNode = undefined;
      this.nodePathChange.emit([]);

      this.isLoading = false;

      this.reload.emit();
    } catch (error) {
      this.isLoading = false;

      console.error(error);

      this.matDialog.open(DialogAlertComponent, {
        hasBackdrop: true,
        disableClose: false,
        data: {
          variant: DialogAlertVariant.ERROR,
          title: "Unable to delete location and it's children",
          text: error,
        } as DialogAlertParams,
      });
    }
  }

  private getAllSubLocationsIds(node: PropertyLayoutTreeItem): number[] {
    const ids: number[] = [];

    ids.push(node.l_id);

    if (node.children && node.children.length > 0) {
      for (const child of node.children) {
        const childIds = this.getAllSubLocationsIds(child);
        ids.push(...childIds);
      }
    }

    return ids;
  }

  private getAllSubPropertyLayoutIds(node: PropertyLayoutTreeItem): number[] {
    const ids: number[] = [];

    ids.push(node.pl_id);

    if (node.children && node.children.length > 0) {
      for (const child of node.children) {
        const childIds = this.getAllSubLocationsIds(child);
        ids.push(...childIds);
      }
    }

    return ids;
  }

  public generateArray(number) {
    return new Array(number)
  }
}
