import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { ENVIRONMENT, Environment } from '../sdk.module';
import { AuthService } from './auth.service';

export type RegisterUserResponse = {
  uid: string;
};

@Injectable({ providedIn: 'root' })
export class FunctionsService {
  constructor(
    @Inject(ENVIRONMENT) private environment: Environment,
    private authService: AuthService,
    private http: HttpClient,
  ) {}

  public async registerUser(email: string, name: string): Promise<RegisterUserResponse> {
    try {
      const token = await firstValueFrom(this.authService.getToken()) as string;

      const response = await firstValueFrom(
        this.http.post<RegisterUserResponse>(
          this.environment.functionsUrl + 'registerUser',
          { email, name },
          { headers: { authorization: `Bearer ${token}` } }
        ),
      );

      await this.authService.metadataCreateWatcher(response.uid);

      return response;
    } catch (error: any) {
      throw new Error(error?.error?.error?.message || error?.error?.message || error?.message || error);
    }
  }

  public async deleteUser(firebaseId: string): Promise<RegisterUserResponse> {
    try {
      const token = await firstValueFrom(this.authService.getToken()) as string;

      const response = await firstValueFrom(
        this.http.post<RegisterUserResponse>(
          this.environment.functionsUrl + 'deleteUser',
          { uid: firebaseId },
          { headers: { authorization: `Bearer ${token}` } }
        ),
      );

      return response;
    } catch (error: any) {
      throw new Error(error?.error?.error?.message || error?.error?.message || error?.message || error);
    }
  }

  public async deleteManyUsers(usersUids: string[]): Promise<RegisterUserResponse> {
    try {
      const token = await firstValueFrom(this.authService.getToken()) as string;

      const response = await firstValueFrom(
        this.http.post<RegisterUserResponse>(
          this.environment.functionsUrl + 'deleteManyUsers',
          { uids: usersUids },
          { headers: { authorization: `Bearer ${token}` } }
        ),
      );

      return response;
    } catch (error: any) {
      throw new Error(error?.error?.error?.message || error?.error?.message || error?.message || error);
    }
  }

  public async insertPropertyLayout(
    propertyId: number,
    path: string,
    deviceId?: number,
  ): Promise<RegisterUserResponse> {
    try {
      const token = await firstValueFrom(this.authService.getToken()) as string;

      const response = await firstValueFrom(
        this.http.post<RegisterUserResponse>(
          this.environment.functionsUrl + 'insertPropertyLayout',
          { propertyId, path, deviceId },
          { headers: { authorization: `Bearer ${token}` } }
        ),
      );

      return response;
    } catch (error: any) {
      throw new Error(error?.error?.error?.message || error?.error?.message || error?.message || error);
    }
  }

  public async sendResetPasswordEmail(email: string): Promise<RegisterUserResponse> {
    try {
      const response = await firstValueFrom(
        this.http.post<RegisterUserResponse>(
          this.environment.functionsUrl + 'resetPassword',
          { email },
        ),
      );

      return response;
    } catch (error: any) {
      throw new Error(error?.error?.error?.message || error?.error?.message || error?.message || error);
    }
  }

  public async setPassword(
    email: string,
    key: string,
    password: string,
    passwordConfirmation: string,
  ): Promise<RegisterUserResponse> {
    try {
      const response = await firstValueFrom(
        this.http.post<RegisterUserResponse>(
          this.environment.functionsUrl + 'setPassword',
          { email, key, password, passwordConfirmation },
        ),
      );

      return response;
    } catch (error: any) {
      throw new Error(error?.error?.error?.message || error?.error?.message || error?.message || error);
    }
  }
}
