<label class="w-full  focus-within:text-black text-tertiary flex flex-col gap-2">
  <span *ngIf="label" class="text-lg font-medium">{{ label }}</span>
  
  <div
    [ngClass]="[
      classes.base,
      classes[variant],
      invalid ? classes.invalid : '',
      sizes[size],
      rounded ? 'rounded-full' : 'rounded-[5px]',
      control.invalid && (control.dirty || control.touched) ? 'border-state-cancel' : '',
      disabled ? 'bg-black/5' : 'bg-transparent'
    ]"
  >
    <input
      type="text"
      [formControl]="control"
      [placeholder]="placeholder"
      [attr.disabled]="disabled"
      [type]="visible ? 'text' : 'password'"
      class="w-full outline-none text-sm"
    />

    <button
      type="button"
      class="size-[18px] rounded-full flex items-center justify-center"
      (click)="visible = !visible"
    >
      <i class="bi text-[18px]" [ngClass]="visible ? 'bi-eye-slash-fill' : 'bi-eye-fill'"></i>
    </button>
  </div>
</label>
