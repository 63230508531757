import { Inject, Injectable } from '@angular/core';

import { getStorage, ref, uploadBytes, deleteObject } from 'firebase/storage';
import { Environment, ENVIRONMENT } from '../sdk.module';

@Injectable({ providedIn: 'root' })
export class StorageService {
  constructor(
    @Inject(ENVIRONMENT) private environment: Environment,
  ) {}

  public async uploadProfilePhoto(file: File, userId: string): Promise<string> {
    const storage = getStorage(undefined, this.environment.firebase.storageBucket);

    const fileExtension = file.name.split('.').pop();
    const fileRef = ref(storage, `profile-photos/profile-photo-${userId}-${Date.now()}.${fileExtension}`);

    const fileBuffer = await file.arrayBuffer();

    await uploadBytes(fileRef, fileBuffer);

    const fileUrl = `https://firebasestorage.googleapis.com/v0/b/${this.environment.firebase.storageBucket}/o/${encodeURIComponent(`profile-photos/${fileRef.name}`)}?alt=media`

    return fileUrl;
  }

  public async uploadPropertyPhoto(file: File): Promise<string> {
    const storage = getStorage(undefined, this.environment.firebase.storageBucket);

    const fileExtension = file.name.split('.').pop();
    const fileRef = ref(storage, `property-photos/property-photo-${Date.now()}.${fileExtension}`);

    const fileBuffer = await file.arrayBuffer();

    await uploadBytes(fileRef, fileBuffer);

    const fileUrl = `https://firebasestorage.googleapis.com/v0/b/${this.environment.firebase.storageBucket}/o/${encodeURIComponent(`property-photos/${fileRef.name}`)}?alt=media`

    return fileUrl;
  }

  public async deleteFile(fileUrl: string): Promise<void> {
    if (!fileUrl.includes('firebasestorage')) {
      return;
    }

    const storage = getStorage(undefined, this.environment.firebase.storageBucket);

    const fileRef = ref(storage, fileUrl);

    await deleteObject(fileRef);
  }
}
