<div
  class="flex justify-between items-center border border-[#F0F0F0] px-4 py-2 rounded-xl"
>
  <div
    class="w-[49px] h-[52px] min-w-[49px] flex flex-col items-center justify-center text-[#D7A339] text-base font-bold rounded bg-[url('/assets/calendar-icon.svg')] bg-no-repeat relative"
  >
    <span
      class="absolute -translate-x-1/2 -translate-y-1/2 top-[47%] left-[50%]"
    >
      {{ day() }}
    </span>
    <span
      class="absolute -translate-x-1/2 -translate-y-1/2 top-[75%] left-[47%]"
    >
      {{ month() }}
    </span>
  </div>
  <img src="/assets/pinpoint-icon.svg" class="h-[26px]" />
  <div class="flex flex-col justify-between">
    <p class="text-base font-semibold text-black">{{ address }}</p>
    <p class="flex items-center">
      <i class="bi bi-stopwatch text-[#0C0507] mr-1"></i>
      <span class="text-[#8D8D8D] font-normal mr-3">{{ time }} AM</span>

      <i class="bi bi-geo-alt text-[#0C0507] mr-1"></i>
      <span class="text-[#8D8D8D] font-normal">{{ fixture }}</span>
    </p>
  </div>
  <div class="flex flex-col items-center gap-2">
    <span
      class="rounded-full bg-primary-accent text-primary py-1 px-4 text-sm"
      >{{ alertType }}</span
    >
    <i class="bi bi-arrow-up-right text-primary text-2xl"></i>
  </div>
</div>
