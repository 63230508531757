import { CommonModule } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { LocationsService } from '@arkiq-portals/sdk';
import {
    ButtonComponent,
    IconButtonComponent,
    InputComponent,
} from '../../components';
import { DialogAlertComponent, DialogAlertParams, DialogAlertVariant } from '../dialog-alert/dialog-alert.component';

export type DialogRenameLocationParams = {
  locationId: number;
};

@Component({
  selector: 'arkiq-dialog-rename-location',
  templateUrl: './dialog-rename-location.component.html',
  standalone: true,
  imports: [
    CommonModule,
    MatDialogModule,
    IconButtonComponent,
    InputComponent,
    ButtonComponent,
  ],
})
export class DialogRenameLocationComponent {
  public isSaving = false;

  public nameControl = new FormControl('', [Validators.required]);

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: DialogRenameLocationParams,
    public dialogRef: MatDialogRef<DialogRenameLocationComponent>,
    private locationsService: LocationsService,
    private matDialog: MatDialog,
  ) {}

  public async handleRenameLocation(): Promise<void> {
    try {
      this.isSaving = true;

      await this.locationsService.update(
        this.data.locationId,
        { name: this.nameControl.value ?? '' },
      );

      this.isSaving = false;

      this.dialogRef.close({ name: this.nameControl.value });
    } catch (error) {
      this.isSaving = false;

      console.error(error);

      this.matDialog.open(
        DialogAlertComponent,
        {
          hasBackdrop: true,
          disableClose: false,
          data: {
            variant: DialogAlertVariant.ERROR,
            title: 'Unable to rename location',
            text: error,
          } as DialogAlertParams,
        },
      );
    }
  }
}
