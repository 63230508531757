import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { RouterModule } from '@angular/router';
import { AuthService, UsersService } from '@arkiq-portals/sdk';
import { DialogAlertComponent, DialogAlertParams, DialogAlertVariant, SpinnerComponent } from '@arkiq-portals/ui';
import { filter, fromEvent, interval, merge, Subscription, take, takeUntil, tap } from 'rxjs';

@Component({
  standalone: true,
  imports: [RouterModule, MatDialogModule, SpinnerComponent],
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent implements OnInit, OnDestroy {
  private inactivityTimerSubscription!: Subscription;
  private activitySubscription!: Subscription;
  private lastActivityTime!: number;

  constructor(
    private usersService: UsersService,
    private authService: AuthService,
    private matDialog: MatDialog,
  ) { }

  public get isLoading() {
    return this.usersService.isLoadingUser;
  }

  public ngOnInit() {
    this.startListeners();

    this.authService.isLoggedIn.subscribe(isLoggedIn => {
      if (isLoggedIn) {
        this.startListeners();
      } else {
        this.inactivityTimerSubscription.unsubscribe();
        this.activitySubscription.unsubscribe();
      }
    });
  }

  public ngOnDestroy() {
    this.inactivityTimerSubscription.unsubscribe();
    this.activitySubscription.unsubscribe();
  }

  private startListeners(): void {
    this.lastActivityTime = Date.now();

    this.inactivityTimerSubscription = interval(600000) // 10 minutes
      .pipe(
        takeUntil(this.activityStream()),
        filter(() => Date.now() - this.lastActivityTime >= 600000),
        tap(() => {
          const ref = this.matDialog.open(
            DialogAlertComponent,
            {
              hasBackdrop: true,
              disableClose: false,
              data: {
                variant: DialogAlertVariant.INFO,
                title: 'Inactivity detected',
                text: 'You were logged out due inactivity',
              } as DialogAlertParams,
            },
          );

          ref.afterClosed().pipe(take(1)).subscribe(
            () => {
              this.authService.signOut()
            },
          );
        }),
      )
      .subscribe();

    this.activitySubscription = this.activityStream().subscribe(() => {
      this.lastActivityTime = Date.now();
    });
  }

  private activityStream() {
    return merge(
      fromEvent(document, 'click'),
      fromEvent(document, 'keydown'),
      fromEvent(document, 'mousemove')
    );
  }
}
