import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MatTooltipModule } from '@angular/material/tooltip';

@Component({
  selector: 'arkiq-icon-button',
  templateUrl: 'icon-button.component.html',
  standalone: true,
  imports: [CommonModule, MatTooltipModule],
})
export class IconButtonComponent {
  @Input({ required: true }) icon!: `bi-${string}`;
  @Input() tooltipText = '';
  @Input() disabled = false;
  @Input() customIconClass = '';
}
