import { CommonModule, Location } from '@angular/common';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Router, RouterModule } from '@angular/router';
import { AuthService } from '@arkiq-portals/sdk';
import { Subscription } from 'rxjs';

export type SidebarItem = {
  text: string;
  icon: `bi-${string}`;
  route: string;
};

@Component({
  selector: 'arkiq-sidebar',
  templateUrl: 'sidebar.component.html',
  standalone: true,
  imports: [CommonModule, FormsModule, ReactiveFormsModule, RouterModule],
})
export class SidebarComponent implements OnInit, OnDestroy {
  @Input() items: SidebarItem[] = [];

  public tabControl = new FormControl(this.items[0]);
  // public currentTab = '/';

  public subscription = new Subscription();

  constructor(private router: Router, private authService: AuthService) {}

  public ngOnInit(): void {
    this.tabControl.setValue(
      this.items.find(item =>item.route === this.currentRoute.split('?').at(0))  ||
        this.items[0],
    );
    this.subscription.add(
      this.tabControl.valueChanges.subscribe(value => {
        if (value) {
          this.router.navigateByUrl(value.route);
          // this.currentTab = value.route;
        }
      }),
    );
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public get currentRoute() {
    const route = this.router.url.startsWith('/')
      ? this.router.url.replace('/', '')
      : this.router.url;
    return route.split('/').at(0)?.split('?').at(0) ?? '';
  }

  public handleSignOut() {
    this.authService.signOut();
  }
}
