import { DeviceOverview } from '@arkiq-portals/sdk';
import { Component, Inject, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';

import {
  MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { ButtonComponent, IconButtonComponent } from '@arkiq-portals/ui';

enum STATES {
  INITIAL = 'INITIAL',
}

export type DeleteDevicesData = {
  selectedDevices: DeviceOverview[];
};

@Component({
  standalone: true,
  imports: [
    CommonModule,
    MatDialogModule,
    ButtonComponent,
    IconButtonComponent,
  ],
  selector: 'delete-device-modal',
  templateUrl: 'delete-device-modal.component.html',
  styleUrl: '../scrollbar-devices.scss',
})
export class DeleteDeviceModalComponent implements OnInit {
  public modal_state: STATES = STATES.INITIAL;
  public STATES = STATES;

  constructor(
    private dialogRef: MatDialogRef<DeleteDeviceModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DeleteDevicesData,
  ) {}

  ngOnInit() {}

  public handleCloseModal() {
    this.dialogRef.close();
  }
  //  TODO -> STILL NEEDS API IMPLEMENTATION
  public confirmDeletion() {
    console.log('confirmDeletion');
  }

  public cancelDeletion() {
    console.log('cancelDeletion');
    this.handleCloseModal();
  }
}
