<div class="bg-white flex flex-col gap-6 p-5 rounded-[20px] max-w-[432px] w-full">
  <header class="w-full flex flex-col gap-2">
    <button
      type="button"
      class="size-7 rounded-full hover:bg-black/5 transition-colors self-end flex items-center justify-center"
      (click)="dialogRef.close({ confirm: false })"
    >
      <i class="bi bi-x-lg text-[16px] text-[#AAAAAA]"></i>
    </button>

    <h1 class="m-0 text-center text-2xl font-bold text-state-cancel">
      {{ data.title }}
    </h1>
  </header>

  <main class="font-medium text-[15px] text-center w-full px-5">
    {{ data.text }}
  </main>

  <footer class="flex gap-2 w-full items-center justify-center px-6 pb-6">
    <arkiq-button
      class="flex-1"
      text="Cancel"
      variant="tertiary"
      [rounded]="false"
      size="md"
      (click)="dialogRef.close({ confirm: false })"
    />

    <arkiq-button
      class="flex-1"
      text="Confirm"
      variant="danger"
      [rounded]="false"
      size="md"
      (click)="dialogRef.close({ confirm: true })"
    />
  </footer>
</div>
