<button
  type="button"
  [ngClass]="[
    classes.base,
    classes[variant],
    sizes[size],
    disabled ? 'opacity-50' : 'opacity-100',
    rounded ? 'rounded-full' : 'rounded-[5px]',
    iconPosition === 'right' ? 'flex-row-reverse' : 'flex-row'
  ]"
  [disabled]="disabled"
>
  <i *ngIf="icon" class="bi" [ngClass]="icon"></i>
  <span *ngIf="!loading && text">{{ text }}</span>
  <arkiq-spinner *ngIf="loading" />
</button>
