import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';

@Component({
  selector: 'arkiq-password-input',
  templateUrl: 'password-input.component.html',
  standalone: true,
  imports: [CommonModule, FormsModule, ReactiveFormsModule],
})
export class PasswordInputComponent {
  @Input() label = '';
  @Input() placeholder = '';
  @Input() control = new FormControl();
  @Input() disabled = false;
  @Input() invalid = false;
  @Input() icon?: `bi-${string}`;
  @Input() variant: 'primary' | 'secondary' = 'primary';
  @Input() rounded = true;
  @Input() size: 'lg' | 'md' = 'lg';

  public classes = {
    base: 'focus-within:border-primary border border-[#EEEEEE] flex items-center gap-4',
    primary: '',
    secondary: 'bg-[#EEEEEE]',
    invalid: 'border-state-cancel focus-within:border-state-cancel',
  };

  public sizes = {
    lg: 'h-[50px] text-[20px] px-[29px] py-[14px]',
    md: 'h-[34px] text-sm px-3 py-2',
  }

  @Output() valueChange = new EventEmitter();

  public visible = false;

  constructor() { }
}
