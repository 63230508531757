import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { OrganizationSummaryCardsComponent } from '../../components/organization-summary-cards/organization-summary-cards.component';
import { Organization, OrganizationsService } from '@arkiq-portals/sdk';
import { ActivatedRoute } from '@angular/router';
import { SpinnerComponent } from '@arkiq-portals/ui';

@Component({
  selector: 'app-organization-details-summary',
  templateUrl: 'organization-details-summary.component.html',
  standalone: true,
  imports: [
    CommonModule,
    SpinnerComponent,
    OrganizationSummaryCardsComponent,
  ],
  styles:[`:host {display: flex; flex:1;}`]
})
export class OrganizationDetailsSummaryComponent implements OnInit {
  public organization!: Organization;

  public isLoading = false;

  constructor(
    private activatedRoute: ActivatedRoute,
    private organizationsService: OrganizationsService,
  ) {}

  public ngOnInit(): void {
    const routeParams = this.activatedRoute.parent?.snapshot.params;

    if (routeParams?.['id']) {
      this.getOrganization(Number(routeParams['id']));
    }
  }

  private async getOrganization(organizationId: number) {
    try {
      this.isLoading = true;

      this.organization = await this.organizationsService.getById(organizationId);

      this.isLoading = false;
    } catch (error) {
      console.error(error);
      this.isLoading = false;
      alert(error);
    }
  }
}
