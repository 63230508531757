import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { SpinnerComponent } from '../spinner/spinner.component';

export type ButtonVariants =
  | 'primary'
  | 'secondary'
  | 'tertiary'
  | 'outline'
  | 'outline-primary'
  | 'danger'
  | 'primary-accent';

@Component({
  selector: 'arkiq-button',
  templateUrl: 'button.component.html',
  standalone: true,
  imports: [CommonModule, SpinnerComponent],
})
export class ButtonComponent {
  @Input() text = '';
  @Input() icon?: `bi-${string}`;
  @Input() iconPosition?: 'left' | 'right' = 'left';
  @Input() disabled = false;
  @Input() loading = false;
  @Input() variant: ButtonVariants = 'primary';
  @Input() rounded = true;
  @Input() size: 'lg' | 'md' = 'lg';

  public classes = {
    base: 'w-full flex items-center gap-3 font-semibold border hover:opacity-75 transition-opacity flex items-center justify-center',
    primary: 'bg-primary border-primary text-white',
    secondary: 'bg-secondary border-secondary text-white',
    tertiary: 'bg-tertiary border-tertiary text-white',
    outline: 'border-[#E0E0E0] bg-transparent text-[#2E2E2E]',
    'outline-primary': 'border-primary bg-transparent text-primary',
    danger: 'border-state-cancel bg-state-cancel text-white',
    'primary-accent': 'bg-primary-accent text-primary font-medium text-sm',
  };

  public sizes = {
    lg: 'h-[50px] text-[20px] px-[12px] md:px-[18px]',
    md: 'h-[34px] text-sm px-3 md:px-4',
  };

  constructor() {}
}
