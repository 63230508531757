@if (isLoading) {
  <div class="w-full h-full flex items-center justify-center gap-2">
    <arkiq-spinner />
    <p class="text-gray-500">Loading device data...</p>
  </div>
} @else {
  <div class="w-full h-full flex flex-col gap-5">
    <section class="w-full bg-white rounded-[6px] shadow-md">
      <main class="w-full grid grid-cols-1 lg:grid-cols-5 py-2">
        <div class="col-span-1 lg:col-span-2 flex flex-col gap-2 w-full items-center justify-center border-b border-b-black/10 lg:border-b-0 lg:border-r lg:border-r-black/10 border-dashed">
          <p class="text-[15px] font-medium">Valve Status</p>
          
          <div class="flex items-center gap-2">
            <p
              class="text-[36px] font-semibold"
              [ngClass]="isOpenedControl.value ? 'text-primary' : 'text-black'"
            >
              {{ isOpenedControl.value ? 'Opened' : 'Closed' }}
            </p>
            <arkiq-slide-toggle
              *hasRole="[USER_ROLES.ORGANIZATION_MASTER]"
              [control]="isOpenedControl"
            />
          </div>
        </div>
  
        <div class="col-span-1 flex flex-col gap-2 w-full items-center justify-center border-b border-b-black/10 lg:border-b-0 lg:border-r lg:border-r-black/10 border-dashed">
          <p class="text-[15px] font-medium">Water Pressure</p>
          <p class="text-[36px] font-semibold">{{ highlightData.water_pressure | number:'1.1-2' }} psi</p>
        </div>
  
        <div class="col-span-1 flex flex-col gap-2 w-full items-center justify-center border-b border-b-black/10 lg:border-b-0 lg:border-r lg:border-r-black/10 border-dashed">
          <p class="text-[15px] font-medium">Water Temperature</p>
          <p class="text-[36px] font-semibold">{{ highlightData.water_temperature | number:'1.1-2' }} ºF</p>
        </div>
  
        <div class="col-span-1 flex flex-col gap-2 w-full items-center justify-center">
          <p class="text-[15px] font-medium">Flow Rate</p>
          <p class="text-[36px] font-semibold">{{ highlightData.consumption_rate | number:'1.1-2' }} gal/h</p>
        </div>
      </main>
  
      <footer class="w-full flex items-center justify-center border-t border-t-black/10 py-2 gap-2">
        <p class="text-sm text-[#888888] font-bold">Last Update:</p>
        <p class="text-sm text-[#888888] font-medium">{{ lastUpdate }}</p>
      </footer>
    </section>
  
    <section class="w-full bg-white rounded-[6px] shadow-md">
      <arkiq-table
        [columns]="tableColumns"
        [data]="data"
        [hideTitle]="true"
        [totalPages]="totalPages"
        [isLoading]="isLoadingTable"
        (rowsLimitChanged)="onTableRowsLimitChanged($event)"
        (pageChanged)="onTablePageChanged($event)"
      />
    </section>
  </div>
}
