export type PropertyLayout = {
  l_created_at: string;
  l_id: number;
  l_name: string;
  l_parent_id: number;
  l_property_id: number;
  l_updated_at: string;
  pl_device_id: number;
  pl_id: number;
  pl_path: string;
  pl_property_id: number;
  pl_is_fixture: boolean;
}

export type PropertyLayoutTreeItem = PropertyLayout & {
  isCollapsed: boolean;
  children: PropertyLayoutTreeItem[];
}

export function BuildPropertyLayout(data: any): PropertyLayout {
  return {
    ...data,
    created_at: new Date(data.created_at),
    updated_at: new Date(data.updated_at),
  };
}

export function BuildPropertyLayoutTree(data: PropertyLayout[]) {
  const map = {};
  const tree: PropertyLayoutTreeItem[] = [];

  data.forEach(item => {
    map[item.pl_path] = { ...item, children: [] };
  });

  data.forEach(item => {
    const pathParts = item.pl_path.split('.');

    if (pathParts.length === 1) {
      tree.push(map[item.pl_path]);
    } else {
      const parentPath = pathParts.slice(0, -1).join('.');

      if (map[parentPath]) {
        map[parentPath].children.push(map[item.pl_path]);
      } else {
        tree.push(map[item.pl_path]);
      }
    }
  });

  return tree;
}
