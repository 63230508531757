import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Apollo, gql } from 'apollo-angular';
import { firstValueFrom } from 'rxjs';
import { DeviceCategory, Inventories, UserType } from '../models';
import { Environment, ENVIRONMENT } from '../sdk.module';
import { SortOrder } from '../types';
import { BuildDeviceOverview, DeviceOverview } from '../views';
import { FixturesService } from './fixtures.service';
import { UsersService } from './users.service';

export type ListDevicesOverviewParams = {
  limit?: number;
  page?: number;
  search?: string;
  category?: DeviceCategory;
  inventory?: Inventories;
  order?: SortOrder;
  orderBy?: string;
  organizationId?: number[] | null;
  propertyId?: number[] | null;
  type?: string[] | null;
  status?: string[] | null;
  battery?: string[] | null;
  usersService?: UsersService | null;
  id?: string | number | null;
};

export type ListDevicesOverviewQueryResult = {
  arkiq_devices_overview: DeviceOverview[];
};

export type CountDevicesOverviewQueryResult = {
  arkiq_devices_overview_aggregate: {
    aggregate: {
      count: number;
    };
  };
};

export type ListDevicesOverviewResponse = {
  devices: DeviceOverview[];
  totalItems: number;
};

export type ListDeviceCountByPropertiesQueryResult = {
  [key: string]: {
    aggregate: {
      count: number;
    };
  };
};
export type ListDeviceCountByPropertiesResponse = {
  [key: string]: number;
};

const GET_DEVICES_OVERVIEW_QUERY = (params: ListDevicesOverviewParams) => {
  const limit = params?.limit ?? 10;
  const page = params?.page ?? 1;
  const orderBy = params?.orderBy || 'organization';
  const order = params?.order || 'asc_nulls_first';
  const offset = (page - 1) * limit;
  const usersService = params?.usersService;

  const organizationsIds = params.organizationId?.map(organizationId => organizationId).join(',') ?? '';

  const propertiesIds = params.propertyId?.map(propertyId => propertyId).join(',') ?? '';

  let orgAndPropertiesQuery = '';

  const loggedUserOrganizations =
    usersService && usersService.isLoggedUserCustomer
      ? `${usersService.loggedUserOrganizations.join(',')},${organizationsIds}`
      : '';

  if (params.category === DeviceCategory.INVENTORY) {
    switch (params.inventory) {
      case Inventories.ARKIQ:
        orgAndPropertiesQuery = 'organization_id: {_is_null: true}, property_id: {_is_null: true}';
        break;

      case Inventories.CUSTOMERS:
        orgAndPropertiesQuery = 'organization_id: {_is_null: false, }, property_id: {_is_null: true}';
        break;

      default:
        orgAndPropertiesQuery = `property_id: {_is_null: true}`;
        break;
    }
  } else if (params.category === DeviceCategory.INSTALLED || params.category === DeviceCategory.ALL) {
    if (
      params.usersService?.loggedUser?.type === UserType.CUSTOMER ||
      organizationsIds ||
      propertiesIds
    ) {
      if (organizationsIds || propertiesIds) {
        orgAndPropertiesQuery = `
          ${propertiesIds ? `property_id: {_in: [${propertiesIds}]}` : ''}
          ${
            organizationsIds
              ? `organization_id: {_in: [${organizationsIds}]}`
              : ''
          }
        `;
      } else {
        orgAndPropertiesQuery = `organization_id: {_is_null: false, _in:[${loggedUserOrganizations}]}, property_id: {_is_null: false}`;
      }
    } else {
      orgAndPropertiesQuery = `organization_id: {_is_null: false},  property_id: {_is_null: false}`;
    }
  }

  const typesQuery =
    params?.['type']?.map(type => '"' + type + '"').join(',') ?? '';

  const statusQuery =
    params?.['status']?.map(status => '"' + status + '"').join(',') ?? '';

  const batteryQuery =
    params?.['battery']?.map(battery => '"' + battery + '"').join(',') ?? '';

  const query = `
    where: {
      ${
        params?.['search']
          ? `
              _or: {
                id: { _ilike: "%${params?.['search']}%" },
              }
            `
          : ''
      }
      ${typesQuery ? `type: {_in: [${typesQuery}]}` : ''}
      ${statusQuery ? `status: {_in: [${statusQuery}]}` : ''}
      ${batteryQuery ? `battery: {_in: [${batteryQuery}]}` : ''}
      ${params?.['id'] ? `id: {_eq: "${params?.['id']}" } ` : ''}
      ${orgAndPropertiesQuery}
    }
  `;

  const listQuery = gql`
    query ListDevicesOverview {
      arkiq_devices_overview(
        limit: ${limit}
        offset: ${offset}
        order_by: {${orderBy}: ${order}}
        ${query}
      ) {
        id
        type
        organization
        organization_id
        property
        property_id
        last_alert
        alerts_in_last_7_days
        status
        battery_level
        purchased_on
        sold_on
        installed_on
        device {
          organization {
            id
            name
            created_at
            updated_at
          }
          property {
            id
            name
            type
            address_street
            address_city
            address_state
            address_zip_code
            photo
            organizationId
          }
        }
      }
    }
  `;

  const countQuery = gql`
    query ListDevicesOverview {
      arkiq_devices_overview_aggregate(
        ${query}
      ) {
        aggregate {
          count
        }
      }
    }
  `;

  return { listQuery, countQuery };
};

@Injectable({ providedIn: 'root' })
export class DevicesOverviewService {
  constructor(
    @Inject(ENVIRONMENT) private environment: Environment,
    private apollo: Apollo,
    private usersService: UsersService,
    private fixturesService: FixturesService,
    private http: HttpClient,
  ) {}

  public async list(
    params?: ListDevicesOverviewParams,
  ): Promise<DeviceOverview[]> {
    if (
      this.usersService.isLoggedUserCustomer &&
      this.usersService.loggedUserOrganizations.length === 0
    ) {
      return [];
    }
    try {
      const response = await firstValueFrom(
        this.apollo.query<ListDevicesOverviewQueryResult>({
          query: GET_DEVICES_OVERVIEW_QUERY({
            ...params,
            usersService: this.usersService,
          }).listQuery,
          fetchPolicy: 'no-cache',
        }),
      );

      return response.data.arkiq_devices_overview.map(BuildDeviceOverview);
    } catch (error: any) {
      throw new Error(error?.error?.message || error?.message || error);
    }
  }

  public async count(params?: ListDevicesOverviewParams): Promise<number> {
    if (
      this.usersService.isLoggedUserCustomer &&
      this.usersService.loggedUserOrganizations.length === 0
    ) {
      return 0;
    }
    try {
      const response = await firstValueFrom(
        this.apollo.query<CountDevicesOverviewQueryResult>({
          query: GET_DEVICES_OVERVIEW_QUERY({
            ...params,
            usersService: this.usersService,
          }).countQuery,
          fetchPolicy: 'no-cache',
        }),
      );

      return response.data.arkiq_devices_overview_aggregate.aggregate.count;
    } catch (error: any) {
      throw new Error(error?.error?.message || error?.message || error);
    }
  }

  public async getById(deviceId: number | string): Promise<DeviceOverview> {
    try {
      const response = await firstValueFrom(
        this.apollo.query<ListDevicesOverviewQueryResult>({
          query: gql`
            query ListDevicesOverview {
              arkiq_devices_overview(
                where: { id: { _eq: "${deviceId}" } }
              ) {
                id
                type
                organization
                organization_id
                property
                property_id
                last_alert
                alerts_in_last_7_days
                status
                battery_level
                purchased_on
                sold_on
                installed_on
                device {
                  organization {
                    id
                    name
                    created_at
                    updated_at
                  }
                  property {
                    id
                    name
                    type
                    address_street
                    address_city
                    address_state
                    address_zip_code
                    photo
                    organizationId
                  }
                }
              }
            }
          `,
          fetchPolicy: 'no-cache',
        }),
      );
      return BuildDeviceOverview(response.data.arkiq_devices_overview[0]);
    } catch (error: any) {
      throw new Error(error?.error?.message || error?.message || error);
    }
  }

  public async getByOrganizationId(
    organizationId: number,
    params?: ListDevicesOverviewParams,
  ): Promise<DeviceOverview[]> {
    try {
      const limit = params?.limit ?? 10;
      const page = params?.page ?? 1;
      const orderBy = params?.orderBy || 'organization';
      const order = params?.order || 'asc_nulls_first';
      const offset = (page - 1) * limit;

      const response = await firstValueFrom(
        this.apollo.query<ListDevicesOverviewQueryResult>({
          query: gql`
            query ListDevicesOverview {
              arkiq_devices_overview(
                limit: ${limit}
                offset: ${offset}
                order_by: {${orderBy}: ${order}}
                where: {organization_id: {_eq: ${organizationId}}}
              ) {
                id
                type
                organization
                organization_id
                property
                property_id
                last_alert
                alerts_in_last_7_days
                status
                battery_level
                purchased_on
                sold_on
                installed_on
                device {
                  organization {
                    id
                    name
                    created_at
                    updated_at
                  }
                  property {
                    id
                    name
                    type
                    address_street
                    address_city
                    address_state
                    address_zip_code
                    photo
                    organizationId
                  }
                }
              }
            }
          `,
          fetchPolicy: 'no-cache',
        }),
      );

      return response.data.arkiq_devices_overview.map(BuildDeviceOverview);
    } catch (error: any) {
      throw new Error(error?.error?.message || error?.message || error);
    }
  }

  public async countByOrganizationId(
    organizationId: number,
    params?: ListDevicesOverviewParams,
  ): Promise<number> {
    try {
      // const search = `where: {organization_id: {_eq: ${organizationId}}}`;

      const response = await firstValueFrom(
        this.apollo.query<CountDevicesOverviewQueryResult>({
          query: gql`
            query ListDevicesOverview {
              arkiq_devices_overview_aggregate(
                where: {organization_id: {_eq: ${organizationId}}}
              ) {
                aggregate {
                  count
                }
              }
            }
          `,
          fetchPolicy: 'no-cache',
        }),
      );

      return response.data.arkiq_devices_overview_aggregate.aggregate.count;
    } catch (error: any) {
      throw new Error(error?.error?.message || error?.message || error);
    }
  }

  public async assignOrganizationAndPropertyToDevice(
    deviceId: string,
    organizationId?: number,
    propertyId?: number,
  ) {
    try {
      const params = { deviceId };

      if (organizationId) {
        Object.assign(params, { organizationId });
      }

      if (propertyId) {
        Object.assign(params, { propertyId });
      }

      const url =
        this.environment.arkiqApiUrl + '/Property/MoveDeviceToProperty?';

      await firstValueFrom(
        this.http.post(url, {}, { params, responseType: 'text' }),
      );
    } catch (error: any) {
      throw new Error(error?.error?.message || error?.message || error);
    }
  }

  public async deviceCountByProperties(
    propertiesId: Array<number | string>,
  ): Promise<ListDeviceCountByPropertiesResponse> {
    try {
      const user = this.usersService.loggedUser;

      if (!user) return {};

      const isUserCustomer = this.usersService.isLoggedUserCustomer;

      if (isUserCustomer) return {};

      const paramPropertiesId = propertiesId ?? [];

      if (propertiesId.length === 0) return {};

      let queryString = '';
      paramPropertiesId.forEach(propertyId => {
        queryString += `id_${propertyId}: arkiq_devices_details_aggregate(
          where: {property_id: {_eq: ${propertyId}}}) {
          aggregate {
            count
          }
        }
        `;
      });
      const query = `
        query ListDeviceCountByProperties {
            ${queryString}
        }
      `;
      const response = await firstValueFrom(
        this.apollo.query<{
          ListDeviceCountByPropertiesQueryResult;
        }>({
          query: gql`
            ${query}
          `,
        }),
      );

      const data = response.data;
      const newObj = {};
      Object.entries(data).forEach(([key, value]) => {
        newObj[key] = value.aggregate.count;
      });
      return newObj;
    } catch (error: any) {
      throw new Error(error?.error?.message || error?.message || error);
    }
  }
  public async returnDevice(deviceId: string) {
    try {
      const params = { deviceId };

      const url =
        this.environment.arkiqApiUrl + '/Property/MoveDeviceToProperty?';

      await firstValueFrom(
        this.http.post(url, {}, { params, responseType: 'text' }),
      );

      const deviceFixtures = await this.fixturesService.listByDeviceId(
        deviceId,
      );

      if (deviceFixtures.length > 0) {
        await this.fixturesService.removeDeviceIdFromMany(
          deviceFixtures.map(fixture => fixture.id),
        );
      }
    } catch (error: any) {
      throw new Error(error?.error?.message || error?.message || error);
    }
  }

  public async deviceCountByLocationId(locationsId: Array<number | string>) {
    try {
      const user = this.usersService.loggedUser;

      if (!user) return 0;

      const isUserCustomer = this.usersService.isLoggedUserCustomer;

      if (isUserCustomer) return 0;
      if (locationsId.length === 0) return 0;

      const paramLocationsId = locationsId ?? [];


      const locationIdQuery = paramLocationsId.join(',');
      const query = `
      query MyQuery {
        counter: arkiq_devices_overview_aggregate(where: {fixture: {location_id: {_in: [${locationIdQuery}]}}}) {
          aggregate {
            count
          }
        }
      }
      `;
      const response = await firstValueFrom(
        this.apollo.query<{
          counter: {aggregate: {count: number}};
        }>({
          query: gql`
            ${query}
          `,
        }),
      );
      return response.data.counter.aggregate.count;
    } catch (error: any) {
      throw new Error(error?.error?.message || error?.message || error);
    }
  }
}
