import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { DevicesOverviewService, PropertyLayoutTreeItem } from '@arkiq-portals/sdk';
import {
  SpinnerComponent,
} from '../../components';

@Component({
  selector: 'arkiq-dialog-loading',
  templateUrl: './dialog-loading.component.html',
  standalone: true,
  imports: [
    CommonModule,
    MatDialogModule,
    SpinnerComponent
  ],
})
export class DialogLoadingComponent {
  constructor(
    public dialogRef: MatDialogRef<DialogLoadingComponent>,
    public deviceOverviewSevices: DevicesOverviewService
  ) {}
}
