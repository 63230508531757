import { CommonModule, Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import {
  OrganizationsService,
  PropertyAndRoles,
  User,
  UserOrganizationRoleService,
  UserRole,
  UserRoleLabel,
  UsersService,
  UserTypeLabel,
} from '@arkiq-portals/sdk';
import {
  ButtonComponent,
  DialogAlertComponent,
  DialogAlertParams,
  DialogAlertVariant,
  HasRoleDirective,
  IconButtonComponent,
  SpinnerComponent,
} from '@arkiq-portals/ui';
import { format } from 'date-fns';
import { take } from 'rxjs';

@Component({
  selector: 'app-user-details',
  templateUrl: './user-details.component.html',
  standalone: true,
  imports: [
    CommonModule,
    MatDialogModule,
    SpinnerComponent,
    IconButtonComponent,
    ButtonComponent,
    HasRoleDirective,
  ],
})
export class UserDetailsComponent implements OnInit {
  public isLoading = false;

  public user!: User;
  public propertyAndRoles: PropertyAndRoles[] = [];

  public USER_ROLES = UserRole;

  constructor(
    private matDialog: MatDialog,
    private activatedRoute: ActivatedRoute,
    private location: Location,
    private usersService: UsersService,
    private organizationsService: OrganizationsService,
    private userOrganizationRoleService: UserOrganizationRoleService,
    private router: Router,
  ) {}

  public ngOnInit(): void {
    const id = this.activatedRoute.snapshot.paramMap.get('id');

    if (id) {
      this.loadUser(Number(id));
    } else {
      this.location.back();
    }
  }

  public async loadUser(userId: number): Promise<void> {
    try {
      this.isLoading = true;

      this.user = await this.usersService.getById(userId);
      for (const userOrganizationRole of this.user.users_organizations_roles ?? []) {
        const organization = userOrganizationRole.organization;
        if (!organization) continue;
        const property = userOrganizationRole.property;
        // if (!property) continue;
        const responseObject = {
          organizationName: organization.name,
          propertyName: property?.name ?? '(No property defined)',
          role: UserRoleLabel[userOrganizationRole.role],
        };
        this.propertyAndRoles.push(responseObject);
      }

      this.isLoading = false;
    } catch (error) {
      console.error(error);

      this.isLoading = false;

      const ref = this.matDialog.open(DialogAlertComponent, {
        hasBackdrop: true,
        disableClose: false,
        data: {
          variant: DialogAlertVariant.ERROR,
          title: 'Unable to load user',
          text: error,
        } as DialogAlertParams,
      });

      ref
        .afterClosed()
        .pipe(take(1))
        .subscribe(() => this.loadUser(userId));
    }
  }

  public handleGoBack(): void {
    this.location.back();
  }

  get userType() {
    return UserTypeLabel[this.user.type];
  }

  get lastLogin() {
    if (!this.user.lastLogin) return 'N/A';
    return format(this.user.lastLogin, 'MMM dd, yyyy');
  }

  public handleEditUser() {
    this.router.navigateByUrl(`/users/${this.user.id}/edit`, {
      state: { user: this.user },
    });
  }
}
