export const environment = {
  production: true,
  apiUrl: 'https://arkiq-production.hasura.app/v1/graphql',
  functionsUrl: 'https://us-central1-ark-iq.cloudfunctions.net/',
  arkiqApiUrl: 'https://api-uat.thearkiq.com/api',
  firebase: {
    apiKey: "AIzaSyDKCxTrX-aOKldyu_5j27J_YbqcpXS8_H0",
    authDomain: "ark-iq.firebaseapp.com",
    databaseURL: "https://ark-iq-default-rtdb.firebaseio.com",
    projectId: "ark-iq",
    storageBucket: "ark-iq.appspot.com",
    messagingSenderId: "409438329219",
    appId: "1:409438329219:web:e493b3848c1ad98fdd7eb6",
    measurementId: "G-59HBMV3W67"
  }
};
