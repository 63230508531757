<button
  type="button"
  class="w-full p-6 flex items-center gap-[29px] hover:bg-black/5 transition-colors cursor-pointer border-y border-y-[#E0E0E0]"
  (click)="input.click()"
>
  <img
    *ngIf="pictureUrl"
    [src]="pictureUrl"
    alt="New User Picture"
    class="min-w-[149px] min-h-[149px] max-w-[149px] max-h-[149px] rounded-full object-cover"
  />

  <section
    *ngIf="!pictureUrl"
    class="min-w-[149px] min-h-[149px] max-w-[149px] max-h-[149px] bg-[#EEEEEE] rounded-full flex items-center justify-center"
  >
    <span class="text-lg font-normal text-[#AAAAAA]">Image</span>
  </section>

  <div class="flex flex-col gap-[14px]">
    <span class="font-semibold text-[#727272] text-[17px]">Upload Photo (Optional)</span>
    <span class="underline text-primary font-semibold text-sm">Click to add image</span>
  </div>
</button>

<input
  #input
  type="file"
  accept="image/*"
  hidden
  (change)="handleUploadPicture($event)"
/>
