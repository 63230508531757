export function getUploadedImageURL(image: File): Promise<string | null> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = fileReaderEvent => {
      const fileContent = fileReaderEvent.target?.result?.toString();

      if (fileContent) {
        resolve(fileContent);
      }
    };

    reader.readAsDataURL(image);
  });
}
