<main class="flex flex-col flex-1 max-w-full">
  <section class="w-full flex justify-center mb-8">
    <arkiq-tabs [tabs]="tabs" />
  </section>

  <section class="flex flex-col gap-[15px] flex-1">
    @if (selectedUsers.length > 0) {
    <div
      class="w-full h-[52px] flex items-center justify-between bg-white rounded-[15px] p-[9px]"
    >
      <span class="text-[#727272] font-semibold">
        {{ selectedUsers.length + ' Users Selected' }}
      </span>

      <arkiq-button
        text="Delete users"
        variant="danger"
        size="md"
        [rounded]="false"
        (click)="handleDeleteManyUsers()"
      />
    </div>
    }

    <arkiq-table
      label="User(s)"
      [isLoading]="isLoading"
      [data]="users"
      [columns]="columns"
      [enableSearch]="true"
      searchInputLabel="Search by name"
      [enableDownloadCsv]="false"
      [buttons]="tableHeaderButtons"
      (searchChanged)="onSearch($event)"
      selectionMode="MULTIPLE"
      (selectedRowsChanged)="onTableRowsSelected($event)"
      [rolesAllowedToSelect]="[USER_ROLES.ORGANIZATION_MASTER]"
      [totalPages]="totalPages"
      [totalItems]="totalItems"
      (rowsLimitChanged)="onTableRowsLimitChanged($event)"
      (pageChanged)="onTablePageChanged($event)"
      (sortOrderChanged)="onTableSortChanged($event)"
      (rowClicked)="onTableRowClicked($event)"
      (clearAllFilters)="onClearFilters()"
      [enableClearAllFilters]="true"
      customHeaderClasses="flex-col md:flex-row"
      maxHeightPageClasses="max-h-[calc(100dvh-491px)] md:max-h-[calc(100dvh-339px)]"
    />
  </section>
</main>
