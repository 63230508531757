<div class="bg-white rounded-[20px] max-w-screen w-[432px]">
  <header
    class="w-full flex items-center justify-end px-5 py-2 border-b border-b-black/10"
  >
    <arkiq-icon-button icon="bi-x-lg" (click)="dialogRef.close()" />
  </header>

  <main class="w-full flex flex-col gap-5 p-6 pt-3">
    <h1 class="m-0 text-2xl text-[#F00] font-bold">Warning</h1>
    <h4 class="text-xl font-medium">
      You are about to
      <span class="uppercase font-bold text-[#F00]">delete</span>
      {{ ' ' }}
      <span class="text-[#000] font-bold">{{ data.text }}</span>
      location
      <span *ngIf="hasChildren"> and its childrens</span>
    </h4>
  </main>
  <footer class="w-full border-t border-t-black/10 p-6">
    <p class="font-semibold text-sm">An OTP code has been sent to [Number]</p>
    <label
      for="otpcode"
      class="w-full focus-within border border-black/10 p-4 text-sm flex items-center rounded-lg mt-3"
    >
      <input
        type="text"
        placeholder="OTP Code"
        class="w-full outline-none text-base bg-transparent"
      />
      <button id="otpcode" class="text-primary underline text-sm ml-auto">
        Resend
      </button>
    </label>

    <div class="flex items-center justify-between mt-6">
      <arkiq-button
        variant="tertiary"
        text="Cancel"
        size="md"
        [rounded]="false"
        (click)="dialogRef.close()"
      />

      <arkiq-button
        text="Delete"
        variant="danger"
        size="md"
        [rounded]="false"
        (click)="handleDeletion()"
      />
    </div>
  </footer>
</div>
