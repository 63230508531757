import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'arkiq-text-card-icon',
  templateUrl: './text-card-icon.component.html',
  standalone: true,
  imports: [CommonModule],
})
export class TextCardIconComponent {
  @Input({ required: true }) description!: string;
  @Input() title?: string;
  @Input() rightIcon?: `bi-${string}`;
  @Input() iconClasses?: string;
  @Input() leftIcon?: `bi-${string}`;
  @Input() subtitle?: string;

  public defaultIconClass = 'bi text-[52px] text-[#000]';
}
