<button
  type="button"
  class="w-full flex items-center gap-[29px] relative"
  (click)="input.click()"
>
  <img
    *ngIf="pictureUrl"
    [src]="pictureUrl"
    alt="New User Picture"
    class="min-w-[174px] min-h-[174px] max-w-[174px] max-h-[174px] rounded-[21px] object-cover"
  />

  <section
    *ngIf="!pictureUrl"
    class="min-w-[174px] min-h-[174px] max-w-[174px] max-h-[174px] bg-[#EEEEEE] rounded-[21px] flex items-center justify-center"
  >
    <span class="text-lg font-normal text-[#AAAAAA]">Image</span>
  </section>

  <button
    type="button"
    class="bg-white size-10 rounded-full flex items-center justify-center absolute bottom-2 right-2"
  >
    <i class="bi bi-pencil-fill text-base text-[#AAAAAA]"></i>
  </button>
</button>

<input
  #input
  type="file"
  accept="image/*"
  hidden
  (change)="handleUploadPicture($event)"
/>
