<div *ngIf="isLoading" class="w-full h-full flex items-center justify-center">
  <arkiq-spinner />
</div>

<div *ngIf="!isLoading" class="w-full h-full bg-white rounded-xl">
  <header class="flex items-center gap-2 py-5 px-6">
    <arkiq-icon-button icon="bi-chevron-left" (click)="handleGoBack()" />

    <h2 class="m-0 font-bold text-base">View Property Details</h2>

    @if (!isLoading) {
      <arkiq-button
        *hasRole="[USER_ROLES.ORGANIZATION_MASTER]"
        text="Edit"
        size="md"
        variant="primary"
        [rounded]="false"
        class="ml-auto"
        (click)="handleEditProperty()"
      />
    }
  </header>

  <section
    class="w-full border-y border-y-[#E0E0E0] py-[30px] px-6 flex flex-col lg:flex-row justify-center lg:justify-between gap-8"
  >
    <div class="min-w-[300px] flex justify-center lg:justify-normal gap-3">
      <div
        class="min-w-[115px] min-h-[115px] max-w-[115px] max-h-[115px] rounded-[20px] border border-black/10 flex items-center justify-center overflow-hidden"
      >
        <img
          [src]="property.photo"
          [alt]="property.name"
          class="bg-cover w-full h-full"
        />
      </div>
      <div class="flex flex-col">
        <span class="text-primary font-[600] text-[14px]">{{
          propertyType
        }}</span>
        <h3 class="font-bold text-[22px]">{{ property.name }}</h3>
        <p class="flex items-center gap-3 text-[#888] text-[15px]">
          <i class="bi bi-briefcase-fill"></i>
          <span>{{ organizationName }}</span>
        </p>
        <p class="flex items-center gap-3 text-[#888] text-[15px]">
          <i class="bi bi-geo-alt-fill"></i>
          <span>
            {{
              property.address_street +
              ' ' + property.address_zip_code +
              ' • ' + property.address_city +
              ' • ' + property.address_state
            }}
          </span>
        </p>
      </div>
    </div>

    <div class="flex flex-col items-center lg:items-stretch gap-2">
      <p class="text-[#888] text-sm text-right">
        <span class="bi bi-calendar2-week"></span>
        <span> Created on {{ property.created_at | date : 'd MMMM, y' }} </span>
      </p>

      <div class="flex items-center">
        <div
          class="border border-[#E0E0E0] rounded-lg p-3 flex items-center gap-[70px] cursor-default"
          *ngIf="propertyMaster"
        >
          <div class="flex items-center gap-3">
            <img
              class="rounded-full size-8 object-cover"
              [src]="propertyMaster.photo"
              [alt]="propertyMaster.firstName + ' ' + propertyMaster.lastName"
            />

            <span class="font-semibold">
              {{ propertyMaster.firstName + ' ' + propertyMaster.lastName }}
            </span>
          </div>

          <div class="border-[1.5px] border-black/15 rounded-[3px] py-1 px-3">
            <span class="text-sm font-semibold text-[#666666]"
              >Property Master</span
            >
          </div>
        </div>
      </div>

      <div class="flex items-center justify-center lg:justify-end gap-8">
        <p
          class="flex items-center gap-3 text-[#888] text-[15px]"
          *ngIf="propertyManagersAmount && propertyManagersAmount > 0"
        >
          <i class="bi bi-people-fill"></i>
          <span>{{ propertyManagersAmount }} Management</span>
        </p>
        <p
          class="flex items-center gap-3 text-[#888] text-[15px]"
          *ngIf="propertyResidentsAmount && propertyResidentsAmount > 0"
        >
          <i class="bi bi-people-fill"></i>
          <span>{{ propertyResidentsAmount }} Residents</span>
        </p>
      </div>
    </div>
  </section>

  <app-property-details-tabs [property]="property" />

  <main class="w-full">
    <router-outlet></router-outlet>
  </main>
</div>
